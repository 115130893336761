import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { stat } from 'fs';
import { catchError, Observable, throwError } from 'rxjs';

import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public sort: string = 'asc';
    constructor(private http: HttpClient) {}
    //public BASE_URL="https://aah.idevelopgroup.com/api"
    //public BASE_HOST="https://aah.idevelopgroup.com"

     public BASE_URL="http://127.0.0.1:8000/api"
     public BASE_HOST=""


    public getClientIP(){
      return this.http.get('http://api.ipify.org/?format=json');
    }

    getIpAddress() {

    }

    getSort(value: any){
      return this.sort;
    }

    clickSort(value: any){
      console.log(value);

      if(value =='desc'){
        this.sort = 'desc';
      }
      return this.sort;
    }


    public hasRole(roles:Array<string>){
      for (let index = 0; index < roles.length; index++) {
        const element = roles[index];
        const  result = localStorage.getItem("roles").indexOf(element) >=0;
        if(result){
          return true;
        }
      }
      return false;
    }

    public hasPermission(permissions:Array<string>){
      for (let index = 0; index < permissions.length; index++) {
        const element = permissions[index];
        const  result = localStorage.getItem("permissions").indexOf(element) >=0;
        if(result){
          return true;
        }
      }
      return false;
    }

    public isUserActive()
    {

    }

    parseUrl(href) {
      var l = document.createElement("a");
      l.href = href;
      return l;
    }

    public getPaginationItems(url:string): Observable<any>{
      return this.http.get(`${this.BASE_HOST}${url}`);
    }


    public createUser(data:any) : Observable<any>{
      return this.http.post(`${this.BASE_URL}/users/register`, data);
    }

    public updateProfile(data:any) : Observable<any>{
      return this.http.post(`${this.BASE_URL}/users/update-profile`, data);
    }

    public updatePassword(data:any) : Observable<any>{
      return this.http.post(`${this.BASE_URL}/users/update-password`, data);
    }

    public getUsers(): Observable<any>{
      return this.http.get(`${this.BASE_URL}/users`);
    }

    public getAllUsers(): Observable<any>{
      return this.http.get(`${this.BASE_URL}/users?per_page=500`);
    }

    public getActiveUser(): Observable<any>{
      return this.http.get(`${this.BASE_URL}/user-active`);
    }

    public getPermissions(): Observable<any>{
      return this.http.get(`${this.BASE_URL}/permissions`);
    }

    public getAllPermissions(): Observable<any>{
      return this.http.get(`${this.BASE_URL}/permissions?per_page=500`);
    }

    public deleteUser(id:any){
      return this.http.delete(`${this.BASE_URL}/users/${id}`);
    }

    public deactivateUser(id:any){
      return this.http.get(`${this.BASE_URL}/users/deactivate/${id}`);
    }

    public activateUser(id:any){
      return this.http.get(`${this.BASE_URL}/users/activate/${id}`);
    }


    public createRole(data:any) : Observable<any>{
      return this.http.post(`${this.BASE_URL}/roles`, data);
    }

    public getRoles(): Observable<any>{
      return this.http.get(`${this.BASE_URL}/roles`);
    }

    public updateRole(id:any, data:any): Observable<any> {
      return this.http.put(`${this.BASE_URL}/roles/${id}`, data);
  }

  public deleteRole(id:any): Observable<any>{
      return this.http.delete(`${this.BASE_URL}/roles/${id}`);
  }


  public createBlockedIp(data:any) : Observable<any>{
    return this.http.post(`${this.BASE_URL}/blockedips`, data);
  }

  public getBlockedIps(): Observable<any>{
    return this.http.get(`${this.BASE_URL}/blockedips`);
  }

  public updateBlockedIp(id:any, data:any): Observable<any> {
    return this.http.put(`${this.BASE_URL}/blockedips/${id}`, data);
}

public deleteBlockedIp(id:any): Observable<any>{
    return this.http.delete(`${this.BASE_URL}/blockedips/${id}`);
}

public getDevices(status:number): Observable<any>{
  return this.http.get(`${this.BASE_URL}/devices?status=${status}`);
}
public deleteDevice(id:any): Observable<any>{
  return this.http.delete(`${this.BASE_URL}/devices/${id}`);
}

public allowDevice(id:any): Observable<any>{
  return this.http.get(`${this.BASE_URL}/devices/allow/${id}`);
}

    public createDistrict(data:any) : Observable<any>{
      return this.http.post(`${this.BASE_URL}/provinces/districts`, data);
    }

    public getDistricts(provinceId:number): Observable<any>{
      return this.http.get(`${this.BASE_URL}/provinces/districts/${provinceId}`);
    }

    public getAllDistricts(): Observable<any>{
      return this.http.get(`${this.BASE_URL}/provinces/districts-all`);
    }


    public createDeparmtent(data:any) : Observable<any>{
      return this.http.post(`${this.BASE_URL}/departments`, data);
    }

    /////////////////////////////////////////////////////////////////////////////////
    public createDepartment(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/departments`, data);
  }
  public updateDepartment(id:any, data:any): Observable<any> {
      return this.http.put(`${this.BASE_URL}/departments/${id}`, data);
  }

  public getDepartments(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/departments`);
  }

  public deleteDepartment(id:any): Observable<any>{
      return this.http.delete(`${this.BASE_URL}/departments/${id}`);
  }

  public getProvinces(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/provinces`);
  }

  public getGrantProvinces(grantId:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/provinces/grant/${grantId}`);
  }

  public createGrant(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants`, data);
  }

  public getGrants(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants`);
  }

  public getAllGrants(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/grants?per_page=500`);
  }

  public getGrant(id:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/${id}`);
  }

  public deleteGrant(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/grants/${id}`);
  }



  public createGrantGoal(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/goals`, data);
  }

  public getGrantGoals(grantId:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/goals/${grantId}`);
  }

  public clearAllGrantConfiguration(grantId:number): Observable<any> {
    return this.http.get(`${this.BASE_URL}/grants/clear/${grantId}`);
}

public approveAllGrantConfiguration(grantId:number): Observable<any> {
  return this.http.get(`${this.BASE_URL}/grants/approve/${grantId}`);
}

  public deleteGrantGoals(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/grants/goals/${id}`);
  }


  public createGrantOutcome(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/outcomes`, data);
  }

  public getGrantOutcomes(grantId:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/outcomes/${grantId}`);
  }

  public deleteGrantOutcome(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/grants/outcomes/${id}`);
  }



  public createGrantOutput(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/outputs`, data);
  }


  public createTargetCalc(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/activities/whole`, data);
  }

  public createActivityBaseline(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/activities/baseline`, data);
  }

  public grantStatusChange(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/status-change`, data);
  }

  public createActivitySessions(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/activities/sessions`, data);
  }

  public createActivityIndividuals(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/activities/individuals`, data);
  }

  public createProvinceTeams(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/activities/province-teams`, data);
  }

  public createProvinceTarget(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/activities/province-targets`, data);
  }

  public createIsp3(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/grants/isp3`, data);
  }


  public getGrantIsp3(grantId:number): Observable<any> {
    return this.http.get(`${this.BASE_URL}/grants/isp3/${grantId}`);
 }

  public getAprPreview(grantId:number){
    return this.http.get(`${this.BASE_URL}/grants/apr-review/${grantId}`);
  }

  public getGrantOutputs(grantId:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/outputs/${grantId}`);
  }

  public deleteGrantOutputs(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/grants/outputs/${id}`);
  }



  public createGrantActivities(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/activities`, data);
  }

  public getGrantActivities(grantId:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/activities/${grantId}`);
  }

  public getGrantApprovalHistories(grantId:number): Observable<any> {
    return this.http.get(`${this.BASE_URL}/grants/approvals/${grantId}`);
}

  public getAllActivities(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/grants/all/activities`);
}

  public deleteGrantActivities(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/grants/activities/${id}`);
  }

  public deleteActivityTargetCalc(id:number): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/grants/target-calc/activities/${id}`);
}



  public createGrantDatasets(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/datasets`, data);
  }

  public getGrantDatasets(grantId:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/datasets/${grantId}`);
  }


  public getGrantDatasetInstance(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/datasets/load`, data);
  }

  public deleteGrantDatasets(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/grants/datasets/${id}`);
  }

  public createGrantSchemaData(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/datainstances`, data);
  }

  public getGrantDataInstance(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/datainstances/load`, data);
  }

  public loadGrantDataInstance(grantId:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/datainstances/load/${grantId}`);
  }

  public createGrantGroupTarget(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/targets`, data);
  }


  public createGrantIndividualTarget(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/targets/individual`, data);
  }

  public loadGrantTargets(grantId:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/targets/${grantId}`);
  }

  public loadGrantIndividualTargets(grantId:any, indicatorId:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/targets/individual/${grantId}/${indicatorId}`);
  }

  public loadAdminOrgsChildern(provinceId:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/adminorgs/childern/${provinceId}`);
  }


  public createBeneficiary(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/beneficiary`, data);
  }

  public searchBeneficiary(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/myspace/beneficiaries/search`, data);
  }

  public getBeneficiaries(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/beneficiary`);
  }

  public deleteBeneficiary(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/beneficiary/${id}`);
  }

  public getBeneficiary(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/beneficiary/${id}`);
  }

  public getBeneficiaryActivities(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/beneficiary/activities/${id}`);
  }

  public getBeneficiaryActivitiesByIdAndGroupAndType(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/myspace/beneficiary/activities-types`, data);
  }

  public createBeneficiaryActivities(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/beneficiary/activities`, data);
  }

  public deleteBeneficiaryActivity(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/beneficiary/activities/${id}`);
  }


  public getBeneficiaryMealTools(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/beneficiary/mealtools/${id}`);
  }

  public createBeneficiaryMealTools(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/beneficiary/mealtools`, data);
  }

  public deleteBeneficiaryMealTools(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/beneficiary/mealtools/${id}`);
  }


  public getBeneficiaryReferral(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/beneficiary/referrals/${id}`);
  }

  public getBeneficiaryReferralById(id:number): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/beneficiary/referrals/byid/${id}`);
}

  public createBeneficiaryReferral(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/beneficiary/referrals`, data);
  }

  public confirmReferral(id:any): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/beneficiary/referrals/confirm/${id}`);
  }




  public deleteBeneficiaryReferral(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/beneficiary/referrals/${id}`);
  }



  public getBeneficiaryEvaluation(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/beneficiary/evaluations/${id}`);
  }

  public createBeneficiaryEvaluation(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/beneficiary/evaluations`, data);
  }

  public deleteBeneficiaryEvaluation(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/beneficiary/evaluations/${id}`);
  }


  public getBeneciarybyCode(code:string): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/beneficiary/search/${code}`);
  }

  public createKitDistribution(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/kitdistributions`, data);
  }


  public getDistributedKits(id:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/kitdistributions/${id}`);
  }

  public deleteDistributedKits(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/kitdistributions/${id}`);
  }


  public createCommunityDialogue(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/communitydialogues`, data);
  }


  public getCommunityDialogueFollowUps(id:any): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/communitydialogues/follow-ups/${id}`);
  }

  public getCommunityDialogue(id:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/communitydialogues/${id}`);
  }

  public getCommunityDialogueById(id:any): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/communitydialogues/by-id/${id}`);
}


  public deleteCommunityDialogue(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/communitydialogues/${id}`);
  }



  public createTrainings(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/trainings`, data);
  }


  public getTraining(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/trainings`);
  }

  public getTrainingsByParticipantId(id:number): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/trainings/participantid/${id}`);
}

  public getAllTraining(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/trainings-all`);
  }

  public getTrainingId(id:any): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/trainings/${id}`);
}


  public deleteTraining(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/trainings/${id}`);
  }



  public createTrainingScore(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/trainingscores`, data);
  }


  public getTrainingScore(id:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/trainingscores/${id}`);
  }

  public deleteTrainingScore(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/trainingscores/${id}`);
  }


  public createTrainingParticipant(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/myspace/trainings/participants`, data);
  }


  public getAllTrainingParticipants(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/all-participants`);
  }

public getTrainingParticipant(id:any): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/trainings/participants/${id}`);
}

public getTrainingParticipantById(id:any): Observable<any> {
  return this.http.get(`${this.BASE_URL}/myspace/participants/byid/${id}`);
}

public deleteTrainingParticipant(id:number): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/myspace/trainings/participants/${id}`);
}


  public createTrainingEvaluation(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/trainingevaluations`, data);
  }


  public getTrainingEvaluation(trainingId:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/trainingevaluations/${trainingId}`);
  }

  public getAllTrainingEvaluation(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/trainingevaluations-all`);
}

  public deleteTrainingEvaluation(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/trainingevaluations/${id}`);
  }


  public createPsychoeducation(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/psychoeducation`, data);
  }


  public getPsychoeducations(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/psychoeducation`);
  }

  public getPsychoeducationId(id:any): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/psychoeducation/${id}`);
  }

  public deletePschoeducation(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/psychoeducation/${id}`);
  }


  public searchGrantRecords(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/search-records`, data);
  }


  public getIndicators(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/indicators`);
  }

  public deleteIndicator(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/indicators/${id}`);
  }


  public createGrantBulkIndicators(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/indicators/grant/bulk`, data);
  }

  public createIndicator(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/indicators`, data);
  }

  public getIndicatorGroups(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/indicatorgroups`);
  }

  public deleteIndicatorGroup(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/myspace/indicatorgroups/${id}`);
  }

  public getIndicatorByGrantId(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/indicators/grant/${id}`);
  }


  public getIndicatorGroupById(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/indicatorgroups/id/${id}`);
  }



  public createIndicatorGroup(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/myspace/indicatorgroups`, data);
  }

  public getIndicatorDisaggregations(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/indicatordisaggregations`);
  }

  public getIndicatorDisaggregationsByLevel(level:string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/myspace/indicatordisaggregations/bylevel/${level}`);
}

  public getIndicatorOfGroup(id:number): Observable<any> {
      return this.http.get(`${this.BASE_URL}/myspace/indicatorgroups/indicators/${id}`);
  }


  public createIndicatorsDataForGrant(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/indicators-data`,data);
  }


  public getIndicatorDataByGrant(grantId:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/grants/indicators-data/${grantId}`);
  }



  public getIndicatorDataByGrantForValidation(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/grants/indicators-data/validation`, data);
  }

public getAllBeneficiaries(): Observable<any> {
  return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/load`);
}

getTotalSubmittedBeneficiaryCount(): Observable<number> {
  return this.http.get<number>(`${this.BASE_URL}/get_total_submitted_benificiary`);
}

getTotalSubmittedKitDitributionCount(): Observable<number> {
  return this.http.get<number>(`${this.BASE_URL}/get_total_submitted_kitdistribution`);
}

getTotalSubmittedPsychoEducationCount(): Observable<number> {
  return this.http.get<number>(`${this.BASE_URL}/get_total_submitted_psychoEducation`);
}

getTotalSubmittedCommunityDialogueCount(): Observable<number> {
  return this.http.get<number>(`${this.BASE_URL}/get_total_submitted_communityDialogue`);
}

getTotalSubmittedTrainingsCount(): Observable<number> {
  return this.http.get<number>(`${this.BASE_URL}/get_total_submitted_trainings`);
}

getAllSubmittedBeneficiaries(): Observable<any> {
  return this.http.get(`${this.BASE_URL}/get_all_submitted_benificiaries`);
}

  public approveBeneficiary(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/approve/${id}`);
  }
  public validateBeneficiary(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/validate/${id}`);
  }
  public validateBeneficiaryKitDistribution(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/kitDistribution/validate/${id}`);
  }
  public validatePsychoEducation(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/psychoEducation/validate/${id}`);
  }
  public validateTraining(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/training/validate/${id}`);
  }
  public validateCommunityDialogue(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/communityDialogue/validate/${id}`);
  }

  public rejectBeneficiary(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/reject/${id}`);
  }

  public getAllBeneficiariesByStatus(status:string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/bystatus/${status}`);
  }

  public getAllKitDistributionsByStatus(status:string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/dtb_management/kitDistributions/bystatus/${status}`);
  }
  public getAllPsychoEducationByStatus(status:string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/dtb_management/psychoeducations/bystatus/${status}`);
  }
  public getAllTrainingsByStatus(status:string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/dtb_management/trainings/bystatus/${status}`);
  }

  public getAllCommunityDialogueByStatus(status:string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/dtb_management/communitydialogues/bystatus/${status}`);
  }

  public resendBeneficiary(id:any){
    return this.http.get(`${this.BASE_URL}/dtb_management/dtbsoverview/resend/${id}`);
  }

  public createDataValidations(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/documentation/datavalidations`, data);
  }

  public getDataValidations(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/documentation/datavalidations/load`, data);
  }

  public getAllDataValidations(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/documentation/datavalidations/all`);
}
public getAllValidatedData(): Observable<any> {
  return this.http.get(`${this.BASE_URL}/documentation/datavalidations/all-validated`);
}

public getAllDataValidationsByStatus(status:string): Observable<any> {
  return this.http.get(`${this.BASE_URL}/documentation/datavalidations/bystatus/${status}`);
}

  public getDataValidationsById(id:any): Observable<any> {
      return this.http.get(`${this.BASE_URL}/documentation/datavalidations/${id}`);
  }

  public deleteDataValidations(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/documentation/datavalidations/${id}`);
  }

  public validateDataValidation(id:any){
    return this.http.get(`${this.BASE_URL}/documentation/datavalidations/validate/${id}`);
  }

  public finalizeDataValidation(id:any){
    return this.http.get(`${this.BASE_URL}/documentation/datavalidations/finalize/${id}`);
  }

  public rejectDataValidation(id:any){
    return this.http.get(`${this.BASE_URL}/documentation/datavalidations/reject/${id}`);
  }

  public resendDataValidation(id:any){
    return this.http.get(`${this.BASE_URL}/documentation/datavalidations/resend/${id}`);
  }


  public createDataValidationHistory(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/documentation/datavalidations/history`, data);
  }

  public deleteDataValidationHistory(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/documentation/datavalidations/history/${id}`);
  }


  public uploadKoboToolBoxData(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/integrations/kobotoolbox`, data);
  }




  public createDisaggregation(data:any): Observable<any> {
      return this.http.post(`${this.BASE_URL}/settings/disaggregations`, data);
  }

  public getDisaggregations(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/settings/disaggregations`);
  }

  public deleteDisaggregation(id:number): Observable<any> {
      return this.http.delete(`${this.BASE_URL}/settings/disaggregations/${id}`);
  }


  // ===============================================
  // Raw queries
  public getDepartmentsGrantsStats(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/query/departments-grants`);
  }

  public getIndicatorDataForAllGrants(): Observable<any> {
      return this.http.get(`${this.BASE_URL}/query/grants-indicatordata-all`);
  }

  public getMonthsByYear(year: number): Observable<string[]> {
    return this.http.post<string[]>(`${this.BASE_URL}/documentation/getMonthsByYear`, { year });
  }

  public getDataYears(data: any): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/documentation/getYear`, data);
  }

  public getIndicatorDataForGrants(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/query/grants-indicatordata`, data);
  }

  public getDtbs(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/dtbs`);
  }

  public getCoreStats(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/stats/core`);
  }

  public getTopUserLogs(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/logs/user-logs`);
  }

  public getErrorLogs(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/logs/error-logs`);
  }

  //================================================

  public exportToExcel (element: any, fileName: string): void {
    // generate workbook and add the worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(element);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    // save to file
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);
  }


  getCurrentYear() {
    const date =  new Date();
    return date.getFullYear();
  }
 getYears(from) {
    const years = [];
    const currentYear = this.getCurrentYear();
    for (let index = 0; index <= currentYear - from; index++) {
        years.push(from + index);
    }

    return {years, currentYear};
  }

  public getMonthNames(date = new Date()) {
    // Copy input date so don't modify it
    let d = new Date(+date);
    // Set to 1st of month
    d.setDate(1);
    let result = [];
    // Get 12 month names, starting with current month
    for (let i=0; i<12; i++) {
      result.push(d.toLocaleString('default',{month:'2-digit'}));
      d.setMonth(d.getMonth() + 1);
    }
    return result;
  }


  public getIds(objs:Array<any>){
    const values = [];
    objs.forEach(obj => {
      values.push(obj.id);
    });

    return values;
  }


}
