import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import {UntypedFormGroup, Validators, FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { ApiService } from '@services/api.service';
import { HttpClient } from '@angular/common/http';
import { IpService } from '@services/ip.service';
import { DataExchangeService } from '@services/data-exchange.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public loginForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    public saved_email = localStorage.getItem('saved_email');
    public saved_password = localStorage.getItem('saved_password');
    public remember_me = localStorage.getItem('remember_me');
    public submitted = false;

    isBlocked: boolean;
    message: any;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private formBuilder: FormBuilder,
        public apiService: ApiService,
        private httpClient: HttpClient,
        private ipService: IpService,
        private dataExchangeService: DataExchangeService
    ) {}

    ngOnInit() {
        this.checkBlock();

        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.loginForm = this.formBuilder.group({
            username: [this.saved_email, [Validators.required, Validators.email]],
            password: [this.saved_password, Validators.required],
            remember_me: [this.remember_me],
        });
    }

    loginByAuth() {
        this.submitted = true;
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            this.appService.loginByAuth(this.loginForm.value);
            console.log(this.appService);
            this.dataExchangeService.currentData.subscribe(data => {
              this.message = data;
            });
            this.isAuthLoading = false;
            console.log('test messages');
            this.checkBlock();
        } else {
            this.toastr.error('Form is not valid!');
        }
    }

    checkBlock(){
      this.appService.isIpBlocked().subscribe((response: any) => {
        if(response.result){
          this.isBlocked = true;
        }
      });
    }

    async loginByGoogle() {
        this.isGoogleLoading = true;
        await this.appService.loginByGoogle();
        this.isGoogleLoading = false;
    }

    async loginByFacebook() {
        this.isFacebookLoading = true;
        await this.appService.loginByFacebook();
        this.isFacebookLoading = false;
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
