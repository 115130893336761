import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-beneficiary-referral-details',
  templateUrl: './beneficiary-referral-details.component.html',
  styleUrls: ['./beneficiary-referral-details.component.scss']
})
export class BeneficiaryReferralDetailsComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  objs: any;

  addObj: boolean = false;
  editObj: any;
  provinces: any;
  departments: any;
  id: any;
  beneficiary: any;
  referral: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
    ){

      this.activedRoute.params.subscribe((params) => {
        this.id = params['id'];
        this.apiService.getBeneficiaryReferralById(this.id).subscribe((response) => {
          this.referral = response;
          this.apiService.getBeneficiary(response.beneficiary_id).subscribe((response) => {
            this.beneficiary = response;
          });
        });


      });}

  ngOnInit() {
      // this.loadData();
  }

  loadData(){
    this.apiService.getAllGrants().subscribe(response => {
      this.objs = response.data.data;
    });

  }


  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteGrant(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }


  edit(item: any){
    // if(window.confirm("Are you sure to edit?")){
      this.router.navigate(['/grant/update/'+ item.id]);
    // }
  }

  configure(item: any){
      this.router.navigate(['/grant/configure/'+ item.id]);
  }


  details(item: any){
    this.router.navigate(['/grant/details/'+ item.id]);
  }



}

