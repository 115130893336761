<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Beneficiaries</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
          <li class="breadcrumb-item active">Beneficiaries</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">


        <div class="row">
          <div class="col-lg-4">
            <button type="button" class="btn btn-primary btn-sm" (click)="addObjectClicked()">Register
              New</button>
          </div>
          <div class="col-lg-4"></div>
          <div class=" input-group col-lg-4" *ngIf="!addObj">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
            </div>
            <input class="input" (input)="filter($event.target.value)" class="form-control"
              placeholder="Search Beneficiary">
          </div>
        </div>

      <div *ngIf="!addObj">
        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered">
            <tr class="bg-primary">
              <td [appSort]="response?.data" data-order="desc" data-name="id" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>&nbsp;#
              </td>
              <td [appSort]="response?.data" data-order="desc" data-name="beneficiary_code" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>&nbsp;Code
              </td>
              <td [appSort]="response?.data" data-order="desc" data-name="client_name" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>&nbsp;Name
              </td>
              <td [appSort]="response?.data" data-order="desc" data-name="age" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>&nbsp;Age
              </td>
              <td [appSort]="response?.data" data-order="desc" data-name="gender" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>&nbsp;Gender
              </td>
              <td [appSort]="response?.data" data-order="desc" data-name="literacy_level_of_bnf" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>&nbsp;Eduction
              </td>
              <td scope="col">Action</td>

            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of response?.data; let i=index">
              <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
              <td>{{item.beneficiary_code}}</td>
              <td>{{item.client_name}} / {{item.father_or_husband_name}} </td>
              <td>{{item.age}}</td>
              <td>{{item.gender}}</td>
              <td>{{item.literacy_level_of_bnf}}</td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-primary" (click)="addServices(item)"><i class="fa fa-eye"></i></button>
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                  <!-- <button type="button" class="btn btn-primary" (click)="addServices(item)">Services</button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-center">
          <span *ngFor="let item of response?.links">
            <button (click)="clickLink(item.url ? item.url : response.prev_page_url)"
              [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)"
              class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''"
              style="color:rgb(0, 0, 0); border: solid #ccc 1px;"></button>
          </span>
        </div>
      </div>
      <hr />

      <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

        <div class="row">

          <div class="mb-3 col-lg-3">
            <label class="form-label">Grant</label>
            <ng-select [items]="grants" bindLabel="title" bindValue="id" formControlName="grant_id"
              (change)="loadGrantProvinces($event)">
            </ng-select>
            <print-error [control]="formGroup.get('grant_id')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Province</label>

            <ng-select [items]="provinces" bindLabel="name" bindValue="id" formControlName="province_id"
              (change)="loadAdminOrgsChildern($event)">
            </ng-select>
            <print-error [control]="formGroup.get('province_id')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">District</label>
            <ng-select [items]="districts" bindLabel="name" bindValue="id" formControlName="district_id">
            </ng-select>

            <print-error [control]="formGroup.get('district_id')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Village</label>
            <input type="type" formControlName="village" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('village')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Site Code</label>
            <input type="type" formControlName="site_code" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('site_code')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Intervention Modality</label>
            <select class="form-control" formControlName="intervention_modality">
              <option value="REMOTE">REMOTE</option>
              <option value="ON-SITE">ON-SITE</option>
            </select>
            <print-error [control]="formGroup.get('intervention_modality')"></print-error>

          </div>



          <div class="mb-3 col-lg-3">
            <label class="form-label">Registration Date</label>
            <input type="date" formControlName="registration_date" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('registration_date')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Beneficiary Code</label>
            <input type="type" formControlName="beneficiary_code" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('beneficiary_code')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Name</label>
            <input type="type" formControlName="client_name" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('client_name')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Father or Husband Name</label>
            <input type="type" formControlName="father_or_husband_name" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('father_or_husband_name')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Age</label>
            <input type="number" formControlName="age" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('age')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Gender</label>
            <select class="form-control" formControlName="gender">
              <option value="MALE">MALE</option>
              <option value="FEMALE">FEMALE</option>
            </select>
            <print-error [control]="formGroup.get('gender')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Marital Status</label>
            <select class="form-control" formControlName="marital_status">
              <option value="SINGLE">SINGLE</option>
              <option value="MARRIED">MARRIED</option>
            </select>
            <print-error [control]="formGroup.get('marital_status')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Child of Beneficiary Code</label>
            <input type="number" formControlName="child_of_beneficiary_code" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('child_of_beneficiary_code')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Age of child of Beneficiary</label>
            <input type="number" formControlName="age_of_child_of_bnf" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('age_of_child_of_bnf')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Phone</label>
            <input type="type" formControlName="client_phone" class="form-control"
              placeholder="Accepted Pattern: +937xxxxxxxxx">
            <print-error [control]="formGroup.get('client_phone')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Household Status</label>
            <select class="form-control" formControlName="household_status_of_bnf">
              <option value="Host Community">Host Community</option>
              <option value="Other">Other</option>
            </select>
            <print-error [control]="formGroup.get('household_status_of_bnf')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Literacy Level</label>
            <select class="form-control" formControlName="literacy_level_of_bnf">
              <option value="No Formal Education">No Formal Education</option>
              <option value="High School Graduate">High School Graduate</option>
              <option value="14 grade education">14 grade education</option>
              <option value="Bachelor Degree Holder">Bachelor Degree Holder</option>
              <option value="Master Degree Holder">Master Degree Holder</option>
              <option value="PhD Degree Holder">PhD Degree Holder</option>
            </select>
            <print-error [control]="formGroup.get('literacy_level_of_bnf')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Disability Type</label>
            <select class="form-control" formControlName="disability_type">
              <option value="No Disability">No Disability</option>
              <option value="vision Impairment">vision Impairment</option>
              <option value="deaf or hard of hearing">deaf or hard of hearing</option>
              <option value="mental health conditions">mental health conditions</option>
              <option value="intellectual disability">intellectual disability</option>
              <option value="acquired brain injury">acquired brain injury</option>
              <option value="autism spectrum disorder">autism spectrum disorder</option>
              <option value="physical disability">physical disability</option>
            </select>
            <print-error [control]="formGroup.get('disability_type')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">GBV Survivor</label>
            <select class="form-control" formControlName="gbv_survivor">
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <print-error [control]="formGroup.get('gbv_survivor')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Referred for Protection (MHPSS, GBV) services?</label>
            <select class="form-control" formControlName="referred_for_protection">
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <print-error [control]="formGroup.get('referred_for_protection')"></print-error>

          </div>

        </div>


        <div class="row">
          <div class="col-3">
            <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
              Submit
            </button>
          </div>

          <div class="col-6">

          </div>

        </div>

      </form>


    </div>
  </div>
</section>
