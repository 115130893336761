
<div class="row">
  <!-- <div class="col-lg-2">
    <h5>Activity:</h5>
  </div> -->
  <div class="col-lg-12">
<form >

  <div class="row">

    <div class="col-lg-4">
      <label class="form-label">Activity</label>
      <ng-select [items]="activities" bindLabel="title" bindValue="id" class="form-select form-select-sm"
         [formControl]="formGroup.get('grant_activity_id')">
      </ng-select>
    </div>

    <div class="col-lg-4">
      <label class="form-label">Baseline Target</label>
      <input type="number" [formControl]="formGroup.get('baseline_target')" class="form-control form-control-sm" placeholder="">
    </div>

    <div class="col-lg-4">
      <label class="form-label">Total # of target</label>
      <input type="number" [formControl]="formGroup.get('total_target')" class="form-control form-control-sm">
    </div>

          <!-- <div class="col-lg-3">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
              <i class="fa fa-add"></i>
            </button>

          </div> -->
        </div>





        <hr/>


        <div class="row">
          <div class="col-lg-6">
            <div class="form-check">
              <label class="custom-control custom-checkbox">
                <label class="form-check-label">
                  Session
                </label>
                <input type="checkbox" #sessionCheckBox class="custom-control-input" (change)="enableSession($event)">
                <span class="custom-control-indicator"></span>
              </label>
            </div>
          </div>

          <div class="col-lg-6">

            <div class="form-check">
              <label class="custom-control custom-checkbox">
                <label class="form-check-label">
                  Individual
                </label>
                <input type="checkbox" #individualCheckBox class="custom-control-input" (change)="enableIndividual($event)">
                <span class="custom-control-indicator"></span>
              </label>
            </div>

          </div>
        </div>


        <!-- <div class="row">
          <div class="col-lg-8">
            <div *ngIf="sessionCheckBox.checked">
              <hr/>
              <h5>Session</h5>
              <div *ngFor="let disaggregation of sessionDisaggregations">
                <div class="row">
                  <div class="col-lg-6">
                    <input class="form-check-input" #cleanControlName(disaggregation?.name) type="checkbox" value="" id="flexCheckDefault">
                    <label>{{ disaggregation?.name }}</label>
                  </div>
                  <div class="col-lg-6">
                    <input type="number" [readOnly]="!cleanControlName(disaggregation?.name).checked" [value]="averageSessionTarget" [formControl]="dynamicForm.get(cleanControlName(disaggregation?.name))" class="form-control form-control-sm" placeholder="Overall target" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="row" style="margin-left: 5px;" *ngIf="sessionCheckBox.checked">
          <div class="col-lg-8">
            <div>
              <hr/>
              <h5>Session</h5>
              <div *ngFor="let disaggregation of sessionDisaggregations">
                <div class="row">
                  <div class="col-lg-6">
                    <input class="form-check-input" type="checkbox" #checkBox (change)="toggleInput(cleanControlName(disaggregation?.name), checkBox.checked)">
                    <label>{{ disaggregation.name }}</label>
                  </div>
                  <div class="col-lg-6">
                    <input type="number" [readOnly]="!checkBox.checked" [value]="averageSessionTarget" [formControl]="dynamicForm.get(cleanControlName(disaggregation?.name))" class="form-control form-control-sm" placeholder="Overall target" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



    <div class="row" style="margin-left: 5px;" *ngIf="individualCheckBox.checked">
      <div class="col-lg-8">
        <div >
          <hr/>
          <h5>Individuals</h5>
          <div *ngFor="let disaggregation of individualDisaggregations">

            <div class="row">
              <div class="col-lg-6">
                <input class="form-check-input" type="checkbox" #checkBox (change)="toggleInputForIndividualDisaggregation(cleanControlName(disaggregation?.name), checkBox.checked)">
                <label>{{ disaggregation?.name }}</label>
              </div>
              <div class="col-lg-6">
                <input type="number" [value]="averageIndividualTarget" [readOnly]="!checkBox.checked" [disabled]="!checkBox.checked" [formControl]="dynamicIndividualForm.get(cleanControlName(disaggregation?.name))" class="form-control form-control-sm" placeholder="Overall target" />
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>


        <div class="row">
          <div class="col-lg-8">
        <div>
          <hr/>
          <h5>Provice Target</h5>
          <div *ngFor="let province of provinces">

            <div class="row">
              <div class="col-lg-6">
                <label>{{ province?.name }}</label>
              </div>
              <div class="col-lg-6">
                <input type="number" [formControl]="provinceTargetForm.get(cleanControlName(province?.name))" [readOnly]="!enableManualForProvinceTarget" class="form-control form-control-sm" />
              </div>
            </div>
          </div>
        </div>
</div></div>

<div class="row">
  <div class="col-lg-8">
        <div>
          <hr/>
          <h5>Provice Teams</h5>
          <div *ngFor="let province of provinces">

            <div class="row">
              <div class="col-lg-6">
                <label># teams in {{ province?.name }}</label>
              </div>
              <div class="col-lg-6">
                <input type="number" [formControl]="provinceTeamForm.get(cleanControlName(province?.name))" class="form-control form-control-sm" />
              </div>
            </div>
          </div>
        </div>

        </div></div>


        <div class="row">
          <div class="col-lg-3">
            <button class="btn btn-sm btn-primary" (click)="calculateAbsoluteAverage()"> Calculate Target</button>
            <button class="btn btn-sm btn-warning" style="float: right;" (click)="enableManualDataEntryForTargetProvince()">Manually</button>

          </div>
        </div>







<hr/>
<button type="submit" [disabled]="formGroup.invalid && provinceTeamForm.invalid" class="btn btn-primary btn-sm form-control" (click)="submit()">
              <!-- <i class="fa fa-add"></i> -->
Save
</button>

</form>
</div>
</div>



<!-- <table class="table table-sm  table-responsive-md table-responsive-sm" *ngIf="showtable">
  <thead>
    <tr>
      <th>#</th>
      <th [appSort]="activities" data-order="desc" data-name="title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>Title
      </th>
      <th [appSort]="activities" data-order="desc" data-name="outcome?.title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>Outcome
      </th>

      <th [appSort]="activities" data-order="desc" data-name="baseline" scope="col"> <i
        class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
        [hidden]="!showDesc"></i>
      <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
        [hidden]="!showAsc"></i>Baseline Target
    </th>

    <th [appSort]="activities" data-order="desc" data-name="total_target" scope="col"> <i
      class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
      [hidden]="!showDesc"></i>
    <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
      [hidden]="!showAsc"></i>Total Target
  </th>

    </tr>
  </thead>
  <tbody class="table table-sm -group-divider">
    <tr *ngFor="let item of activities; let i = index">
      <th scope="row">{{i+1}}</th>
      <td>{{item.title}}</td>
      <td>{{item.output.title}}</td>
      <td>{{item.baseline_target}}</td>
      <td>{{item.total_taget}}</td>
      <td>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-danger btn-sm" ><i class="fa fa-trash"></i></button>
          <button type="button" class="btn btn-warning btn-sm" ><i class="fa fa-edit"></i></button>
        </div>
      </td>

    </tr>
  </tbody>
</table> -->

