<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Trainings</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Trainings</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button> -->


        <table class="table table-sm  table-responsive" *ngIf="!addObj">
          <thead>
            <tr>
              <!-- <th  [appSort]="objs" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</th> -->
              <th  [appSort]="objs" data-order="desc" data-name="title_en" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Title En</th>
              <th  [appSort]="objs" data-order="desc" data-name="title_dr" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Title Dr</th>
              <th  [appSort]="objs" data-order="desc" data-name="title_pa" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Title Ps</th>
              <th  [appSort]="objs" data-order="desc" data-name="frequency" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Frequency</th>
              <th  [appSort]="objs" data-order="desc" data-name="department" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Department</th>

              <th  [appSort]="objs" data-order="desc" data-name="positions" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Positions</th>

            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.title_en}}</td>
              <td>{{item.title_dr}}</td>
              <td>{{item.title_pa}}</td>
              <td>{{item.frequency}}</td>
              <td>{{item.department}}</td>
              <td>{{getPositions(item.positions)}}</td>
<!--
              <td>
                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-warning" (click)="showDistricts(item)">Districts</button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>

        <hr/>

              </div>
  </div>
</section>
