<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Training Participants</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item active" ><a href="/myspace/participants">Training Participants</a></li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">


        <div class="row">
          <div class="col-lg-4 btn-group">
            <button type="button" class="btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button>
            <button type="button" class="btn btn-success btn-sm" (click)="addEvaluation()">Evaluation</button>

          </div>
          <div class="col-lg-4"></div>
          <div class=" input-group col-lg-4" *ngIf="!addObj">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
            </div>
            <input class="input" (input)="filter($event.target.value)" class="form-control"
              placeholder="Search....">
          </div>
        </div>





      <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()" >
          <p class="text-center header-banner">Program Information</p>
          <div class="row">


          <div class="mb-3 col-lg-3">
            <label class="form-label">Grant</label>
            <ng-select [items]="grants" bindLabel="title" bindValue="id" formControlName="grant_id"
              (change)="loadGrantProvinces($event)">
            </ng-select>
            <print-error [control]="formGroup.get('grant_id')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="form-label">Province</label>

            <ng-select [items]="provinces" bindLabel="name" bindValue="id" formControlName="province_id"
              (change)="loadAdminOrgsChildern($event)">
            </ng-select>
            <print-error [control]="formGroup.get('province_id')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">District</label>
            <ng-select [items]="districts" bindLabel="name" bindValue="id" formControlName="district_id">
            </ng-select>

            <print-error [control]="formGroup.get('district_id')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="form-label">Training Location</label>
            <input type="type" formControlName="location" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('location')"></print-error>

          </div>

            </div>


          <p class="text-center header-banner">Participant Information</p>
          <div class="row">
            <div class="mb-3 col-lg-3">
              <label class="form-label">Full Name</label>
              <input type="type" formControlName="fullname" class="form-control" placeholder="">
              <print-error [control]="formGroup.get('fullname')"></print-error>

            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Father or Husband Name</label>
              <input type="type" formControlName="father_or_husband_name" class="form-control" placeholder="">
              <print-error [control]="formGroup.get('father_or_husband_name')"></print-error>
            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Organization</label>
              <input type="type" formControlName="organization" class="form-control" placeholder="">
              <print-error [control]="formGroup.get('organization')"></print-error>
            </div>


            <div class="mb-3 col-lg-3">
              <label class="form-label">Job Title</label>
              <input type="type" formControlName="job_title" class="form-control" placeholder="">
              <print-error [control]="formGroup.get('job_title')"></print-error>
            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Gender</label>
              <select class="form-control" formControlName="gender">
                <option value="MALE">MALE</option>
                <option value="FEMALE">FEMALE</option>
              </select>
              <print-error [control]="formGroup.get('gender')"></print-error>

            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Phone</label>
              <input type="type" formControlName="phone" class="form-control"
                placeholder="Accepted Pattern: +937xxxxxxxxx">
              <print-error [control]="formGroup.get('phone')"></print-error>

            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Email</label>
              <input type="email" formControlName="email" class="form-control"
                placeholder="someone@example.com">
              <print-error [control]="formGroup.get('email')"></print-error>
            </div>
          </div>




          <div class="mb-3 col-lg-12">
            <label  class="form-label">Remarks</label>
            <textarea type="text" formControlName="remarks" class="form-control" placeholder=""></textarea>
          </div>


          <div class="row">
            <div class="col-6">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>
        </div>

        </form>

        <hr/>

        <table class="table table-sm table-bordered" *ngIf="!addObj">
          <thead>
            <tr>
              <th  [appSort]="objs" data-order="desc" data-name="fullname" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Name</th>
              <th  [appSort]="objs" data-order="desc" data-name="father_or_husband_name" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                    <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Husband or Father Name</th>
              <th  [appSort]="objs" data-order="desc" data-name="gender" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                    <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Gender</th>

              <th  [appSort]="objs" data-order="desc" data-name="organization" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                      <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Organization</th>
                      <th  [appSort]="objs" data-order="desc" data-name="job" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Job</th>
              <th  [appSort]="objs" data-order="desc" data-name="email" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Email</th>
              <th  [appSort]="objs" data-order="desc" data-name="phone" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Phone</th>

              <!-- <th  [appSort]="objs" data-order="desc" data-name="training" scope="col"><i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Improvement (%)</th>

              <th  [appSort]="objs" data-order="desc" data-name="pre_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Pre Score</th>
              <th  [appSort]="objs" data-order="desc" data-name="post_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Post Score</th> -->
              <th  [appSort]="objs" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody class="table table-sm table-bordered -group-divider">
            <tr *ngFor="let item of objs">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.fullname}}</td>
              <td>{{item.father_or_husband_name}}</td>
              <td>{{item.gender}}</td>
              <td>{{item.organization}}</td>
              <td>{{item.job_title}}</td>
              <td>{{item.email}}</td>
              <td>{{item.phone}}</td>
              <!-- <td>{{item.percentage_of_positive_evaluation}}</td>

              <td>{{item.pre_score}}</td>
              <td>{{item.post_score}}</td> -->
              <td>{{item.remarks}}</td>

              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                  <button type="button" class="btn btn-primary" tooltip="Add Training" (click)="trainings(item)"><i class="fa fa-eye"></i></button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-center">
          <span *ngFor="let item of response?.links">
            <button (click)="clickLink(item.url ? item.url : response.prev_page_url)"
              [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)"
              class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''"
              style="color:rgb(0, 0, 0); border: solid #ccc 1px;"></button>
          </span>
        </div>

      </div>
  </div>
</section>
