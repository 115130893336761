<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h4 class="m-0 text-dark">All Submitted Psycho Education</h4>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/dtbs/submission-list">DTBs</a></li>
                    <li class="breadcrumb-item active">All Submitted Psycho Education</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
        <div class="card card-body">
  
          <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add Submission</button> -->
          <div class="row">
            <div class="col-lg-8"></div>
            <div class="input-group col-lg-4">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
              </div>
              <input class="input" (input)="filter($event.target.value)"  class="form-control" placeholder="">
            </div>
          </div>
  
          <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" >
  
              <tr class="bg-primary">
                <td data-order="desc" data-name="id" scope="col"> <i class="fa fa-sort-desc"
                    aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>#
                </td>
                <td data-order="desc" data-name="grant_title" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Grant
                </td>
                <td data-order="desc" data-name="province_name" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Province
                </td>
                <td data-order="desc" data-name="district_name" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>District
                </td>
                <td data-order="desc" data-name="village" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Village
                </td>
                <td data-order="desc" data-name="site_code" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Site Code
                </td>
                <td data-order="desc" data-name="health_facility" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Health Facility
                </td>
                <td data-order="desc" data-name="intervention_modality" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Intervention Modality
                </td>
                <td data-order="desc" data-name="awareness_date" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Awareness Date
                </td>
                <td data-order="desc" data-name="awareness_topic" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Awareness Topic
                </td>
                <td data-order="desc" data-name="focal_point" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Focal Point
                </td>
                <td data-order="desc" data-name="male_idp" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Male IDP
                </td>
                <td data-order="desc" data-name="male_refugee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Male Refugee
                </td>
                <td data-order="desc" data-name="male_returnee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Male Returnee
                </td>
                <td data-order="desc" data-name="male_disable" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Male Disable
                </td>
                <td data-order="desc" data-name="female_idp" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Female IDP
                </td>
                <td data-order="desc" data-name="female_refugee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Female Refugee
                </td>
                <td data-order="desc" data-name="female_returnee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Female Returnee
                </td>
                <td data-order="desc" data-name="female_disable" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Female Disable
                </td>
                <td data-order="desc" data-name="boy_idp" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Boy IDP
                </td>
                <td data-order="desc" data-name="boy_refugee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Boy Refugee
                </td>
                <td data-order="desc" data-name="boy_returnee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Boy Returnee
                </td>
                <td data-order="desc" data-name="boy_disable" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Boy Disable
                </td>
                <td data-order="desc" data-name="girl_idp" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Girl IDP
                </td>
                <td data-order="desc" data-name="girl_refugee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Girl Refugee
                </td>
                <td data-order="desc" data-name="girl_returnee" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>iGrl Returnee
                </td>
                <td data-order="desc" data-name="girl_disable" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Girl Disable
                </td>
                <td data-order="desc" data-name="remarks" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Remarks
                </td>
                <td scope="col">Action</td>
  
              </tr>
            <tbody class="table table-sm -group-divider">
              <tr *ngFor="let item of response?.data; let i = index">
                <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
                <td>{{item.grant.title}}</td>
                <td>{{item.province.name}}</td>
                <td>{{item.district.name}}</td>
                <td>{{item.village}}</td>
                <td>{{item.site_code}}</td>
                <td>{{item.health_facility}}</td>
                <td>{{item.intervention_modality}}</td>
                <td>{{item.awareness_date}}</td>
                <td>{{item.awareness_topic}}</td>
                <td>{{item.focal_point}}</td>
                <td>{{item.male_idp}}</td>
                <td>{{item.male_refugee}}</td>
                <td>{{item.male_returnee}}</td>
                <td>{{item.male_disable}}</td>
                <td>{{item.female_idp}}</td>
                <td>{{item.female_refugee}}</td>
                <td>{{item.female_returnee}}</td>
                <td>{{item.female_disable}}</td>
                <td>{{item.boy_idp}}</td>
                <td>{{item.boy_refugee}}</td>
                <td>{{item.boy_returnee}}</td>
                <td>{{item.boy_disable}}</td>
                <td>{{item.girl_idp}}</td>
                <td>{{item.girl_refugee}}</td>
                <td>{{item.girl_returnee}}</td>
                <td>{{item.girl_disable}}</td>
                <td>{{item.remarks}}</td>

                <td>
  
                  <div class="btn-group" role="group" >
                    <button type="button" class="btn btn-primary"  (click)="download(item)">Download</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
  
             <div class="d-flex justify-content-center mt-1">
            <span *ngFor="let item of response?.links" >
              <button  (click)="clickLink(item.url ? item.url : response.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
            </span>
          </div>
  
  
        </div>
    </div>
  </section>
  