import { Component, Input } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-activity-provice-wise-target',
  templateUrl: './activity-provice-wise-target.component.html',
  styleUrls: ['./activity-provice-wise-target.component.scss']
})
export class ActivityProviceWiseTargetComponent {
  @Input() grantId;
  grant: any;
  formGroup: UntypedFormGroup;
  provinces: any;
  activities: any;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder : FormBuilder
    ) {
        // console.log('id', this.id);
        this.apiService.getGrant(this.grantId).subscribe((response) => {
          this.grant = response;
        });
    }


ngOnInit() {
    this.formGroup = this.formBuilder.group({
        id: ['',[]],
        province_id: [null,[Validators.required]],
        grant_activity_id: [null,[Validators.required]],
        target: ['', [Validators.required]],
      });

      this.loadData();
    }

    loadData(){

      this.apiService.getGrantActivities(this.grantId).subscribe(response => {
        this.activities = response;
      });

      this.apiService.getGrantProvinces(this.grantId).subscribe(response => {
        this.provinces = response;
      });

    }


    submit(){

      this.apiService.createProvinceTarget(this.formGroup.value).subscribe(response => {
        this.toastrService.success("Registration succeeded");
        this.formGroup.reset();
        this.loadData();
      }, (e) => {
        this.toastrService.error("Registration failed");
      })

    }

  }
