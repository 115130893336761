<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h4 class="m-0 text-dark">All Community Dialogues</h4>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/dtbs/submission-list">DTBs</a></li>
                    <li class="breadcrumb-item active">All Submitted Community Dialogues</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
        <div class="card card-body">
  
          <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add Submission</button> -->
          <div class="row">
            <div class="col-lg-8"></div>
            <div class="input-group col-lg-4">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
              </div>
              <input class="input" (input)="filter($event.target.value)"  class="form-control" placeholder="">
            </div>
          </div>
  
          <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" >
  
              <tr class="bg-primary">
                <td data-order="desc" data-name="id" scope="col"> <i class="fa fa-sort-desc"
                    aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>#
                </td>
                <td data-order="desc" data-name="user" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>User
              </td>
                <td data-order="desc" data-name="grant_title" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Grant
                </td>
                <td data-order="desc" data-name="beneficiary_code" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                   [hidden]="!showAsc"></i>Beneficiary Code
              </td>
              <td data-order="desc" data-name="remarks" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                 [hidden]="!showAsc"></i>Remarks
            </td>
            <td data-order="desc" data-name="cd_date" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
               [hidden]="!showAsc"></i>CD Date
          </td>
          <td data-order="desc" data-name="session_type" scope="col"> <i class="fa fa-sort-desc"
            aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
             [hidden]="!showAsc"></i>Session Type
        </td>
        <td data-order="desc" data-name="cd_type" scope="col"> <i class="fa fa-sort-desc"
          aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
           [hidden]="!showAsc"></i>CD Type
        </td>
        <td data-order="desc" data-name="received_incentive" scope="col"> <i class="fa fa-sort-desc"
        aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
         [hidden]="!showAsc"></i>Received Incentive
        </td>
        <td data-order="desc" data-name="role_in_community" scope="col"> <i class="fa fa-sort-desc"
        aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
       [hidden]="!showAsc"></i>Role in Community
        </td>
        <td data-order="desc" data-name="incentive_amount" scope="col"> <i class="fa fa-sort-desc"
        aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
        [hidden]="!showAsc"></i>incentive_amount
        </td>
        <td scope="col">Action</td>
  
              </tr>
            <tbody class="table table-sm -group-divider">
              <tr *ngFor="let item of response?.data; let i = index">
                <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
                <td>{{item.grant.title}}</td>
                <td>{{item.beneficiary.beneficiary_code}}</td>
                <td>{{item.remarks}}</td>
                <td>{{item.cd_date}}</td>
                <td>{{item.session_type}}</td>
                <td>{{item.cd_type}}</td>
                <td>{{item.received_incentive}}</td>
                <td>{{item.role_in_community}}</td>
                <td>{{item.incentive_amount}}</td>
                <td>{{item.incentive_amount}}</td>
                <td>
                  <div class="btn-group" role="group" >
                    <button type="button" class="btn btn-primary"  (click)="download(item)">Download</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
  
             <div class="d-flex justify-content-center mt-1">
            <span *ngFor="let item of response?.links" >
              <button  (click)="clickLink(item.url ? item.url : response.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
            </span>
          </div>
  
  
        </div>
    </div>
  </section>
  