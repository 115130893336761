import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-device-manager',
  templateUrl: './device-manager.component.html',
  styleUrls: ['./device-manager.component.scss']
})
export class DeviceManagerComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public isLoading :boolean = false;
  // objs: any;

  addObj: boolean = false;
  editObj: any;
  roles: any;
  objs: any;
  currentStep = 1;
  approvedResponses: any;
  approvedObjs: any;
  pendingObjs: any;
  pendingResponses: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
      this.loadData();
  }




  loadData(){

    this.apiService.getDevices(1).subscribe(response => {
      this.approvedResponses = response.data;
      this.approvedObjs = response.data.data;
    });

    this.apiService.getDevices(0).subscribe(response => {
      this.pendingResponses = response.data;
      this.pendingObjs = response.data.data;
    });
  }

  filterApproved(text:any)
  {
      text = text.toLowerCase();
      this.approvedResponses.data =  this.approvedResponses.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.approvedResponses.data = this.objs;
      }
  }


  filterPending(text:any)
  {
      text = text.toLowerCase();
      this.pendingResponses.data =  this.pendingResponses.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.pendingResponses.data = this.objs;
      }
  }

  clickLinkApproved(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.approvedResponses = response.data;
      this.objs = response.data.data;
    });
  }

  clickLinkPending(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.pendingResponses = response.data;
      this.objs = response.data.data;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteDevice(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }


  activate(item: any){
    if(window.confirm("Are you sure to allow the user's device?")){
      this.apiService.allowDevice(item.id).subscribe(response => {
        this.toastrService.success("Device Allowed and User can log In with this device.");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }

  nextStep(): void {
    this.currentStep = this.currentStep+1;
  }

  prevStep(): void {
    this.currentStep = this.currentStep - 1;
  }

  changeCurrentStep(step:number){
    this.currentStep = step;
  }


}
