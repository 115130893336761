import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  public settingForm: FormGroup;
  public isLoading: boolean = false;
  user: any;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.settingForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      c_password: ['', [Validators.required]]
    }, {
      validator: this.ConfirmedValidator('password', 'c_password')
    });

    this.loadData();
  }

  loadData() {
    this.apiService.getActiveUser().subscribe(response => {
      this.user = response;
    });
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  submit() {
    if (!this.settingForm.valid) {
      return;
    }

    const formData = new FormData();
    formData.append("current_password", this.settingForm.value.current_password);
    formData.append("password", this.settingForm.value.password);
    formData.append("c_password", this.settingForm.value.c_password);

    this.apiService.updatePassword(formData).subscribe(response => {
      this.isLoading = false;
      this.toastrService.success("Password update succeeded");
      this.settingForm.reset();
    }, (e) => {
      this.toastrService.error("Password update failed!");
      this.isLoading = false;
    });
  }
}
