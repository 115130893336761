<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Training Pre and Post Evaluation </h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
                  <li class="breadcrumb-item active">Training Evaluation </li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <button type="button" *ngIf="addValue" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button>


        <table class="table table-sm  table-responsive table-bordered" *ngIf="!addObj">
          <thead>
            <tr>
              <th  [appSort]="objs" data-order="desc" data-name="training" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Training</th>
              <th  [appSort]="objs" data-order="desc" data-name="pre_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Pre Score</th>
              <th  [appSort]="objs" data-order="desc" data-name="post_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Post Score</th>
              <th  [appSort]="objs" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</th>

<th scope="col">Action</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.training.training_topic}}</td>
              <td>{{item.pre_score}}</td>
              <td>{{item.post_score}}</td>
              <td>{{item.remarks}}</td>

              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">
          <div class="row">

            <div class="mb-3 col-lg-6">
              <label  class="form-label">Training</label>
                          <ng-select [items]="trainings"
              bindLabel="training_topic"
              bindValue="id"
              formControlName="training_id"
            >
           </ng-select>

            </div>

            <div class="mb-3 col-lg-6">
              <label  class="form-label">Pre Score</label>
              <input type="number" formControlName="pre_score" class="form-control" placeholder="">
            </div>

            <div class="mb-3 col-lg-6">
              <label  class="form-label">Post Score</label>
              <input type="number" formControlName="post_score" class="form-control" placeholder="">
            </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Remarks</label>
            <textarea type="text" formControlName="remarks" class="form-control" placeholder=""></textarea>
          </div>


        </div>
          <div class="row">
            <div class="col-6">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          <div class="col-6">

        </div>

          </div>

        </form>


      </div>
  </div>
</section>
