import { Component, Input } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-beneficiary-evaluation-termination',
  templateUrl: './beneficiary-evaluation-termination.component.html',
  styleUrls: ['./beneficiary-evaluation-termination.component.scss']
})
export class BeneficiaryEvaluationTerminationComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  @Input() beneficiaryId: any;
  addValue: any = true;
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  beneficiary: any;
  activities: any;

  clientEvaluationResults: { value: string, displayValue: string }[] = [
    { value: "New information / knowledge", displayValue: "New information / knowledge" },
    { value: "A listening space", displayValue: "A listening space" },
    { value: "Avoid isolation", displayValue: "Avoid isolation" },
    { value: "Time and place to relax", displayValue: "Time and place to relax" },
    { value: "Improve mother-child bonding", displayValue: "Improve mother-child bonding" },
    { value: "Sharing experience", displayValue: "Sharing experience" },
    { value: "Learning new practices", displayValue: "Learning new practices" },
    { value: "Improve wellbeing", displayValue: "Improve wellbeing" },
    { value: "Meet other people", displayValue: "Meet other people" },
    { value: "Quiet and safe place", displayValue: "Quiet and safe place" },
    { value: "Other", displayValue: "Other, please specify" }
  ];

  satisfactionLevel: { value: string, displayValue: string }[] = [
    { value: "Not at all Satisfied", displayValue: "Not at all Satisfied" },
    { value: "Partly Satisfied", displayValue: "Partly Satisfied" },
    { value: "Satisfied", displayValue: "Satisfied" },
    { value: "More than Satisfied", displayValue: "More than Satisfied" },
    { value: "Very Satisfied", displayValue: "Very Satisfied" }
  ];


  reasonsForDischarge: { value: string, displayValue: string }[] = [
    { value: "PSS cycle completed", displayValue: "PSS cycle completed" },
    { value: "Improved wellbeing", displayValue: "Improved wellbeing" },
    { value: "No longer interested", displayValue: "No longer interested" },
    { value: "Other child(ren) at home", displayValue: "Other child(ren) at home" },
    { value: "Husband /family not granting permission", displayValue: "Husband /family not granting permission" },
    { value: "Referral", displayValue: "Referral" },
    { value: "No improvement", displayValue: "No improvement" },
    { value: "Death of caregiver", displayValue: "Death of caregiver" },
    { value: "Non-attendance", displayValue: "Non-attendance" },
    { value: "Displacement", displayValue: "Displacement" },
    { value: "Workload", displayValue: "Workload" },
    { value: "Death of Child", displayValue: "Death of Child" },
    { value: "Insecurity", displayValue: "Insecurity" },
    { value: "Other", displayValue: "Other" }
  ];
  evaluation: any;
  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder
      ){

        // this.activedRoute.params.subscribe((params) => {
        //   this.beneficiaryId = params['id'];
        //   this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
        //     this.beneficiary = response;
        //     // this.apiService.getGrantActivities(this.beneficiary.grant_id).subscribe(response => {
        //     //   this.activities = response;
        //     // });
        //   });
        // });
    }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',],
      grant_id: ['', []],
      beneficiary_id: ['', []],
      client_evaluation_date: ['', [Validators.required]],
      client_evaluation_results: ['', [Validators.required]],
      client_evaluation_other: ['', []],
      client_satisfaction_date: ['', [Validators.required]],
      statisfation_level: ['', [Validators.required]],
      client_satisfaction_other: ['', []],
      client_discharge_date: ['', [Validators.required]],
      reasons_for_discharge: ['', [Validators.required]],
      discharge_other_reasons: ['', []],
      remarks: ['', []],
    });

      this.loadData();
  }

  loadData(){
    this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
      this.beneficiary = response;
      // this.apiService.getGrantActivities(this.beneficiary.grant_id).subscribe(response => {
      //   this.activities = response;
      // });
    });
    this.apiService.getBeneficiaryEvaluation(this.beneficiaryId).subscribe(response => {
      this.evaluation = response;
      this.formGroup.controls['client_evaluation_date'].setValue(this.evaluation?.client_evaluation_date);
      this.formGroup.controls['client_evaluation_results'].setValue(this.evaluation?.client_evaluation_results);
      this.formGroup.controls['client_evaluation_other'].setValue(this.evaluation?.client_evaluation_other);
      this.formGroup.controls['client_satisfaction_date'].setValue(this.evaluation?.client_satisfaction_date);
      this.formGroup.controls['statisfation_level'].setValue(this.evaluation?.statisfation_level);
      this.formGroup.controls['client_satisfaction_other'].setValue(this.evaluation?.client_satisfaction_other);

      this.formGroup.controls['client_discharge_date'].setValue(this.evaluation?.client_discharge_date);
      this.formGroup.controls['reasons_for_discharge'].setValue(this.evaluation?.reasons_for_discharge);
      this.formGroup.controls['discharge_other_reasons'].setValue(this.evaluation?.discharge_other_reasons);
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteBeneficiaryEvaluation(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['client_evaluation_date'].setValue(item.client_evaluation_date);
      this.formGroup.controls['client_evaluation_results'].setValue(item.client_evaluation_results);
      this.formGroup.controls['client_evaluation_other'].setValue(item.client_evaluation_other);
      this.formGroup.controls['client_satisfaction_date'].setValue(item.client_satisfaction_date);
      this.formGroup.controls['statisfation_level'].setValue(item.statisfation_level);
      this.formGroup.controls['client_satisfaction_other'].setValue(item.client_satisfaction_other);
      this.formGroup.controls['client_discharge_date'].setValue(item.client_discharge_date);
      this.formGroup.controls['reasons_for_discharge'].setValue(item.reasons_for_discharge);
      this.formGroup.controls['discharge_other_reasons'].setValue(item.discharge_other_reasons);
      this.formGroup.controls['remarks'].setValue(item.remarks);

      this.editObj = item;
    }
  }




  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);
    this.formGroup.controls['grant_id'].setValue(this.beneficiary.grant_id);
    this.formGroup.controls['beneficiary_id'].setValue(this.beneficiary.id);

    this.apiService.createBeneficiaryEvaluation(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}
