import { Component, OnInit } from '@angular/core';
import { KoboService } from '../../kobo.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apr-overview',
  templateUrl: './apr-overview.component.html',
  styleUrls: ['./apr-overview.component.scss']
})
export class AprOverviewComponent implements OnInit {
  koboData: any[] = [];

  constructor(
    private koboService: KoboService,
    private toastrService: ToastrService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.koboService.getKoboData().subscribe(data => {
      this.koboData = data; // Adjust based on your API response structure
    });
  }

  process(item: any): void {
    this.koboService.processBeneficiaryForm(item).subscribe(
      response => {
        console.log(response);
        this.toastrService.success('Status updated to VALIDATED');
        this.loadData(); // Refresh the data after updating the status
      },
      error => {
        this.toastrService.error('Failed to update status');
      }
    );
  }

  loadData(): void {
    this.koboService.getKoboData().subscribe(data => {
      this.koboData = data;
    });
  }
}