import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { UserHomeComponent } from '@pages/user/user-home/user-home.component';
import { TrainingHomeComponent } from '@pages/training/training-home/training-home.component';
import { DepartmentHomeComponent } from '@pages/department/department-home/department-home.component';
import { AdministrativeHomeComponent } from '@pages/administrative/administrative-home/administrative-home.component';
import { MyspaceHomeComponent } from '@pages/myspace/myspace-home/myspace-home.component';
import { GrantHomeComponent } from '@pages/grant/grant-home/grant-home.component';
import { GrantCreateComponent } from '@pages/grant/grant-create/grant-create.component';
import { GrantListComponent } from '@pages/grant/grant-list/grant-list.component';
import { PermissionComponent } from '@pages/user/permission/permission.component';
import { RoleComponent } from '@pages/user/role/role.component';
import { BlockedIpComponent } from '@pages/user/blocked-ip/blocked-ip.component';
import { DisaggregationComponent } from '@pages/settings/disaggregation/disaggregation.component';
import { GrantUpdateComponent } from '@pages/grant/grant-update/grant-update.component';
import { GrantConfigureComponent } from '@pages/grant/grant-configure/grant-configure.component';
import { GrantDetailsComponent } from '@pages/grant/grant-details/grant-details.component';
import { GrantStatusComponent } from '@pages/grant/grant-status/grant-status.component';
import { BeneficiaryComponent } from '@pages/myspace/beneficiary/beneficiary.component';
import { KoboComponent } from '@pages/kobo/kobo.component'; // Adjust the path as needed
import { ReferralComponent } from '@pages/myspace/referral/referral.component';
import { KitDistributionComponent } from '@pages/myspace/kit-distribution/kit-distribution.component';
import { CommunityDialogueComponent } from '@pages/myspace/community-dialogue/community-dialogue.component';
import { PsychoeducationComponent } from '@pages/myspace/psychoeducation/psychoeducation.component';
import { EquipComponent } from '@pages/myspace/equip/equip.component';
import { DtbComponent } from '@pages/myspace/dtb/dtb.component';
import { AssessmentComponent } from '@pages/myspace/assessment/assessment.component';
import { BeneficiaryServicesComponent } from '@pages/myspace/beneficiary-services/beneficiary-services.component';
import { BeneficiaryDetailsComponent } from '@pages/myspace/beneficiary-details/beneficiary-details.component';
import { BeneficiaryActivityComponent } from '@pages/myspace/beneficiary-activity/beneficiary-activity.component';
import { BeneficiaryMealComponent } from '@pages/myspace/beneficiary-meal/beneficiary-meal.component';
import { BeneficiaryReferralComponent } from '@pages/myspace/beneficiary-referral/beneficiary-referral.component';
import { BeneficiaryEvaluationTerminationComponent } from '@pages/myspace/beneficiary-evaluation-termination/beneficiary-evaluation-termination.component';
import { BeneficiaryReferralDetailsComponent } from '@pages/myspace/beneficiary-referral-details/beneficiary-referral-details.component';
import { BeneficiarySearchComponent } from '@pages/myspace/beneficiary-search/beneficiary-search.component';
import { PsychoeducationDetailsComponent } from '@pages/myspace/psychoeducation-details/psychoeducation-details.component';
import { TrainingComponent } from '@pages/myspace/training/training.component';
import { TrainingPrePostEvaluationComponent } from '@pages/myspace/training-pre-post-evaluation/training-pre-post-evaluation.component';
import { TrainingStastisfactionEvaluationComponent } from '@pages/myspace/training-stastisfaction-evaluation/training-stastisfaction-evaluation.component';
import { DtbOverviewComponent } from '@pages/management/dtb-overview/dtb-overview.component';
import { AllSubmittedComponent } from '@pages/management/dtb-overview/submitted-beneficiary-details/submitted-beneficiary-details.component';
import { SubmittedKitDistributionDetailsComponent } from '@pages/management/dtb-overview/submitted-kit-distribution-details/submitted-kit-distribution-details.component';
import { SubmittedPsychoEducationDetailsComponent } from '@pages/management/dtb-overview/submitted-psycho-education-details/submitted-psycho-education-details.component';
import { DtbSubmissionComponent } from '@pages/management/dtb-submission/dtb-submission.component';
import { UserLogComponent } from '@pages/logs/user-log/user-log.component';
import { ErrorLogComponent } from '@pages/logs/error-log/error-log.component';
import { IndicatorGroupComponent } from '@pages/settings/indicator-group/indicator-group.component';
import { DtbSubmissionListComponent } from '@pages/management/dtb-submission-list/dtb-submission-list.component';
import { FinalizedSubmissionsComponent } from '@pages/management/finalized-submissions/finalized-submissions.component';
import { PortifolioSummaryComponent } from '@pages/portifolio-summary/portifolio-summary.component';
import { ProfileComponent } from '@pages/user/profile/profile.component';
import { RejectedSubmissionsComponent } from '@pages/management/rejected-submissions/rejected-submissions.component';
import { GrantCreateConfigurationsComponent } from '@pages/grant/grant-create-configurations/grant-create-configurations.component';
import { DeviceManagerComponent } from '@pages/user/device-manager/device-manager.component';
import { UnauthorizedComponent } from '@pages/errors/unauthorized/unauthorized.component';
import { CommunityDialogueFollowupsComponent } from '@pages/myspace/community-dialogue-followups/community-dialogue-followups.component';
import { TrainingParticipantsComponent } from '@pages/myspace/training-participants/training-participants.component';
import { BeneficiaryTabServicesComponent } from '@pages/myspace/beneficiary-tab-services/beneficiary-tab-services.component';
import { SubmittedCommunityDialogueDetailsComponent } from '@pages/management/dtb-overview/submitted-community-dialogue-details/submitted-community-dialogue-details.component';
import { SubmittedTrainingsDetailsComponent } from '@pages/management/dtb-overview/submitted-trainings-details/submitted-trainings-details.component';
import { DtbApprovedListComponent } from '@pages/management/dtb-approved-list/dtb-approved-list.component';
import { SettingComponent } from '@pages/user/setting/setting.component';
import {  AprOverviewComponent } from '@pages/apr-overview/apr-overview.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
              path: 'settting',
              component: SettingComponent
          },



            {
              path: 'portfolio-summary',
              component: PortifolioSummaryComponent
             },
            {
              path: 'myspace',
              component: MyspaceHomeComponent
             },

             {
              path: 'beneficiaries',
              component: BeneficiaryComponent
             },

             {
              path: 'beneficiaries/search/:id',
              component: BeneficiarySearchComponent
             },

             {
              path: 'beneficiaries/services/:id',
              component: BeneficiaryServicesComponent
             },

             {
              path: 'beneficiaries/services/tab/:id',
              component: BeneficiaryTabServicesComponent
             },

             {
              path: 'beneficiaries/activities/:id',
              component: BeneficiaryActivityComponent
             },
             {
              path: 'beneficiaries/meals/:id',
              component: BeneficiaryMealComponent
             },
             {
              path: 'beneficiaries/referrals/details/:id',
              component: BeneficiaryReferralDetailsComponent
             },

             {
              path: 'beneficiaries/referrals/:id',
              component: BeneficiaryReferralComponent
             },

             {
              path: 'beneficiaries/evaluation-terminations/:id',
              component: BeneficiaryEvaluationTerminationComponent
             },

             {
              path: 'beneficiaries/details/:id',
              component: BeneficiaryDetailsComponent
             },
             {
              path: 'referrals',
              component: ReferralComponent
             },

             {
              path: 'beneficiaries/kit-distributions/:id',
              component: KitDistributionComponent
             },

             {
              path: 'beneficiaries/community-dialogues/:id',
              component: CommunityDialogueComponent
             },

             {
              path: 'beneficiaries/community-dialogues/follow-ups/:id',
              component: CommunityDialogueFollowupsComponent
             },

            //  {
            //   path: 'myspace/trainings',
            //   component: TrainingComponent
            //  },

             {
              path: 'myspace/participants',
              component: TrainingParticipantsComponent
             },

             {
              path: 'myspace/trainings/:id',
              component: TrainingComponent
             },

             {
              path: 'beneficiaries/trainings-tests/:id',
              component: TrainingPrePostEvaluationComponent
             },

             {
              path: 'myspace/trianing-evaluations',
              component: TrainingStastisfactionEvaluationComponent
             },

             {
              path: 'dtbs/overview',
              component: DtbOverviewComponent
             },
             {
              path: 'dtbs/overview/submitted-beneficiary',
              component: AllSubmittedComponent
             },
             {
              path: 'dtbs/overview/submitted-kit-distribution',
              component: SubmittedKitDistributionDetailsComponent
             },
             {
              path: 'dtbs/overview/submitted-psycho-education',
              component: SubmittedPsychoEducationDetailsComponent
             },
             {
              path: 'dtbs/overview/submitted-community-dialogues',
              component: SubmittedCommunityDialogueDetailsComponent
             },
             {
              path: 'dtbs/overview/submitted-trainings',
              component: SubmittedTrainingsDetailsComponent
             },
             {
              path: 'dtbs/submission',
              component: DtbSubmissionComponent
             },

             {
              path: 'dtbs/submission-list',
              component: DtbSubmissionListComponent
             },
             {
              path: 'dtbs/approved-list',
              component: DtbApprovedListComponent
             },
             {
              path: 'dtbs/submission-finalized',
              component: FinalizedSubmissionsComponent
             },


             {
              path: 'dtbs/submission-rejected',
              component: RejectedSubmissionsComponent
             },
             {
              path: 'psychoeducations',
              component: PsychoeducationComponent
             },

             {
              path: 'psychoeducations/details/:id',
              component: PsychoeducationDetailsComponent
             },

             {
              path: 'equips',
              component: EquipComponent
             },

             {
              path: 'dtbs',
              component: DtbComponent
             },

             {
              path: 'assessments',
              component: AssessmentComponent
             },

            {
                path: 'grant/overview',
                component: GrantListComponent
            },
            {
                path: 'apr/overview',
                component: AprOverviewComponent
            },
            {
              path: 'grant/create',
              component: GrantCreateComponent
            },

            {
              path: 'grant/create/:id',
              component: GrantCreateComponent
            },
            {
              path: 'grant/update/:id',
              component: GrantUpdateComponent
            },

            {
              path: 'grant-status',
              component: GrantStatusComponent
            },

            {
              path: 'project-status',
              component: GrantStatusComponent
            },

            {
              path: 'logs/user-logs',
              component: UserLogComponent
            },

            {
              path: 'logs/error-logs',
              component: ErrorLogComponent
            },

            {
              path: 'grant/configure/:id',
              component: GrantCreateConfigurationsComponent
            },

            {
              path: 'grant/details/:id',
              component: GrantDetailsComponent
            },


            {
              path: 'grant/list',
              component: GrantListComponent
            },


            {
              path: 'users',
              component: UserHomeComponent
            },

            {
              path: 'device-manager',
              component: DeviceManagerComponent
            },

            {
              path: 'permissions',
              component: PermissionComponent
            },
            {
              path: 'roles',
              component: RoleComponent
            },

            {
              path: 'blockedips',
              component: BlockedIpComponent
            },
            {
              path: 'disaggregations',
              component: DisaggregationComponent
            },

            {
              path: 'indicator-groups',
              component: IndicatorGroupComponent
            },
            {
              path: 'trainings',
              component: TrainingHomeComponent
            },
            {
              path: 'departments',
              component: DepartmentHomeComponent
            },

            {
                path: 'administratives',
                component: AdministrativeHomeComponent
            },


            {
              path: '401',
              component: UnauthorizedComponent
            },
            {
              path: 'kobo', // Add this new route
              component: KoboComponent
            },
            {
                path: '',
                component: DashboardComponent
                // component: TnaHomeComponent

            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
