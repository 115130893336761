<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Configure Grant</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item"><a href="/grant/overview">Grants</a></li>
                  <li class="breadcrumb-item"><a href="/grant/details/{{grant.id}}">{{grant?.title}}</a></li>

                  <li class="breadcrumb-item active">Project Code: {{grant.code}}</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">
        <h4>Goals</h4>
        <div class="row">
          <div class="col-6">

            <form [formGroup]="formGroup" (ngSubmit)="submit()">

              <div class="mb-3">
                <label  class="form-label">Title</label>
                <textarea type="type" formControlName="title" class="form-control" placeholder=""></textarea>
              </div>

              <div class="row">
                <div class="col-6">
                 <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                    Add
                  </button>
              </div>

              </div>

            </form>

          </div>

          <div class="col-6">

            <table class="table table-sm  table-responsive">
              <thead>
                <tr>
                  <!-- <th [appSort]="objs" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</th> -->
                  <th [appSort]="objs" data-order="desc" data-name="title" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Title</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody class="table table-sm -group-divider">
                <tr *ngFor="let item of objs; let i = index">
                  <!-- <th scope="row">{{i+1}}</th> -->
                  <td>{{item.title}}</td>
                  <td>

                    <div class="btn-group" role="group" >
                      <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                      <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>


          </div>

        </div>
      </div>
  </div>
</section>

<!-- /////////////////////////////////////////////////// -->
                      <!-- Outcomes -->
    <!-- /////////////////////////////////////////////////// -->
    <section class="content" style="padding: 1%;">
      <div class="container-fluid">
          <div class="card card-body">
            <h4>Outcomes</h4>
            <div class="row">
              <div class="col-6">

                <form [formGroup]="outcomeFormGroup" (ngSubmit)="submitOutcome()">

                  <div class="mb-3">
                    <label  class="form-label">Title</label>
                    <textarea type="type" formControlName="title" class="form-control" placeholder=""></textarea>
                  </div>

                  <div class="row">
                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">Baseline</label>
                      <input type="number" formControlName="baseline" class="form-control" placeholder="">
                    </div>

                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">Target</label>
                      <input type="number" formControlName="target" class="form-control" placeholder="">
                    </div>
                  </div>

                  <div class="mb-3">
                    <label  class="form-label">Link to</label>
                    <select class="form-control" formControlName="grant_goal_id">
                      <option></option>
                      <option *ngFor="let item of objs" [value]="item.id">{{item.title}}</option>
                    </select>

                  </div>


                  <div class="row">
                    <div class="col-6">
                     <button type="submit" [disabled]="outcomeFormGroup.invalid" style="width: 100%;" class="btn btn-primary">
                        Add
                      </button>
                  </div>

                  </div>

                </form>

              </div>

              <div class="col-6">

                <table class="table table-sm  table-responsive">
                  <thead>
                    <tr>
                      <th [appSort]="outcomes" data-order="desc" data-name="title" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Title</th>
                      <th [appSort]="outcomes" data-order="desc" data-name="baseline" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Baseline</th>
                      <th [appSort]="outcomes" data-order="desc" data-name="target" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Target</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody class="table table-sm -group-divider">
                    <tr *ngFor="let item of outcomes; let i = index">
                      <td>{{item.title}}</td>
                      <td>{{item.baseline}}</td>
                      <td>{{item.target}}</td>
                      <td>

                        <div class="btn-group" role="group" >
                          <button type="button" class="btn btn-danger" (click)="deleteOutcome(item)"><i class="fa fa-trash"></i></button>
                          <button type="button" class="btn btn-success" (click)="editOutcome(item)"><i class="fa fa-pen"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </section>


    <!-- /////////////////////////////////////////////////// -->
                      <!-- Outputs -->
    <!-- /////////////////////////////////////////////////// -->
    <section class="content" style="padding: 1%;">
      <div class="container-fluid">
          <div class="card card-body">
            <h4>Outputs</h4>
            <div class="row">
              <div class="col-6">

                <form [formGroup]="outputFormGroup" (ngSubmit)="submitOutput()">

                  <div class="mb-3">
                    <label  class="form-label">Title</label>
                    <textarea type="type" formControlName="title" class="form-control" placeholder=""></textarea>
                  </div>

                  <div class="row">
                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">Baseline</label>
                      <input type="number" formControlName="baseline" class="form-control" placeholder="">
                    </div>

                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">Target</label>
                      <input type="number" formControlName="target" class="form-control" placeholder="">
                    </div>
                  </div>

                  <div class="mb-3">
                    <label  class="form-label">Link to</label>
                    <select class="form-control" formControlName="grant_outcome_id">
                      <option></option>
                      <option *ngFor="let item of outcomes" [value]="item.id">{{item.title}}</option>
                    </select>
                  </div>


                  <div class="row">
                    <div class="col-6">
                     <button type="submit" [disabled]="outputFormGroup.invalid" style="width: 100%;" class="btn btn-primary">
                        Add
                      </button>
                  </div>

                  </div>

                </form>

              </div>

              <div class="col-6">

                <table class="table table-sm  table-responsive">
                  <thead>
                    <tr>
                      <!-- <th [appSort]="objs" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</th> -->
                      <th [appSort]="outputs" data-order="desc" data-name="title" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Title</th>
                      <th [appSort]="outputs" data-order="desc" data-name="baseline" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Baseline</th>
                      <th [appSort]="outputs" data-order="desc" data-name="target" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Target</th>
                      <!-- <th [appSort]="objs" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Goal</th> -->
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody class="table table-sm -group-divider">
                    <tr *ngFor="let item of outputs; let i = index">
                      <!-- <th scope="row">{{i+1}}</th> -->
                      <td>{{item.title}}</td>
                      <td>{{item.baseline}}</td>
                      <td>{{item.target}}</td>
                      <!-- <td>{{item.goal.title}}</td> -->
                      <td>

                        <div class="btn-group" role="group" >
                          <button type="button" class="btn btn-danger" (click)="deleteOutput(item)"><i class="fa fa-trash"></i></button>
                          <button type="button" class="btn btn-success" (click)="editOutput(item)"><i class="fa fa-pen"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </section>



    <!-- /////////////////////////////////////////////////// -->
                      <!-- Activity -->
    <!-- /////////////////////////////////////////////////// -->
    <section class="content" style="padding: 1%;">
      <div class="container-fluid">
          <div class="card card-body">
            <h4>Activities</h4>
            <div class="row">
              <div class="col-5">

                <form [formGroup]="activitiesFormGroup" (ngSubmit)="submitActivity()">

                  <div class="mb-3">
                    <label  class="form-label">Title</label>
                    <textarea type="type" formControlName="title" class="form-control" placeholder=""></textarea>
                  </div>

                  <div class="row">
                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">ACF</label>
                      <input type="number" formControlName="acf" class="form-control" placeholder="">
                    </div>

                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">Total</label>
                      <input type="number" formControlName="total" class="form-control" placeholder="">
                    </div>

                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">Baseline Target</label>
                      <input type="number" formControlName="baseline_target" class="form-control" placeholder="">
                    </div>

                    <div class="mb-3 col-lg-6">
                      <label  class="form-label">Total Target</label>
                      <input type="number" formControlName="total_target" class="form-control" placeholder="">
                    </div>
                  </div>

                  <div class="mb-3">
                    <label  class="form-label">Disaggregation</label>
                    <ng-select [items]="disaggregations"
                    bindLabel="name"
                    bindValue="id"
                    formControlName="disaggregations"
                    [multiple]="true">
                  </ng-select>
                  </div>

                  <div class="mb-3">
                    <label  class="form-label">Link to</label>
                    <select class="form-control" formControlName="grant_output_id">
                      <option></option>
                      <option *ngFor="let item of outputs" [value]="item.id">{{item.title}}</option>
                    </select>
                  </div>


                  <div class="row">
                    <div class="col-6">
                     <button type="submit" [disabled]="activitiesFormGroup.invalid" style="width: 100%;" class="btn btn-primary">
                        Add
                      </button>
                  </div>

                  </div>

                </form>

              </div>

              <div class="col-7">

                <table class="table table-sm  table-responsive">
                  <thead>
                    <tr>
                      <!-- <th [appSort]="objs" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</th> -->
                      <th [appSort]="activities" data-order="desc" data-name="title" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Title</th>
                      <th [appSort]="activities" data-order="desc" data-name="acf" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>ACF</th>
                      <th [appSort]="activities" data-order="desc" data-name="total" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Total</th>
                      <th [appSort]="activities" data-order="desc" data-name="baseline_target" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Baseline</th>
                      <th [appSort]="activities" data-order="desc" data-name="total_target" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Target</th>
                      <!-- <th [appSort]="objs" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Goal</th> -->
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody class="table table-sm -group-divider">
                    <tr *ngFor="let item of activities; let i = index">
                      <!-- <th scope="row">{{i+1}}</th> -->
                      <td>{{item.title}}</td>
                      <td>{{item.acf}}</td>
                      <td>{{item.total}}</td>
                      <td>{{item.baseline_target}}</td>
                      <td>{{item.total_target}}</td>
                      <!-- <td>{{item.goal.title}}</td> -->
                      <td>

                        <div class="btn-group" role="group">
                          <button type="button" class="btn btn-danger" (click)="deleteActivity(item)"><i class="fa fa-trash"></i></button>
                          <button type="button" class="btn btn-warning" (click)="setTarget(item)">Calculate Target</button>
                          <button type="button" class="btn btn-success" (click)="editActivity(item)"><i class="fa fa-pen"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <app-grant-target *ngIf="targetAssignment" [grantId]="id" [activity]="targetActivity"></app-grant-target>
      </div>
    </section>
