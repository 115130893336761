import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-blocked-ip',
  templateUrl: './blocked-ip.component.html',
  styleUrls: ['./blocked-ip.component.scss']
})
export class BlockedIpComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: FormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  roles: any;
  response: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router
  ) {}

  ngOnInit() {
      this.formGroup = new UntypedFormGroup({
          id: new UntypedFormControl(null,[]),
          name: new UntypedFormControl(null, Validators.required),
          host: new UntypedFormControl(null, [Validators.required,]),
          remarks: new UntypedFormControl(null, Validators.required),
      });

      this.loadData();
  }

  loadData(){
    this.apiService.getBlockedIps().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteBlockedIp(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['name'].setValue(item.name);
      this.formGroup.controls['host'].setValue(item.host);
      this.formGroup.controls['remarks'].setValue(item.remarks);
      this.editObj = item;
    }
  }


  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createBlockedIp(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

  getRoles(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.name);
    });

    return values;
  }

  getIds(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.id);
    });

    return values;
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    })
  }

  filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

}
