import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-grant-home',
  templateUrl: './grant-home.component.html',
  styleUrls: ['./grant-home.component.scss']
})
export class GrantHomeComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  constructor(private router: Router, public apiService: ApiService){
  }

  route(path: string){
    this.router.navigateByUrl(path);
  }

}
