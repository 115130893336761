import { Component } from '@angular/core';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

}
