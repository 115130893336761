<!-- <div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Referral Services </h4>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>

                  <li class="breadcrumb-item active">Referrals</li>
              </ol>
          </div>
      </div>
  </div>
</div> -->

        <!-- <button type="button" *ngIf="addValue" class="col-lg-1 mb-1 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add New</button> -->


        <!-- <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

            <tr>
              <td  [appSort]="objs" data-order="desc" data-name="case_no" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Case No</td>
              <td  [appSort]="objs" data-order="desc" data-name="referral_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Referral Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="referral_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Referral Type</td>
              <td  [appSort]="objs" data-order="desc" data-name="referredby_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Referred By</td>
              <td  [appSort]="objs" data-order="desc" data-name="referredto_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Referred To </td>

              <td scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <td>{{item.case_no}}</td>
              <td>{{item.referral_date | date}}</td>
              <td>{{item.referral_type }}</td>
              <td>{{item.referredby_name}} / {{item.referredby_agency}}</td>
              <td>{{item.referredto_name}} / {{item.referredto_agency}}</td>

              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-primary" (click)="details(item)"><i class="fa fa-eye"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/> -->

        <div class="row">
          <div class="col-lg-9"></div>
          <div class="col-lg-3">
            <div class="btn-group">
              <button type="button" class="btn btn-primary btn-sm" (click)="confirmReferral()">Confirm Referral</button>
              <button type="button" class="btn btn-warning btn-sm" (click)="edit()">Edit</button>
              <button type="button" class="btn btn-success btn-sm" (click)="savePDF()">PDF</button>
            </div>
          </div>
        </div>

<!-- Main content -->
<section class="content" id="content" #content>
  <div class="container-fluid">
      <div class="card card-body" >



        <form [formGroup]="formGroup" (ngSubmit)="submit()">
          <div class="row">

            <div class="mb-3 col-lg-12">
              <label class="form-label">1. Is there or do you have any concern regarding and before the referral? </label>
              <div>
                <label class="radio-inline">
                  <input type="radio" formControlName="is_there_concern_before_referral" #is_there_concern_before_referral [value]="'YES'"> Yes, please explain
                </label>
                <label class="radio-inline radio-spacing">
                  <input type="radio" formControlName="is_there_concern_before_referral" [value]="'NO'"> No
                </label>
              </div>
            </div>


            <div class="mb-3 col-lg-12" *ngIf="is_there_concern_before_referral.checked">
              <label  class="form-label">Concern Before Referral</label>
              <textarea type="text" formControlName="concern_before_referral" class="form-control form-control-sm" placeholder=""></textarea>
            </div>


            <div class="mb-3 col-lg-12">
              <label class="form-label">2. In case you need further support, do you want to be referred to any other service provider for additional support? </label>
              <div>
                <label class="radio-inline">
                  <input type="radio" formControlName="is_there_need_for_further_support" [value]="'YES'"> Yes
                </label>
                <label class="radio-inline radio-spacing">
                  <input type="radio" formControlName="is_there_need_for_further_support" [value]="'NO'"> No
                </label>

                <label class="radio-inline radio-spacing">
                  <input type="radio" formControlName="is_there_need_for_further_support" [value]="'N/A'"> N/A
                </label>
              </div>
            </div>

            <div>
              <p>I, <b>{{this.beneficiary?.client_name}}</b> ,  by saying “Yes”, to the question 2 in referral, give my consent to Action Against Hunger to refer me to the appropriate service provider in order for me to receive appropriate (additional) assistance. This includes the sharing of my personal information by the organization with the third party service provider.  </p>


              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="readUnderstood" formControlName="read_and_understood_the_questions">
                <label class="form-check-label" for="readUnderstood">I have read and understood the below information</label>
              </div>
              <ul>
                <li>The organization referring is only interested in seeking additional assistance for the individual.</li>
                <li>The above-mentioned points are my current problem/complaints, it has told by me/ relative <input type="text" formControlName="relation" class="" placeholder=""> & it is ok.</li>
                <li>I have been informed of any possible positive or negative effects of this referral by the organization.</li>
                <li>In case I face any challenges with the service provider after the referral has been made, I acknowledge that the organization that made the referral does not bear responsibility for that.</li>
              </ul>
            </div>

       </div>


       <div class="row">

        <div class="col-lg-6">
          <p class="header-banner text-center">Referred By</p>


          <div class="col-lg-12">
            <label  class="form-label">Case No</label> : {{this.formGroup?.get('case_no').value}}
            <!-- <input type="text" formControlName="case_no" class="form-control form-control-sm" placeholder="" readonly> -->
          </div>

          <div class="col-lg-12">
            <label class="form-label">Referral Type</label>
            <div>
              <label class="radio-inline">
                <input type="radio" formControlName="referral_type" [value]="'Internal'"> Internal
              </label>
              <label class="radio-inline radio-spacing">
                <input type="radio" formControlName="referral_type" [value]="'External'"> External
              </label>
            </div>
          </div>



          <div class="col-lg-12">
            <label  class="form-label">Referred By Name</label>
            <input type="text" formControlName="referredby_name" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-12">
            <label  class="form-label">Referred By Agency</label>
            <input type="text" formControlName="referredby_agency" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-12">
            <label  class="form-label">Referred By Position</label>
            <input type="text" formControlName="referredby_position" class="form-control form-control-sm" placeholder="">
          </div>


          <div class="col-lg-12">
            <label  class="form-label">Referred By Phone</label>
            <input type="text" formControlName="referredby_phone" class="form-control form-control-sm" placeholder="">
          </div>


          <div class="col-lg-12">
            <label  class="form-label">Referred By Email</label>
            <input type="text" formControlName="referredby_email" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-12">
            <label  class="form-label">Referred By Address</label>
            <input type="text" formControlName="referredby_address" class="form-control form-control-sm" placeholder="">
          </div>

        </div>


        <div class="col-lg-6">

          <p class="header-banner text-center">Referred To</p>


          <div class="col-lg-12">
            <label  class="form-label">Referral Date</label>
            <input type="date" formControlName="referral_date" class="form-control form-control-sm" placeholder="">
          </div>


          <div class="mb-3 col-lg-12">
            <label class="form-label">Is There Consumer Consent</label>
            <div>
              <label class="radio-inline">
                <input type="radio" formControlName="has_customer_consent" [value]="'YES'"> Yes
              </label>
              <label class="radio-inline">
                <input type="radio" formControlName="has_customer_consent" [value]="'NO'" #has_customer_consent> No, please specify why
              </label>
            </div>
          </div>

          <div class="mb-3 col-lg-12" *ngIf="has_customer_consent.checked">
            <label  class="form-label">Why Client Not Provided Consent</label>
            <textarea type="text" formControlName="why_client_not_provided_consent"  class="form-control form-control-sm" placeholder=""></textarea>
          </div>


          <div class="col-lg-12">
            <label  class="form-label">Referred To Name</label>
            <input type="text" formControlName="referredto_name" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-12">
            <label  class="form-label">Referred To Agency</label>
            <input type="text" formControlName="referredto_agency" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-12">
            <label  class="form-label">Referred To Position</label>
            <input type="text" formControlName="referredto_position" class="form-control form-control-sm" placeholder="">
          </div>


          <div class="col-lg-12">
            <label  class="form-label">Referred To Phone</label>
            <input type="text" formControlName="referredto_phone" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-12">
            <label  class="form-label">Referred To Email</label>
            <input type="text" formControlName="referredto_email" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-12">
            <label  class="form-label">Referred By Address</label>
            <input type="text" formControlName="referredto_address" class="form-control form-control-sm" placeholder="">
          </div>

        </div>
      </div>


      <p class="header-banner text-center">Personal Information</p>

      <div class="row">
        <div class="col-lg-4">
          <label class="form-label">First Name</label>
          <input type="type" formControlName="client_name" class="form-control form-control-sm" placeholder="" >
        </div>

        <div class="col-lg-4">
          <label class="form-label">Last Name</label>
          <input type="type" formControlName="father_or_husband_name" class="form-control form-control-sm" placeholder="" >
        </div>

        <div class="col-lg-4">
          <label class="form-label">Date of Birth</label>
          <input type="date" formControlName="dob" class="form-control form-control-sm" placeholder="">
        </div>

        <div class="col-lg-4">
          <label class="form-label">Age</label>
          <input type="number" formControlName="age" class="form-control form-control-sm" placeholder="">
        </div>


        <div class="col-lg-4">
          <label class="form-label">National ID</label>
          <input type="text" formControlName="nid" class="form-control form-control-sm" placeholder="">
        </div>

        <div class="col-lg-4">
          <label class="form-label">Gender</label>
          <div>
            <label class="radio-inline">
              <input type="radio" formControlName="gender" [value]="'MALE'"> Male
            </label>
            <label class="radio-inline">
              <input type="radio" formControlName="gender" [value]="'FEMALE'"> Female
            </label>
          </div>
        </div>

        <div class="col-lg-4">
          <label class="form-label">Address/Location</label>
          <input type="type" formControlName="address" class="form-control form-control-sm" placeholder="">
        </div>

        <div class="col-lg-4">
          <label class="form-label">Language</label>
          <input type="type" formControlName="language" class="form-control form-control-sm" placeholder="">
        </div>

        <div class="col-lg-4">
          <label class="form-label">Phone</label>
          <input type="type" formControlName="client_phone" class="form-control form-control-sm" placeholder="">
        </div>
      </div>



      <p class="header-banner text-center">Reasons for Referrals</p>


      <div class="row">

        <div class="col-lg-12">
          <label  class="form-label">Reasons for Referral</label>
          <textarea type="text" formControlName="reasons_for_referral" class="form-control form-control-sm" placeholder=""></textarea>
        </div>


        <div class="col-lg-6">
          <label  class="form-label">Mental Health Alert</label>
          <select class="form-control form-control-sm" formControlName="mental_health_alert" >
            <option value="Self-Harm">Self-Harm</option>
            <option value="Suicide ideation / Attempt">Suicide ideation / Attempt</option>
            <option value="Potential undiagnosed psychosis">Potential undiagnosed psychosis</option>
          </select>
        </div>


        <div class="col-lg-6">
          <label  class="form-label">Mental Health Description</label>
          <input type="text" formControlName="mental_health_description" class="form-control form-control-sm" placeholder="">
        </div>


        <div class="col-lg-6">
          <label class="form-label">Type of Service(s) Requested</label>
          <ng-select [items]="services"
              bindLabel="value"
              bindValue="value"
              formControlName="type_services_requested"
              [multiple]="true">
            </ng-select>

            <small *ngFor="let item of selectedServices">{{item.label}},</small>
        </div>
        <!-- <div class="col-lg-6">
          <label  class="form-label">Type of Service(s) Requested</label>
          <select class="form-control form-control-sm" formControlName="type_services_requested" multiple>
            <option value="Alternative care">Alternative care</option>
            <option value="Security (e.g. safe shelter)">Security (e.g. safe shelter)</option>
            <option value="Education (formal)">Education (formal)</option>

            <option value="Non-formal education">Non-formal education</option>
            <option value="S/GBV specialized care">S/GBV specialized care</option>
            <option value="Family tracing and reunification">Family tracing and reunification</option>

            <option value="Basic psychosocial support">Basic psychosocial support</option>
            <option value="Food">Food</option>
            <option value="Non-food items">Non-food items</option>

            <option value="Cash assistance">Cash assistance</option>
            <option value="Livelihoods">Livelihoods</option>
            <option value="General Health & Medical support">General Health & Medical support</option>


            <option value="Nutrition">Nutrition</option>
            <option value="Legal support">Legal support</option>
            <option value="Documentation">Documentation</option>

            <option value="Services for beneficiary with disabilities">Services for beneficiary with disabilities</option>
            <option value="Shelter">Shelter</option>
            <option value="WASH">WASH</option>

            <option value="Relocation">Relocation</option>
            <option value="Sexual & reproductive health">Sexual & reproductive health	</option>
            <option value="Protection issues">Protection issues</option>

            <option value="Child Protection issues">Child Protection issues	</option>
            <option value="Other">Other</option>
          </select>
        </div> -->

        <div class="col-lg-6">
          <label  class="form-label">Other Type of Services Requested</label>
          <input type="text" formControlName="type_services_requested_other" class="form-control form-control-sm" placeholder="">
        </div>



        <div class="col-lg-12">
          <hr>
          <label  class="form-label">Expected outcome of the service(s) requested: Describe what you and the person being referred is hoping to achieve through the referral.</label>
          <textarea type="text" formControlName="expected_outcome_of_services" class="form-control form-control-sm" placeholder=""></textarea>
        </div>


        <div class="col-lg-12">
          <label class="form-label">Does the service provider accept referral?</label>
          <div>
            <label class="radio-inline">
              <input type="radio" formControlName="service_provider_accept_referral" [value]="'Yes'"> Yes
            </label>
            <label class="radio-inline radio-spacing">
              <input type="radio" #service_provider_accept_referral formControlName="service_provider_accept_referral" [value]="'No'"> No
            </label>
          </div>
        </div>

        <div class="col-lg-6" *ngIf="service_provider_accept_referral.checked">
          <label  class="form-label">If referral was not accepted by the service provider, state reasons: </label>
          <textarea type="text" formControlName="why_referral_not_accepted" class="form-control form-control-sm" placeholder=""></textarea>
        </div>

      </div>



      <hr/>

      <div class="row">
        <div class="col-lg-3">
         <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
            Save
          </button>
         </div>
      </div>

    </form>


      </div>
  </div>
</section>


