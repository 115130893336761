<!-- <div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Meal Tools Services </h4>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
                  <li class="breadcrumb-item active">Meal Tools Services  </li>
              </ol>
          </div>
      </div>
  </div>
</div> -->
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">

        <!-- <button type="button" *ngIf="addValue" class="col-lg-1 mb-1 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add New</button>


        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

            <tr>
              <td  [appSort]="objs" data-order="desc" data-name="who_baseline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>WHO Baseline Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="who_endline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>WHO Endline Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="activate_baseline" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Activate Baseline</td>
              <td  [appSort]="objs" data-order="desc" data-name="activate_endline" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Activate Endline</td>
              <td  [appSort]="objs" data-order="desc" data-name="baseline_total_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Baseline Total Score</td>
              <td  [appSort]="objs" data-order="desc" data-name="endline_total_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Endline Total Score</td>
              <td  [appSort]="objs" data-order="desc" data-name="percentage_of_improvement" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>% of improvement</td>

              <td  [appSort]="objs" data-order="desc" data-name="evaluation_baseline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Baseline Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="evaluation_baseline_level" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Baseline Level</td>
              <td  [appSort]="objs" data-order="desc" data-name="evaluation_endline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Endline Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="evaluation_endline_level" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Endline Level</td>

              <td  [appSort]="objs" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Description</td>
<td scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <td>{{item.who_baseline_date | date}}</td>
              <td>{{item.who_endline_date | date}}</td>
              <td>{{item.activate_baseline}}</td>
              <td>{{item.activate_endline}}</td>
              <td>{{item.baseline_total_score}}</td>
              <td>{{item.endline_total_score}}</td>
              <td>{{item.percentage_of_improvement}}</td>
              <td>{{item.evaluation_baseline_date |date}}</td>
              <td>{{item.evaluation_baseline_level}}</td>
              <td>{{item.evaluation_endline_date | date}}</td>
              <td>{{item.evaluation_endline_level}}</td>
              <td>{{item.remarks}}</td>


              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->

        <!-- <hr/> -->

        <p class="header-banner text-center">WHO-5 Wellbeing Initial Assessment (WHO-5 Wellbeing Index (Ticket Score))</p>


        <form [formGroup]="formGroup" (ngSubmit)="submit()">

          <div class="row">
            <div class="col-lg-6">
              <label  class="form-label">WHO-5 Baseline Date</label>
              <input type="date" formControlName="who_baseline_date" class="form-control form-control-sm" placeholder="">
            </div>

            <div class="col-lg-6">
              <label  class="form-label">WHO-5 Endline Date</label>
              <input type="date" formControlName="who_endline_date" class="form-control form-control-sm" placeholder="">
            </div>
          </div>

          <hr>

          <div class="row">

              <div class="col-lg-6">
                <div class="form-check">
                  <label class="custom-control custom-checkbox">
                    <label class="form-check-label">
                      Activate Baseline
                    </label>
                    <input type="checkbox" #activate_baseline class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="col-lg-6">

                <div class="form-check">
                  <label class="custom-control custom-checkbox">
                    <label class="form-check-label">
                      Activate Endline
                    </label>
                    <input type="checkbox" #activate_endline class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                  </label>
                </div>

              </div>



          <!-- <div class="col-lg-6">
            <label  class="form-label">Activate Baseline </label>
            <input type="checkbox" formControlName="activate_baseline" class="form-control-sm form-control" #activite_baseline class="" placeholder="">
          </div>

          <div class="col-lg-6">
            <label  class="form-label">Activate Endline </label>
            <input type="checkbox" formControlName="activate_endline" class="form-control form-control-sm" placeholder="">
          </div> -->

          </div>

          <div class="row">

          <div class="col-lg-6">
            <label  class="form-label">Baseline Total Score</label>
            <input type="number" formControlName="baseline_total_score" [readonly]="!activate_baseline.checked" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="col-lg-6">
            <label  class="form-label">Endline Total Score</label>
            <input type="number" formControlName="endline_total_score" class="form-control form-control-sm" [readonly]="!activate_endline.checked" placeholder="">
          </div>

          </div>

          <div class="row">
          <div class="col-lg-3"></div>
          <div class="col-lg-6">
            <label  class="form-label">Percentage of Improvement</label>
            <input type="number" formControlName="percentage_of_improvement" class="form-control form-control-sm" placeholder="">
          </div>
          <div class="col-lg-3"></div>
        </div>


        <hr>

        <p class="header-banner text-center">Evaluation Based on General Judgement (Level of client's distress)</p>

        <div class="row">
          <div class="col-lg-6">

            <div class="col-lg-12">
              <label  class="form-label">Baseline Evaluation Date</label>
              <input type="date" formControlName="evaluation_baseline_date" class="form-control form-control-sm" placeholder="">
            </div>

            <div class="col-lg-12">
              <label  class="form-label">Baseline Level Evaluation</label>
              <select class="form-control form-control-sm" formControlName="evaluation_baseline_level" >
                <option value="Low">Low</option>
                <option value="Moderate">Moderate </option>
                <option value="High">High</option>
                <option value="Evaulation Not Possible">Evaluation Not Possible</option>
                <option value="N/A">N/A </option>
              </select>
            </div>


          </div>
          <div class="col-lg-6">

            <div class="col-lg-12">
              <label  class="form-label">Endline Evaluation Date </label>
              <input type="date" formControlName="evaluation_endline_date" class="form-control form-control-sm" placeholder="">
            </div>

            <div class="col-lg-12">
              <label  class="form-label">Endline Level Evaluation</label>
              <select class="form-control form-control-sm" formControlName="evaluation_endline_level" >
                <option value="Low">Low</option>
                <option value="Moderate">Moderate </option>
                <option value="High">High</option>
                <option value="Evaulation Not Possible">Evaluation Not Possible</option>
                <option value="N/A">N/A </option>
              </select>
            </div>

          </div>








          <!-- <div class="col-lg-6">
            <label  class="form-label">Remarks</label>
            <input type="text" formControlName="remarks" class="form-control form-control-sm" placeholder="">
          </div> -->


        </div>

        <hr>
          <div class="row">
            <div class="col-lg-3">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Save
              </button>
          </div>

          </div>

        </form>


      </div>
  </div>
</section>
