<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">View Beneficiary</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
                <li class="breadcrumb-item active">View Grant </li>
            </ol>
        </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <table class="table table-sm  table-bordered table-responsive" >

          <tr>
            <td class="labelinfo">Grant / Province / District:</td>
            <td>{{beneficiary?.grant.code}} / {{beneficiary?.province.name}} / {{beneficiary?.district.name}}</td>
          </tr>
          <tr>
            <td class="labelinfo">Site Code / Village / Health Facility :</td>
            <td>{{beneficiary?.site_code}} / {{beneficiary?.village}} / {{beneficiary?.health_facility}}</td>
          </tr>

          <tr>
            <td class="labelinfo">Intervention Modality :</td>
            <td>{{beneficiary?.intervention_modality}}</td>
          </tr>


          <tr>
            <td class="labelinfo">Registration Date:</td>
            <td>{{beneficiary?.registration_date | date}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Code:</td>
            <td>{{beneficiary?.beneficiary_code}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Name:</td>
            <td>{{beneficiary?.client_name}} {{beneficiary?.father_or_husband_name}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Age / Gender / Marital Status:</td>
            <td>{{beneficiary?.age}} / {{beneficiary?.gender}} / {{beneficiary?.marital_status}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Phone:</td>
            <td>{{beneficiary?.client_phone}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Household Status:</td>
            <td>{{beneficiary?.household_status_of_bnf}}</td>
            </tr>


          <tr>
            <td class="labelinfo">Literacy:</td>
            <td>{{beneficiary?.literacy_level_of_bnf}}</td>
          </tr>
          <tr>
            <td class="labelinfo">Disability Type:</td>
            <td>{{beneficiary?.disability_type}}</td>
          </tr>
          <tr>
            <td class="labelinfo">GBV Survivor:</td>
            <td>{{beneficiary?.gbv_survivor}}</td>
          </tr>
          <tr>
            <td class="labelinfo">Referred for Protection:</td>
            <td>{{beneficiary?.referred_for_protection}}</td>
          </tr>

        </table>

        <hr/>


        <h3>1 . Activities</h3>

        <table class="table table-sm  table-responsive" >
          <thead>
            <tr>
              <th  [appSort]="activities" data-order="desc" data-name="activity" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Grant Activity</th>
              <th  [appSort]="activities" data-order="desc" data-name="activity_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Activity Type</th>
              <th  [appSort]="activities" data-order="desc" data-name="activity_category" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Activity Category</th>
              <th  [appSort]="activities" data-order="desc" data-name="session_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Session ID</th>
              <th  [appSort]="activities" data-order="desc" data-name="session_topic" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Session Topic</th>
              <th  [appSort]="activities" data-order="desc" data-name="description" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Description</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of activities">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.activity.title}}</td>
              <td>{{item.activity_type}}</td>
              <td>{{item.activity_category}}</td>
              <td>{{item.session_id}}</td>
              <td>{{item.session_date}}</td>
              <td>{{item.session_topic}}</td>
              <td>{{item.description}}</td>
            </tr>
          </tbody>
        </table>

        <hr>

        <h3>2. Referrals</h3>

        <table class="table table-sm  table-responsive table-bordered">
          <thead>
            <tr>
              <th  [appSort]="referrals" data-order="desc" data-name="case_no" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Case No</th>
              <th  [appSort]="referrals" data-order="desc" data-name="referral_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Referral Date</th>
              <th  [appSort]="referrals" data-order="desc" data-name="referral_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Referral Type</th>
              <th  [appSort]="referrals" data-order="desc" data-name="referredby_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Referred By</th>
              <th  [appSort]="referrals" data-order="desc" data-name="referredto_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Referred To </th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of referrals">
              <td>{{item.case_no}}</td>
              <td>{{item.referral_date | date}}</td>
              <td>{{item.referral_type }}</td>
              <td>{{item.referredby_name}} / {{item.referredby_agency}}</td>
              <td>{{item.referredto_name}} / {{item.referredto_agency}}</td>
            </tr>
          </tbody>
        </table>

        <hr>
        <h3>3. Meal Tools</h3>

        <table class="table table-sm  table-responsive table-bordered" >
          <thead>
            <tr>
              <th  [appSort]="mealtools" data-order="desc" data-name="who_baseline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>WHO Baseline Date</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="who_endline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>WHO Endline Date</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="activate_baseline" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Activate Baseline</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="activate_endline" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Activate Endline</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="baseline_total_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Baseline Total Score</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="endline_total_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Endline Total Score</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="percentage_of_improvement" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>% of improvement</th>

              <th  [appSort]="mealtools" data-order="desc" data-name="evaluation_baseline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Baseline Date</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="evaluation_baseline_level" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Baseline Level</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="evaluation_endline_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Endline Date</th>
              <th  [appSort]="mealtools" data-order="desc" data-name="evaluation_endline_level" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Endline Level</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of mealtools">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.who_baseline_date | date}}</td>
              <td>{{item.who_endline_date | date}}</td>
              <td>{{item.activate_baseline}}</td>
              <td>{{item.activate_endline}}</td>
              <td>{{item.baseline_total_score}}</td>
              <td>{{item.endline_total_score}}</td>
              <td>{{item.percentage_of_improvement}}</td>
              <td>{{item.evaluation_baseline_date |date}}</td>
              <td>{{item.evaluation_baseline_level}}</td>
              <td>{{item.evaluation_endline_date | date}}</td>
              <td>{{item.evaluation_endline_level}}</td>
              <td>{{item.remarks}}</td>



            </tr>
          </tbody>
        </table>


        <hr>
        <h3>4. Evaluations and Terminations</h3>

        <table class="table table-sm  table-responsive table-bordered">
          <thead>
            <tr>
              <th  [appSort]="evaluations" data-order="desc" data-name="client_evaluation_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Date</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="client_evaluation_results" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Results</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="client_evaluation_other" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Other</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="client_satisfaction_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Satisfaction Assessment Date</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="statisfation_level" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Satisfaction Level</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="client_satisfaction_other" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Satisfaction Other</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="client_satisfaction_other" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Discharge Date</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="client_discharge_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Discharge Reasons</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="reasons_for_discharge" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Discharge Other</th>
              <th  [appSort]="evaluations" data-order="desc" data-name="discharge_other_reasons" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of evaluations">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.client_evaluation_date | date}}</td>
              <td>{{item.client_evaluation_results}}</td>
              <td>{{item.client_evaluation_other}}</td>
              <td>{{item.client_satisfaction_date | date}}</td>
              <td>{{item.statisfation_level}}</td>
              <td>{{item.client_satisfaction_other}}</td>
              <td>{{item.client_discharge_date | date}}</td>
              <td>{{item.reasons_for_discharge}}</td>
              <td>{{item.discharge_other_reasons}}</td>

              <td>{{item.remarks}}</td>
            </tr>
          </tbody>
        </table>



        <hr>
        <h3>5. Kit Distributions</h3>

        <table class="table table-sm  table-responsive table-bordered">
          <thead>
            <tr>
              <th  [appSort]="kits" data-order="desc" data-name="distribution_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Distribution Date</th>
              <th  [appSort]="kits" data-order="desc" data-name="kit_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Kit Type</th>
              <th  [appSort]="kits" data-order="desc" data-name="no_kits" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>No of Kits</th>
              <th  [appSort]="kits" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of kits">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.distribution_date | date}}</td>
              <td>{{item.kit_type}}</td>
              <td>{{item.no_kits}}</td>
              <td>{{item.remarks}}</td>
            </tr>
          </tbody>
        </table>


        <hr>
        <h3>6. Community Dialogues</h3>

        <table class="table table-sm  table-responsive table-bordered">
          <thead>
            <tr>
              <th  [appSort]="dialogues" data-order="desc" data-name="role_in_community" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Job Title (Role in community)</th>
              <th  [appSort]="dialogues" data-order="desc" data-name="cd_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Dialogue Date</th>
              <th  [appSort]="dialogues" data-order="desc" data-name="cd_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Session Type</th>
              <th  [appSort]="dialogues" data-order="desc" data-name="session_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Dialogue Type</th>
              <th  [appSort]="dialogues" data-order="desc" data-name="received_incentive" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Received Incentive?</th>
              <th  [appSort]="dialogues" data-order="desc" data-name="incentive_amount" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Incentive Amount</th>
              <th  [appSort]="dialogues" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of dialogues">
              <td>{{item.role_in_community}}</td>
              <td>{{item.cd_date | date}}</td>
              <td>{{item.cd_type}}</td>
              <td>{{item.session_type}}</td>
              <td>{{item.received_incentive}}</td>
              <td>{{item.incentive_amount}}</td>
              <td>{{item.remarks}}</td>
            </tr>
          </tbody>
        </table>


        <!-- <hr>
        <h3>7. Trainings</h3>

        <table class="table table-sm  table-responsive table-bordered">
          <thead>
            <tr>
              <th  [appSort]="trainings" data-order="desc" data-name="role_in_community" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Role</th>
              <th  [appSort]="trainings" data-order="desc" data-name="participant_organization" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Organization</th>
              <th  [appSort]="trainings" data-order="desc" data-name="training_topic" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Topic</th>
              <th  [appSort]="trainings" data-order="desc" data-name="training_modality" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Modality</th>
              <th  [appSort]="trainings" data-order="desc" data-name="training_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Type</th>
              <th  [appSort]="trainings" data-order="desc" data-name="start_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Start Date</th>
              <th  [appSort]="trainings" data-order="desc" data-name="end_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>End Date</th>
              <th  [appSort]="trainings" data-order="desc" data-name="facilitator_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Facilitator Name</th>
              <th  [appSort]="trainings" data-order="desc" data-name="facilitator_position" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Facilitator Position</th>
              <th  [appSort]="trainings" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of trainings">
              <td>{{item.role_in_community}}</td>
              <td>{{item.participant_organization}}</td>
              <td>{{item.training_topic}}</td>
              <td>{{item.training_modality}}</td>
              <td>{{item.training_type}}</td>
              <td>{{item.start_date | date}}</td>
              <td>{{item.end_date |date}}</td>
              <td>{{item.facilitator_name}}</td>
              <td>{{item.facilitator_position}}</td>
              <td>{{item.remarks}}</td>
            </tr>
          </tbody>
        </table> -->


        <hr>
        <h3>8. Training Pre & Post Evaluation</h3>

        <table class="table table-sm  table-responsive table-bordered">
          <thead>
            <tr>
              <th  [appSort]="scores" data-order="desc" data-name="training" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Training</th>
              <th  [appSort]="scores" data-order="desc" data-name="pre_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Pre Score</th>
              <th  [appSort]="scores" data-order="desc" data-name="post_score" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Post Score</th>
              <th  [appSort]="scores" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of scores">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.training.training_topic}}</td>
              <td>{{item.pre_score}}</td>
              <td>{{item.post_score}}</td>
              <td>{{item.remarks}}</td>
            </tr>
          </tbody>
        </table>

      </div>
  </div>
</section>
