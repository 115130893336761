<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Users</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Users</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">
        <div *ngIf="!addObj">
          <div class="row">
            <div class="col-lg-8">
              <button type="button" class="col-lg-2 btn btn-primary btn-sm" (click)="addObjectClicked()">Register New</button>
            </div>
            <div class="input-group col-lg-4">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
              </div>
              <input class="input" (input)="filter($event.target.value)"  class="form-control" placeholder="">
            </div>
          </div>
        <table class="table table-sm  table-responsive-md table-responsive-sm table-bordered">
            <tr class="bg-primary">
              <td [appSort]="response?.data" data-order="desc" data-name="id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</td>
              <td [appSort]="response?.data" data-order="desc" data-name="name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Name</td>
              <td [appSort]="response?.data" data-order="desc" data-name="email" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Email</td>
              <td [appSort]="response?.data" data-order="desc" data-name="status" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Status</td>
              <td [appSort]="response?.data" data-order="desc" data-name="roles" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Roles</td>
              <td scope="col">Action</td>

            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of response?.data; let i = index">
              <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
              <td>{{item.name}}</td>
              <td>{{item.email}}</td>
              <td>{{item.active == true ? 'Active' : 'Inactive'}}</td>
              <td>{{getRoles(item.roles)}}</td>
              <td>


                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" *ngIf="!item.active" class="btn btn-success" (click)="activate(item)">Activate</button>
                  <button type="button" *ngIf="item.active" class="btn btn-warning" (click)="deactivate(item)">De-Activate</button>
                  <!-- <button type="button" class="btn btn-warning">View</button> -->
                  <button type="button" class="btn btn-primary" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

           <div class="d-flex justify-content-center">
          <span *ngFor="let item of response?.links" >
            <button  (click)="clickLink(item.url ? item.url : response.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
            [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
          </span>
        </div>

        </div>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">
          <div class="row">
          <div class="mb-3 col-lg-4">
            <label  class="form-label">Name</label>
            <input type="type" formControlName="name" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('name')"></print-error>
          </div>


          <div class="mb-3 col-lg-4">
            <label  class="form-label">Email address</label>
            <input type="email" formControlName="email" class="form-control" placeholder="" email="true">
            <print-error [control]="formGroup.get('email')"></print-error>

          </div>

          <div class="mb-3 col-lg-4">
            <label  class="form-label">Password</label>
            <input type="password" formControlName="password" class="form-control" placeholder="" minlength="8">
            <print-error [control]="formGroup.get('password')"></print-error>

          </div>

          <div class="mb-3 col-lg-4">
            <label  class="form-label">Confirm Password</label>
            <input type="password" formControlName="c_password" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('c_password')"></print-error>

          </div>

          <div class="mb-3 col-lg-4">
            <label  class="form-label">Roles</label>

            <ng-select [items]="roles"
            bindLabel="name"
            bindValue="id"
            formControlName="roles"
            [multiple]="true">
          </ng-select>
            <print-error [control]="formGroup.get('roles')"></print-error>
          </div>


            <div class="col-lg-12">
             <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
                Submit
              </button>
          </div>
        </div>

        </form>


      </div>
  </div>
</section>
