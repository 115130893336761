<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">User Logs</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">User Logs</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">

        <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button> -->
        <div class="row">
          <div class="col-lg-8"></div>
          <div class="input-group col-lg-4">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
            </div>
            <input class="input" (input)="filter($event.target.value)"  class="form-control" placeholder="">
          </div>
        </div>


        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" >

            <tr class="bg-primary">
              <td scope="col">#</td>
              <td [appSort]="response?.data" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>User ID</td>
              <td [appSort]="response?.data" data-order="desc" data-name="event" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Event</td>
              <td [appSort]="response?.data" data-order="desc" data-name="auditable_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Model</td>
              <td [appSort]="response?.data" data-order="desc" data-name="auditable_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Model ID</td>
              <td [appSort]="response?.data" data-order="desc" data-name="old_values" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Old Values</td>
              <td [appSort]="response?.data" data-order="desc" data-name="new_values" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>New Values</td>
              <td [appSort]="response?.data" data-order="desc" data-name="created_at" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Date</td>

            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of response?.data; let i = index" >
              <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
              <td>{{item.user_id}}</td>
              <td>{{item.event}}</td>
              <td>{{item.auditable_type}}</td>
              <td>{{item.auditable_id}}</td>
              <td>{{item.old_values | json}}</td>
              <td>{{item.new_values | json}}</td>
              <td>{{item.created_at | date}}</td>

              <!-- <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-warning" (click)="details(item)">Details</button>
                  <button type="button" class="btn btn-primary" (click)="configure(item)">Configure</button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
           <div class="d-flex justify-content-center mt-1">
          <span *ngFor="let item of response?.links" >
            <button  (click)="clickLink(item.url ? item.url : response.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
            [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
          </span>
        </div>
      </div>
  </div>
</section>
