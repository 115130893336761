import { Component, Input } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-pre-post-evaluation',
  templateUrl: './training-pre-post-evaluation.component.html',
  styleUrls: ['./training-pre-post-evaluation.component.scss']
})
export class TrainingPrePostEvaluationComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  beneficiaryId: any;
  addValue: any = true;
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  beneficiary: any;
  activities: any;
  trainings: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder
      ){

        this.activedRoute.params.subscribe((params) => {
          this.beneficiaryId = params['id'];
          this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
            this.beneficiary = response;
            // this.apiService.getGrantActivities(this.beneficiary.grant_id).subscribe(response => {
            //   this.activities = response;
            // });
          });
        });
    }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',],
      grant_id: ['',[]],
      beneficiary_id: ['',[]],
      user: ['',[]],
      training_id: ['',[Validators.required]],
      pre_score: ['',[Validators.required]],
      post_score: ['',[Validators.required]],
      remarks: ['',[]],
    });

      this.loadData();
  }

  loadData(){
    this.apiService.getTrainingScore(this.beneficiaryId).subscribe(response => {
      this.objs = response;
    });

    this.apiService.getAllTraining().subscribe(response => {
      this.trainings = response;
    })
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteTrainingScore(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['training_id'].setValue(item.training.id);
      this.formGroup.controls['pre_score'].setValue(item.pre_score);
      this.formGroup.controls['post_score'].setValue(item.post_score);
      this.formGroup.controls['remarks'].setValue(item.remarks);

      this.editObj = item;
    }
  }




  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);
    this.formGroup.controls['grant_id'].setValue(this.beneficiary.grant_id);
    this.formGroup.controls['beneficiary_id'].setValue(this.beneficiary.id);

    this.apiService.createTrainingScore(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}

