import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-beneficiary-services',
  templateUrl: './beneficiary-services.component.html',
  styleUrls: ['./beneficiary-services.component.scss']
})
export class BeneficiaryServicesComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  id: any;
  beneficiary: any;
  constructor(private router: Router,
    public apiService: ApiService,
    private activedRoute: ActivatedRoute,
    ){

      this.activedRoute.params.subscribe((params) => {
        this.id = params['id'];
        this.apiService.getBeneficiary(this.id).subscribe((response) => {
          this.beneficiary = response;
        });
      });
  }
  route(path: string){
    this.router.navigateByUrl(path);
  }
}
