<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Psychoeducation and Awareness</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
          <li class="breadcrumb-item active">Psychoeducation and Awareness</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">
      <div class="row">
        <div class="col-lg-8">
          <button type="button" class="mb-1 btn btn-primary btn-sm" (click)="addObjectClicked()" *ngIf="!addObj">Add
            New</button>
        </div>
        <div class="input-group col-lg-4" *ngIf="!addObj">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
          </div>
          <input class="input" (input)="filter($event.target.value)" class="form-control" placeholder="">
        </div>
      </div>


      <div *ngIf="!addObj">
        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered">


          <tr>
            <!-- <th [appSort]="response?.data" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</th> -->
            <td [appSort]="response?.data" data-order="desc" data-name="grant_id" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Grant
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="province_id" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Province/District
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="intervention_modality" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Intervention Modality
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="awareness_date" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Awareness Date
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="awareness_topic" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Awareness Topic
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="focal_point" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Focal Point
            </td>

            <td scope="col">Action</td>

          </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of response?.data">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.grant.title}}</td>
              <td>{{item.province.name}} / {{item.district.name}}</td>
              <td>{{item.intervention_modality}}</td>
              <td>{{item.awareness_date| date}}</td>
              <td>{{item.awareness_topic}}</td>
              <td>{{item.focal_point}}</td>

              <td>

                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i
                      class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                  <button type="button" class="btn btn-primary" (click)="details(item)"><i
                      class="fa fa-eye"></i></button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-center">
          <span *ngFor="let item of response?.links">
            <button (click)="clickLink(item.url ? item.url : response.prev_page_url)"
              [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)"
              class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''"
              style="color:rgb(0, 0, 0); border: solid #ccc 1px;"></button>
          </span>
        </div>

      </div>


      <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

        <div class="row">

          <div class="row">
            <div class="mb-3 col-lg-3">
              <label class="form-label">Project Code</label>
              <ng-select [items]="grants" bindLabel="title" bindValue="id" formControlName="grant_id"
                (change)="loadGrantProvinces($event)">
              </ng-select>
            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Province</label>
              <ng-select [items]="provinces" bindLabel="name" bindValue="id" formControlName="province_id"
                (change)="loadAdminOrgsChildern($event)">
              </ng-select>
            </div>


            <div class="mb-3 col-lg-3">
              <label class="form-label">District</label>
              <ng-select [items]="districts" bindLabel="name" bindValue="id" formControlName="district_id">
              </ng-select>
            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Focal Point</label>
              <input type="type" formControlName="focal_point" class="form-control" placeholder="">
            </div>


            <div class="mb-3 col-lg-3">
              <label class="form-label">Village</label>
              <input type="type" formControlName="village" class="form-control" placeholder="">
            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Site Code</label>
              <input type="type" formControlName="site_code" class="form-control" placeholder="">
            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Health Facility Name (if applicable) </label>
              <input type="type" formControlName="health_facility" class="form-control" placeholder="">
            </div>

            <div class="mb-3 col-lg-3">
              <label class="form-label">Intervention Modality</label>
              <select class="form-control" formControlName="intervention_modality">
                <option value="REMOTE">REMOTE</option>
                <option value="ON-SITE">ON-SITE</option>
              </select>
            </div>
            <div class="mb-3 col-lg-6">
              <label class="form-label">Date of Awareness</label>
              <input type="date" formControlName="awareness_date" class="form-control" placeholder="">
            </div>

            <div class="mb-3 col-lg-6">
              <label class="form-label">Topic of Awareness</label>
              <input type="type" formControlName="awareness_topic" class="form-control" placeholder="">
            </div>
          </div>
          <!-- <div class="mb-3 col-lg-6">
            <label  class="form-label">Host Community</label>
            <input type="type" formControlName="host_community" class="form-control" placeholder="">
          </div> -->

          <div class="row">

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of IDP - Male</label>
            <input type="type" formControlName="male_idp" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Refugee - Male</label>
            <input type="type" formControlName="male_refugee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Returnee - Male</label>
            <input type="type" formControlName="male_returnee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Disable - Male</label>
            <input type="type" formControlName="male_disable" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of IDP - Female</label>
            <input type="type" formControlName="female_idp" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Refugee - Female</label>
            <input type="type" formControlName="female_refugee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Returnee - Female</label>
            <input type="type" formControlName="female_returnee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Disable - Female</label>
            <input type="type" formControlName="female_disable" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of IDP - Boy</label>
            <input type="type" formControlName="boy_idp" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Refugee - Boy</label>
            <input type="type" formControlName="boy_refugee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Returnee - Boy</label>
            <input type="type" formControlName="boy_returnee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Disable - Boy</label>
            <input type="type" formControlName="boy_disable" class="form-control" placeholder="">
          </div>


          <div class="mb-3 col-lg-2">
            <label class="form-label"># of IDP - Girl</label>
            <input type="type" formControlName="girl_idp" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Refugee - Girl</label>
            <input type="type" formControlName="girl_refugee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Returnee - Girl</label>
            <input type="type" formControlName="girl_returnee" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-2">
            <label class="form-label"># of Disable - Girl</label>
            <input type="type" formControlName="girl_disable" class="form-control" placeholder="">
          </div>

          </div>


        </div>

        <div class="row">
          <div class="col-lg-6">
            <label class="form-label">Remarks</label>
            <textarea type="text" formControlName="remarks" class="form-control" placeholder=""></textarea>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-2">
            <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
              Submit
            </button>
          </div>


        </div>

      </form>
    </div>
  </div>
</section>
