import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-beneficiary-details',
  templateUrl: './beneficiary-details.component.html',
  styleUrls: ['./beneficiary-details.component.scss']
})
export class BeneficiaryDetailsComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  // objs: any;
  id: any;
  beneficiaryId: any;
  beneficiary: any;
  activities: any;
  referrals: any;
  mealtools: any;
  evaluations: any;
  kits: any;
  dialogues: any;
  trainings: any;
  scores: any;


  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private activedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
    ){

      this.activedRoute.params.subscribe((params) => {
        this.beneficiaryId = params['id'];
        this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
          console.log(response);
          this.beneficiary = response;
        });
      });
  }

  ngOnInit() {
      this.loadData();
  }

  loadData(){

    this.apiService.getBeneficiaryActivities(this.beneficiaryId).subscribe(response => {
      this.activities = response;
    });

    this.apiService.getBeneficiaryReferral(this.beneficiaryId).subscribe(response => {
      this.referrals = response;
    });

    this.apiService.getBeneficiaryMealTools(this.beneficiaryId).subscribe(response => {
      this.mealtools = response;
    });


    this.apiService.getBeneficiaryEvaluation(this.beneficiaryId).subscribe(response => {
      this.evaluations = response;
    });


    this.apiService.getDistributedKits(this.beneficiaryId).subscribe(response => {
      this.kits = response;
    });

    this.apiService.getCommunityDialogue(this.beneficiaryId).subscribe(response => {
      this.dialogues = response;
    });

    // this.apiService.getTraining(this.beneficiaryId).subscribe(response => {
    //   this.trainings = response;
    // });

    this.apiService.getTrainingScore(this.beneficiaryId).subscribe(response => {
      this.scores = response;
    });

    // this.apiService.getGrantOutcomes(this.id).subscribe(response => {
    //   this.outcomes = response;
    // });

    // this.apiService.getGrantOutputs(this.id).subscribe(response => {
    //   this.outputs = response;
    // });

    // this.apiService.getGrantActivities(this.id).subscribe(response => {
    //   this.activities = response;
    // });
  }

}


