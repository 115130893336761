import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import { ApiService } from '@services/api.service';
import {AppService} from '@services/app.service';
import {Observable, catchError} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit{
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;

    imageUrl: any = null;
    defaultUrl: any = 'assets/img/default-profile.png';

    isPinned: boolean = false;

  togglePin() {
    this.isPinned = !this.isPinned;
    // Additional logic to handle the menu's pinned state
  }

    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        public apiService: ApiService,
        private cdRef: ChangeDetectorRef,
        private translate: TranslocoService,
        private sanitizer: DomSanitizer

    ) {}
  ngAfterViewInit(): void {}

    createImageURL(blob: Blob) {
      const url = URL.createObjectURL(blob);
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    ngOnInit() {

      this.appService.loadUserProfileImage().pipe(
        catchError(error => {
          // handle the error
          console.error(error);
          return [];
        })
      ).subscribe(blob => {
        // create the safe URL and assign it to the variable


        blob.arrayBuffer().then(buffer => {
          if (buffer.byteLength === 0) {
            // the blob is empty
            console.log("empty buffer");
            this.imageUrl = this.defaultUrl;

          } else {
            // the blob is not empty
            this.imageUrl = this.createImageURL(blob);
          }
        })
      });

        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;


        if(!this.user){
            this.appService.returnProfile().subscribe((response: any) =>{
                this.user = response.success;
                // // console.log("user", this.user);
            })
        }
    }
}

export const MENU = [

    {
        name: 'Home',
        iconClasses: 'fas fa-home',
        path: ['/'],
        permissions: ['']
    },

    {
      name: 'Dashboard',
      iconClasses: 'fas fa-tachometer-alt',
      path: ['/portfolio'],
      permissions: ['dashboard'],
      children: [
        {
          name: 'Portfolio Summary',
          iconClasses: 'fas fa-circle',
          permissions: ['portfolio_summary'],
          path: ['/portfolio-summary']
        },


        {
          name: 'Project Status',
          iconClasses: 'fas fa-circle',
          permissions: ['project_status'],
          path: ['/project-status'],
        },

      ]},

    {
        name: 'Grant Management',
        iconClasses: 'fas fa-address-book',
        permissions: ['grant'],
        path: ['/grants'],
        children: [
          {
              name: 'Grant Overview',
              iconClasses: 'fas fa-circle',
              permissions: ['grant_overview'],
              path: ['/grant/overview']
          },
          {
              name: 'Create Project',
              iconClasses: 'fas fa-circle',
              permissions: ['create_project'],
              path: ['/grant/create']
          },
          // {
          //   name: 'Project Status',
          //   iconClasses: 'fas fa-circle',
          //   permissions: ['project_status'],
          //   path: ['/grant-status']
          // },
        ]
    },

    {
        name: 'MySpace',
        iconClasses: 'fas fa-folder',
        permissions: ['myspace'],
        path: ['/myspace'],
//         children: [
//             {
//                 name: 'Beneficiary DB',
//                           iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//                 path: ['/beneficiaries']
//             },
//             {
//                 name: 'Referral',
//                           iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//                 path: ['beneficiaries/search/referral']
//             },
//             {
//               name: 'Activities',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['beneficiaries/search/activity']
//             }

//             ,
//             {
//               name: 'Meal Tools',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['beneficiaries/search/meal']
//             }

//             ,
//             {
//               name: 'Evaluation and Termination',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['beneficiaries/search/evaluationtermination']
//             }
// ,
//             {
//               name: 'Kits Distribution',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['beneficiaries/search/kit-distribution']
//           },
//               {
//                 name: 'Community Dialogue',
//                           iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//                 path: ['beneficiaries/search/community-dialogue']
//             },



//             {
//               name: 'Psychoeduction and Awareness',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['/psychoeducations']
//             },

//             {
//               name: 'Training',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['/beneficiaries/search/training']
//             },
//             {
//               name: 'Training Pre and Post Score',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['beneficiaries/search/training-tests']
//             },

//             {
//               name: 'Training Evaluation',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['/trianing-evaluations']
//             },
//             {
//               name: 'EQUIP',
//                         iconClasses: 'fas fa-circle',
          // permissions: ['dashboard'],
//               path: ['/beneficiaries/search/equip']
//             },

//             //  {
//             //   name: 'Assessment',
//             //   path: ['/assessments']
//             // },

//             // {
//             //   name: 'My Request',
//             //   path: ['/myrequests']
//             // },


//         ]
    },
    {
      name: 'DTB Management',
      iconClasses: 'fas fa-home',
      path: ['/management'],
      permissions: ['dtb_management'],
      children: [
        {
            name: 'DTB Overview',
            iconClasses: 'fas fa-circle',
            permissions: ['dtb_overview'],
            path: ['/dtbs/overview']
        },
        {
          name: 'DTB Submission',
          iconClasses: 'fas fa-circle',
          permissions: ['dtb_submission'],
          path: ['/dtbs/submission']
        },
        {
          name: 'List of DTB Submisstions',
          iconClasses: 'fas fa-circle',
          permissions: ['dtbs_submissions_list'],
          path: ['/dtbs/submission-list']
        },
        {
          name: 'List of DTB Finalized',
          iconClasses: 'fas fa-circle',
          permissions: ['dtbs_submissions_list'],
          path: ['/dtbs/approved-list']
        },

        {
          name: 'Finalized DTBs',
          iconClasses: 'fas fa-circle',
          permissions: ['dtbs_finalized'],
          path: ['dtbs/submission-finalized']
      },

      {
        name: 'Rejected DTBs',
        iconClasses: 'fas fa-circle',
        permissions: ['dtbs_rejected'],
        path: ['dtbs/submission-rejected']
      },
      ]},
      {
        name: 'Kobo Form Data',
        iconClasses: 'fas fa-book',
        path: ['/kobo'],
        permissions: ['dtb_management'],
        children: [
            {
                name: 'Beneficiary Form',
                iconClasses: 'fas fa-circle',
                permissions: ['dtb_management'],
                path: ['/kobo']
            }
        ]
      },
      {
        name: 'APR Reporting',
        iconClasses: 'fas fa-book',
        path: ['/apr/overview'],
        permissions: ['dtb_management'],
        children: [
            {
                name: 'APR Overview',
                iconClasses: 'fas fa-circle',
                permissions: ['dtb_management'],
                path: ['/apr/overview']
            }
        ]
      },
    //  {
    //   name: 'Documentation',
    //   iconClasses: 'fas fa-file',
    //   path: ['/documentation'],
    //   children: [
    //     {
    //         name: 'Quality Check',
    //         path: ['/qualitycheck']
    //     },
    //     {
    //       name: 'Validation',
    //       path: ['/validation']
    //     },

    //     {
    //       name: 'Archive',
    //       path: ['/archive']
    //   },

    //   ]},

      {
        name: 'Access Management',
        iconClasses: 'fas fa-user',
        permissions: ['access_management'],
        children: [
          {
            name: 'Users',
            iconClasses: 'fas fa-circle',
            permissions: ['user_management'],
            path: ['/users']
          },
          {
            name: 'Roles',
            iconClasses: 'fas fa-circle',
            permissions: ['role_management'],
            path: ['/roles']
          },

          {
            name: 'Permissions',
            iconClasses: 'fas fa-circle',
            permissions: ['permission_management'],
            path: ['/permissions']
        },

        {
          name: 'Device Manager',
                    iconClasses: 'fas fa-circle',
          permissions: ['device_management'],
          path: ['/device-manager']
        },

        {
          name: 'Blocked IPs',
          iconClasses: 'fas fa-circle',
          permissions: ['blockedip_management'],
          path: ['/blockedips']
        },

        ]},

        {
          name: 'Category Management',
          iconClasses: 'fas fa-location-arrow',
          path: ['/settings'],
          permissions: ['category_management'],
          children: [
            {
              name: 'Provinces',
              iconClasses: 'fas fa-circle',
              permissions: ['provinces'],
              path: ['/administratives']
            },

            {
              name: 'Departments',
              iconClasses: 'fas fa-circle',
              permissions: ['departments'],
              path: ['/departments']
            },

            {
              name: 'Disaggregations',
              iconClasses: 'fas fa-circle',
              permissions: ['disaggregations'],
              path: ['/disaggregations']
            },

            {
              name: 'Indicator Groups',
              iconClasses: 'fas fa-circle',
              permissions: ['indicator_group'],
              path: ['/indicator-groups']
            },
          ]},



        {
          name: 'Logs History',
          iconClasses: 'fas fa-user',
          path: ['/logs'],
          permissions: ['log_history'],
          children: [
            {
              name: 'User Logs',
              iconClasses: 'fas fa-circle',
              permissions: ['user_log'],
              path: ['/logs/user-logs']
            },

            {
              name: 'Error Logs',
              iconClasses: 'fas fa-circle',
              permissions: ['error_log'],
              path: ['/logs/error-logs']
            },
          ]},
];
