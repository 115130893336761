import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-indicator-group',
  templateUrl: './indicator-group.component.html',
  styleUrls: ['./indicator-group.component.scss']
})
export class IndicatorGroupComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  activities: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router
  ) {}

  ngOnInit() {
      this.formGroup = new UntypedFormGroup({
          id: new UntypedFormControl(null,[]),
          name: new UntypedFormControl(null, Validators.required),
          code: new UntypedFormControl(null, Validators.required),
          grant_activity_ids: new UntypedFormControl(null, Validators.required),
      });

      this.loadData();
  }

  loadData(){
    this.apiService.getIndicatorGroups().subscribe(response => {
      this.objs = response;
    });

    this.apiService.getAllActivities().subscribe(response => {
      // console.log("activiites", response);
      this.activities = response;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteIndicatorGroup(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['name'].setValue(item.name);
      this.formGroup.controls['code'].setValue(item.code);
      this.formGroup.controls['grant_activity_ids'].setValue(this.getIds(item.activities));


      this.editObj = item;
    }
  }



  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createIndicatorGroup(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

  getName(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.title +" \n ");
    });

    return values;
  }

  getIds(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.id);
    });

    return values;
  }


}

