<!-- Dashboard View -->
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">DTBs Overview</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">DTBs Overview</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <div class="col-lg-3 col-6" (click)="navigateToSubmittedBeneficiaryDetail()">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="inner">
            <h3>{{submittedBeneficiariesCount}}</h3>
            <p>Total Beneficiary Submitted</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-6" (click)="navigateToSubmittedKitDistributionDetail()">
        <!-- small box -->
        <div class="small-box bg-success">
          <div class="inner">
            <h3>{{submittedKitDistributionCount}}</h3>
            <p>Total Kit Distribution Submitted</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-6" (click)="navigateToSubmittedPsychoEducationDetail()">
        <!-- small box -->
        <div class="small-box bg-warning">
          <div class="inner">
            <h3>{{submittedPsychoEducationCount}}</h3>
            <p>Total Psycheducation and Awareness Submitted</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-6" (click)="navigateToSubmittedCommunityDialoguesDetail()">
        <!-- small box -->
        <div class="small-box bg-primary">
          <div class="inner">
            <h3>{{submittedCommunityDialogueCount}}</h3>
            <p>Total Community Dialogue Submitted</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-6" (click)="navigateToSubmittedTrainingsDetail()">
        <!-- small box -->
        <div class="small-box bg-danger">
          <div class="inner">
            <h3>{{submittedTrainingsCount}}</h3>
            <p>Total Training Submitted</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
