<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">DTB Submission for Validation</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/dtbs/submission-list">DTBs</a></li>
          <li class="breadcrumb-item active">DTB Submission for Validation</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card card-body">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div class="row">
          <!-- Existing form elements remain unchanged -->
          <div class="mb-3 col-lg-3">
            <label class="form-label">Grant</label>
            <ng-select
              [items]="grants"
              bindLabel="title"
              bindValue="id"
              formControlName="grant_id"
              (change)="loadGrantProvinces($event)"
            ></ng-select>
            <print-error [control]="formGroup.get('grant_id')"></print-error>
          </div>

          <!-- Other form elements remain the same -->

          <div class="col-lg-3">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>

      <!-- Simple table for displaying submitted DTBs -->
      <table class="table table-striped table-bordered" *ngIf="dtbSubmissions.length > 0">
        <thead>
          <tr>
            <th>Grant</th>
            <th>Province</th>
            <th>Year</th>
            <th>Month</th>
            <th>Manager</th>
            <th>Dtb Name</th>
            <th>Remarks</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dtb of dtbSubmissions">
            <td>{{ dtb.grant_title }}</td>
            <td>{{ dtb.province_name }}</td>
            <td>{{ dtb.year }}</td>
            <td>{{ dtb.month }}</td>
            <td>{{ dtb.manager_name }}</td>
            <td>{{ dtb.dtb_name }}</td>
            <td>{{ dtb.remarks }}</td>
            <td>
              <button (click)="edit(dtb)" class="btn btn-warning btn-sm">Edit</button>
              <button (click)="delete(dtb)" class="btn btn-danger btn-sm">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="dtbSubmissions.length === 0">No submissions available.</p>
    </div>
  </div>
</section>
