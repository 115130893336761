
<div class="row">
  <div class="col-lg-2">
    <h5>Activity:</h5>
  </div>
  <div class="col-lg-10">
<form [formGroup]="formGroup" (ngSubmit)="submit()">

  <div class="row">

    <div class="col-lg-3">
      <ng-select [items]="activities" bindLabel="title" bindValue="id" class="form-select"
        formControlName="grant_activity_id">
      </ng-select>
      <label class="form-label">Activity</label>
    </div>

    <div class="col-lg-3">
      <input type="number" formControlName="baseline_target" class="form-control" placeholder="">
      <label class="form-label">Baseline Target</label>
    </div>

    <div class="col-lg-3">
      <input type="number" formControlName="total_target" class="form-control" placeholder="">
      <label class="form-label">Total # of target</label>
    </div>

          <div class="col-lg-3">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
              <i class="fa fa-add"></i>
            </button>
            <button (click)="showData()" class="btn btn-warning">
              <i class="fa fa-eye"></i>
            </button>
          </div>
        </div>

</form>
</div>
</div>

<table class="table table-sm  table-responsive-md table-responsive-sm" *ngIf="showtable">
  <thead>
    <tr>
      <th>#</th>
      <th [appSort]="activities" data-order="desc" data-name="title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>Title
      </th>
      <th [appSort]="activities" data-order="desc" data-name="outcome?.title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>Outcome
      </th>

      <th [appSort]="activities" data-order="desc" data-name="baseline" scope="col"> <i
        class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
        [hidden]="!showDesc"></i>
      <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
        [hidden]="!showAsc"></i>Baseline Target
    </th>

    <th [appSort]="activities" data-order="desc" data-name="total_target" scope="col"> <i
      class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
      [hidden]="!showDesc"></i>
    <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
      [hidden]="!showAsc"></i>Total Target
  </th>

    </tr>
  </thead>
  <tbody class="table table-sm -group-divider">
    <tr *ngFor="let item of activities; let i = index">
      <th scope="row">{{i+1}}</th>
      <td>{{item.title}}</td>
      <td>{{item.output.title}}</td>
      <td>{{item.baseline_target}}</td>
      <td>{{item.total_taget}}</td>
      <td>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-danger btn-sm" ><i class="fa fa-trash"></i></button>
          <button type="button" class="btn btn-warning btn-sm" ><i class="fa fa-edit"></i></button>
        </div>
      </td>

    </tr>
  </tbody>
</table>
