<div class="row">


  <!-- <div class="col-lg-2"></div> -->
  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="formGroup" (ngSubmit)="submit()">
              <div class="row">
                <div class="col-lg-8">
                   <label>Goal/Impact</label>
                   <input type="text" formControlName="title" placeholder="" class="form-control">
                </div>
                <div class="col-lg-4">
                  <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary custom-btn-margin">
                    <i class="fa fa-add"></i>
                  </button>
                </div>
              </div>
        </form>

      </div>
      <!-- <div class="col-lg-3"></div> -->
    </div>


    <div class="row">
      <div class="col-lg-12">

        <form [formGroup]="outcomeFormGroup" (ngSubmit)="submitOutcome()">

                <div class="row">
                  <div class="col-lg-4">
                    <label>Outcome/Result </label>
                    <input type="text" formControlName="title" placeholder="" class="form-control">
                  </div>

                  <div class="col-lg-4">
                    <label>Link to Goal</label>
                    <ng-select [items]="objs" bindLabel="title" bindValue="id" class="form-select"
                        formControlName="grant_goal_id">
                    </ng-select>
                  </div>

                  <div class="col-lg-4">
                    <button type="submit" [disabled]="outcomeFormGroup.invalid" class="btn btn-primary custom-btn-margin">
                      <i class="fa fa-add"></i>
                    </button>
                  </div>
                </div>

        </form>

      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-lg-12">

        <form [formGroup]="outputFormGroup" (ngSubmit)="submitOutput()">

          <div class="row">
            <div class="col-lg-4">
              <label class="form-label">Output</label>
              <input type="text" formControlName="title" class="form-control" placeholder="">
            </div>

              <div class="col-lg-4">
                <label class="form-label">Link to Result</label>
                <ng-select [items]="outcomes" bindLabel="title" bindValue="id" class="form-select"
                  formControlName="grant_outcome_id">
                </ng-select>
              </div>

                  <div class="col-lg-4">
                    <button type="submit" [disabled]="outputFormGroup.invalid" class="btn btn-primary custom-btn-margin">
                      <i class="fa fa-add"></i>
                    </button>
                  </div>
                </div>

        </form>

      </div>
    </div>



    <div class="row">
      <div class="col-lg-12">

        <form [formGroup]="activitiesFormGroup" (ngSubmit)="submitActivity()">

          <div class="row">
            <div class="col-lg-4">
              <label class="form-label">Activity</label>
              <input type="text" formControlName="title" class="form-control" placeholder="">
            </div>
            <div class="col-lg-4">
              <label class="form-label">Link to Output</label>
              <ng-select [items]="outputs" bindLabel="title" bindValue="id" class="form-select"
                formControlName="grant_output_id">
              </ng-select>
            </div>



                  <div class="col-lg-4">
                    <button type="submit" [disabled]="activitiesFormGroup.invalid" class="btn btn-primary custom-btn-margin">
                      <i class="fa fa-add"></i>
                    </button>
                  </div>
                </div>

        </form>

      </div>
    </div>

    <hr>
    <!-- List of records -->

    <div class="row">
      <div class="col-lg-8">
        <h5>1. Goals</h5>
        <table class="table table-sm  table-bordered table-responsive-md table-responsive-sm">
          <thead>
            <tr>
              <th>#</th>
              <th [appSort]="objs" data-order="desc" data-name="title" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>Title
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs; let i = index">
              <td>{{i+1}}</td>
              <td>{{item.title}}</td>
              <td>
                <div class="btn-group btn-group-sm" role="group" >
                  <button type="button" class="btn btn-danger btn-sm" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr />
        <h5>2. Outcomes</h5>
        <table class="table table-sm  table-bordered table-responsive-md table-responsive-sm">
          <thead>
            <tr>
              <th>#</th>
              <th [appSort]="outcomes" data-order="desc" data-name="title" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>Title
              </th>

              <th [appSort]="outcomes" data-order="desc" data-name="baseline" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>Goal
              </th>
              <th>Action</th>

          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of outcomes; let i = index">
              <td>{{i+1}}</td>
              <td>{{item.title}}</td>
              <td>{{item.goal.title}}</td>
              <td>
                <div class="btn-group btn-group-sm" role="group" >
                  <button type="button" class="btn btn-danger btn-sm" (click)="deleteOutcome(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-primary btn-sm" (click)="editOutcome(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
        <hr />
        <h5>3. Outputs</h5>
        <table class="table table-sm  table-bordered table-responsive-md table-responsive-sm">
          <thead>
            <tr>
              <th>#</th>
              <th [appSort]="outputs" data-order="desc" data-name="title" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>Title
              </th>
              <th [appSort]="outputs" data-order="desc" data-name="status" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>Outcome
              </th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of outputs; let i = index">
              <th scope="row">{{i+1}}</th>
              <td>{{item.title}}</td>
              <td>{{item.outcome.title}}</td>
              <td>
                <div class="btn-group btn-group-sm" role="group" >
                  <button type="button" class="btn btn-danger btn-sm" (click)="deleteOutput(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-primary btn-sm" (click)="editOutput(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <h5>4. Activities</h5>
        <table class="table table-sm  table-bordered table-responsive-md table-responsive-sm">
          <thead>
            <tr>
              <th>#</th>
              <th [appSort]="activities" data-order="desc" data-name="title" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>Title
              </th>
              <th [appSort]="activities" data-order="desc" data-name="status" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                  [hidden]="!showAsc"></i>Outcome
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of activities; let i = index">
              <th scope="row">{{i+1}}</th>
              <td>{{item.title}}</td>
              <td>{{item.output.title}}</td>
              <td>
                <div class="btn-group btn-group-sm" role="group" >
                  <button type="button" class="btn btn-danger btn-sm" (click)="deleteActivity(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-primary btn-sm" (click)="editActivity(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <div class="col-lg-2"></div>

</div>
