import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-psychoeducation',
  templateUrl: './psychoeducation.component.html',
  styleUrls: ['./psychoeducation.component.scss']
})
export class PsychoeducationComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;

  addObj: boolean = false;
  editObj: any;
  grants: any;
  provinces: any;
  districts: any;
  response: any;
  objs: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',[]],
      grant_id: ['', [Validators.required]],
      district_id: ['', [Validators.required]],
      health_facility: ['', []],
      province_id: ['', [Validators.required]],
      village: ['', [Validators.required]],
      site_code: ['', [Validators.required]],
      intervention_modality: ['', [Validators.required]],
      focal_point: ['', [Validators.required]],
      awareness_date: ['', [Validators.required]],
      awareness_topic: ['', [Validators.required]],
      // host_community: ['', [Validators.required]],

      male_idp: ['', []],
      male_refugee: ['', []],
      male_returnee: ['', []],
      male_disable: ['', []],

      female_idp: ['', []],
      female_refugee: ['', []],
      female_returnee: ['', []],
      female_disable: ['', []],


      boy_idp: ['', []],
      boy_refugee: ['', []],
      boy_returnee: ['', []],
      boy_disable: ['', []],



      girl_idp: ['', []],
      girl_refugee: ['', []],
      girl_returnee: ['', []],
      girl_disable: ['', []],
      remarks: ['', []],

    });

      this.loadData();
  }


  loadData(){

    this.apiService.getAllGrants().subscribe(response => {
      this.grants = response.data.data;
    })

    this.apiService.getPsychoeducations().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });
  }


    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  loadGrantProvinces(value:any){
    this.apiService.getGrantProvinces(value.id).subscribe(response => {
      this.provinces = response;
    });

  }

  loadAdminOrgsChildern(value:any){
    this.apiService.getDistricts(value.id).subscribe(response => {
      this.districts = response;
    })
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deletePschoeducation(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['client_name'].setValue(item.client_name);
      this.formGroup.controls['beneficiary_code'].setValue(item.beneficiary_code);
      this.formGroup.controls['village'].setValue(item.village);
      this.formGroup.controls['site_code'].setValue(item.site_code);
      this.formGroup.controls['intervention_modality'].setValue(item.intervention_modality);
      this.formGroup.controls['registration_date'].setValue(item.registration_date);
      this.formGroup.controls['age'].setValue(item.age);
      this.formGroup.controls['gender'].setValue(item.gender);
      this.formGroup.controls['marital_status'].setValue(item.marital_status);
      this.formGroup.controls['age_of_child_of_bnf'].setValue(item.age_of_child_of_bnf);
      this.formGroup.controls['household_status_of_bnf'].setValue(item.household_status_of_bnf);
      this.formGroup.controls['child_of_beneficiary_code'].setValue(item.child_of_beneficiary_code);
      this.formGroup.controls['gbv_survivor'].setValue(item.gbv_survivor);
      this.formGroup.controls['disability_type'].setValue(item.disability_type);
      this.formGroup.controls['referred_for_protection'].setValue(item.referred_for_protection);
      this.formGroup.controls['client_phone'].setValue(item.age);
      this.formGroup.controls['literacy_level_of_bnf'].setValue(item.literacy_level_of_bnf);
      this.formGroup.controls['grant_id'].setValue(item.grant.id);
      this.formGroup.controls['province_id'].setValue(item.province.id);
      this.formGroup.controls['district_id'].setValue(item.district.id);
      this.formGroup.controls['father_or_husband_name'].setValue(item.father_or_husband_name);

      this.editObj = item;
    }
  }


  details(item: any)
  {
    this.router.navigate(['/psychoeducations/details/' + item.id ])
  }


  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createPsychoeducation(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}
