<div [class.pinned-menu]="isPinned">
  <div>
    <button type="button" class="btn btn-outline-secondary"  [class.bg-pinned]="isPinned" style="float: right;" (click)="togglePin()">
      <i class="fa fa-thumb-tack"></i>
    </button>
    <!-- Brand Logo -->
    <a [routerLink]="['/']" class="brand-link">
        <img
            src="assets/logo.png"
            class="img-circle"
            height="40"
            width="40"
        />
        <span class="brand-text">APR System</span>
    </a>

  </div>

  <!-- Sidebar -->
  <div class="sidebar" >
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <!-- <div class="image"> -->
              <!-- <pf-image
                  fallback-src="assets/img/default-profile.png"
                  class="img-circle"
                  alt="User Image"
                  width="34"
                  height="34"
                  rounded
              ></pf-image> -->
              <!-- <img [src]="imageUrl"  class="img-circle " width="34" height="34" alt="User Profile"> -->
          <!-- </div>
          <div class="info">
              <a [routerLink]="['/profile']" class="d-block">
                  {{ user?.name }}
              </a>
          </div>
      </div> -->

      <!-- <div class="form-inline">
          <app-sidebar-search></app-sidebar-search>
      </div> -->

      <!-- Sidebar Menu -->
      <nav class="mt-2" style="overflow-y: hidden">
          <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
          >
              <app-menu-item
                  *ngFor="let item of menu"
                  [menuItem]="item"
              ></app-menu-item>
          </ul>
      </nav>
  </div>

</div>
