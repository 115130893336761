import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  public formGroup: FormGroup;
  public isLoading :boolean = false;
  // objs: any;

  addObj: boolean = false;
  editObj: any;
  roles: any;
  response: any;
  objs: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
      this.formGroup = this.formBuilder.group({
          id: new UntypedFormControl(null,[]),
          name: new UntypedFormControl(null, Validators.required),
          email: new UntypedFormControl(null, [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
          password: new UntypedFormControl(null, [Validators.required]),
          c_password: new UntypedFormControl(null, [Validators.required]),
          roles: new UntypedFormControl(null, Validators.required)
      }, {
        validator: this.ConfirmedValidator('password', 'c_password'),
      });

      this.loadData();
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }


  loadData(){
    // this.apiService.getAllUsers().subscribe(response => {
    //   // console.log("users", response);
    //   this.objs = response;
    // });

    this.apiService.getRoles().subscribe(response => {
      this.roles = response;
    })
    this.apiService.getUsers().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteUser(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }


  deactivate(item: any){
    if(window.confirm("Are you sure to de-activate the user?")){
      this.apiService.deactivateUser(item.id).subscribe(response => {
        this.toastrService.success("De-activated");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }


  activate(item: any){
    if(window.confirm("Are you sure to activate the user?")){
      this.apiService.activateUser(item.id).subscribe(response => {
        this.toastrService.success("Activated");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['name'].setValue(item.name);
      this.formGroup.controls['email'].setValue(item.email);
      this.formGroup.controls['roles'].setValue(this.getIds(item.roles));
      this.editObj = item;
    }
  }


  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createUser(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed, Please check the format of the data you have entered.");
      this.isLoading = false;
    })

  }

  getRoles(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.name);
    });

    return values;
  }

  getIds(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.id);
    });

    return values;
  }



}
