import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  tnaFigures: any;
  total: number = 0;
  provinceFigures: any;
  departmentFigures: any;
  grants: any;
  departments: any;
  deptStats: Array<any> = [];
  core_stats: any;

  constructor(private router: Router, public apiService: ApiService) {
    Chart.register(...registerables, ChartDataLabels); // Register the plugin
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.apiService.getDepartmentsGrantsStats().subscribe(response => {
      this.deptStats = response;

      this.createPieChart();
    });
  }

  loadData() {
    this.apiService.getAllGrants().subscribe(response => {
      this.grants = response.data.data;
    });
    this.apiService.getDepartments().subscribe(response => {
      this.departments = response;
    });
    this.apiService.getCoreStats().subscribe(response => {
      this.core_stats = response;
    });
  }

  createPieChart(): void {
    // Define the types for the data
    const statusData: { [key: string]: number } = this.deptStats.reduce((acc: { [key: string]: number }, item: any) => {
      acc[item.status] = (acc[item.status] || 0) + item.no_grants;
      return acc;
    }, {});
  
    const labels: string[] = Object.keys(statusData);
    const data: number[] = Object.values(statusData);
    const total: number = data.reduce((sum: number, value: number) => sum + value, 0);
  
    const ctx = (document.getElementById('pieChart') as HTMLCanvasElement).getContext('2d');
    if (ctx) {
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: true,
              color: '#000',
              anchor: 'end',
              align: 'end',
              formatter: (value: number, context: any) => {
                const label = context.chart.data.labels[context.dataIndex] as string;
                const percentage = ((value / total) * 100).toFixed(2) + '%';
                return `${label}: ${value} (${percentage})`;
              },
              font: {
                weight: 'bold'
              },
              padding: {
                bottom: 10
              }
            },
            legend: {
              position: 'top',
              labels: {
                boxWidth: 10
              }
            }
          }
        }
      });
    }
  }
  
  
  

  details(item: any) {
    this.router.navigate(['/grant/details/' + item.id]);
  }
}
