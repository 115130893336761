import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-beneficiary-search',
  templateUrl: './beneficiary-search.component.html',
  styleUrls: ['./beneficiary-search.component.scss']
})
export class BeneficiarySearchComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  grants: any;
  provinces: any;
  districts: any;
  category: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder,
      private activedRoute: ActivatedRoute,
    ){

      this.activedRoute.params.subscribe((params) => {
        this.category = params['id'];
      });}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      value: ['', [Validators.required]],
      category: ['', [Validators.required]],

    });

      this.loadData();
  }

  loadData(){
  }





  addServices(item:any)
  {
    // console.log(this.category);

    if(this.category == 'referral'){
      this.router.navigate(['/beneficiaries/referrals/' + item.id ]);
    } else if(this.category == 'activity'){
      this.router.navigate(['/beneficiaries/activities/' + item.id ]);
    }else if(this.category == 'meal'){
      this.router.navigate(['/beneficiaries/meals/' + item.id ]);
    }
    else if(this.category == 'kit-distribution'){
      this.router.navigate(['/beneficiaries/kit-distributions/' + item.id ]);
    }else if(this.category == 'training'){
      this.router.navigate(['/beneficiaries/trainings/' + item.id ]);
    }

    else if(this.category == 'training-tests'){
      this.router.navigate(['/beneficiaries/trainings-tests/' + item.id ]);
    }
    else if(this.category == 'psychoeducation'){
      this.router.navigate(['/beneficiaries/psychoeducations/' + item.id ]);
    }else if(this.category == 'evaluationtermination'){
      this.router.navigate(['/beneficiaries/evaluation-terminations/' + item.id ]);
    }else if(this.category == 'community-dialogue'){
      this.router.navigate(['/beneficiaries/community-dialogues/' + item.id ]);
    }

  }

  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.searchBeneficiary(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      this.objs = response;
      this.toastrService.success("Search Result");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Search Error");
      this.isLoading = false;
    })

  }

  getRoles(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.name);
    });

    return values;
  }

}

