import { Component } from '@angular/core';

@Component({
  selector: 'app-grant-update',
  templateUrl: './grant-update.component.html',
  styleUrls: ['./grant-update.component.scss']
})
export class GrantUpdateComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

}
