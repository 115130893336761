<pf-dropdown hide-arrow class="user-menu">
    <div slot="button">
        <a class="nav-link">
            <!-- <pf-image
                [src]="imageUrl ? imageUrl : 'assets/img/default-profile.png'"
                class="user-image-small"
                width="25"
                height="25"
                alt="User Image"
                rounded
            ></pf-image> -->
            <!-- <img src="{{image ? image : 'assets/img/default-profile.png'}}" alt=""> -->
            <!-- <img *ngIf="imageUrl"  class="user-image-small"  width="25"
            height="25" [src]="imageUrl" alt="Image from API"> -->
            <img [src]="imageUrl" width="25" height="25" alt="Image from API">


        </a>
    </div>
    <div slot="menu">
        <li class="user-header bg-primary">
            <!-- <pf-image
                fallback-src="assets/img/default-profile.png"
                class="user-image-big"
                alt="User Image"
                width="90"
                height="90"
                rounded
            ></pf-image> -->
            <!-- {{imageUrl}} -->
<!--
            <img *ngIf="imageUrl"  class="user-image-small"  width="25"
            height="25" [src]="imageUrl !=null ? imageUrl : defaultUrl" alt="No Picture"> -->

            <!-- <div *ngIf="imageUrl"> -->
              <img [src]="imageUrl"  width="25" height="25" alt="User Profile">
            <!-- </div> -->
            <p>
                <span>{{ user?.email }}</span>
                <small>
                    <span class="mr-2">Member since</span>
                    <span>{{ formatDate(user?.created_at) }}</span>
                </small>
            </p>
        </li>
        <!-- Menu Body -->
        <!-- <li class="user-body">
            <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div>
        </li> -->
        <!-- Menu Footer-->
        <li class="user-footer d-flex justify-content-between align-items-center">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a [routerLink]="['/setting']" class="btn btn-default btn-flat mx-auto">
                Setting
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right">
                Sign out
            </a>
        </li>        
        
    </div>
</pf-dropdown>
