<pf-dropdown hide-arrow>
    <div slot="button">
        <a class="nav-link">
            {{this.translocoService.getActiveLang()}}
        </a>
    </div>
    <div slot="menu">

        <a *ngFor="let language of languagesList; index as i" (click)="changeLanguage(language.code)" class="dropdown-item">{{language.name}}</a>
        <!-- <a href="#" class="dropdown-item">
            <span>English</span>
        </a>
        <a href="#" class="dropdown-item">
            <span>Pashto</span>
        </a>
        <a href="#" class="dropdown-item">
            <span>Dari</span>
        </a> -->
    </div>
</pf-dropdown>


<!-- <div class="flex justify-center items-center">
    <button *ngFor="let language of languagesList; index as i" class="flex flex-col items-center mx-5"
        (click)="changeLanguage(language.code)">
        <span class="text-xs mt-1"> {{ language.shorthand }} </span>
    </button>
</div> -->