import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dtb-submission-list',
  templateUrl: './dtb-submission-list.component.html',
  styleUrls: ['./dtb-submission-list.component.scss']
})
export class DtbSubmissionListComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;

  addObj: boolean = false;
  editObj: any;
  grants: any;
  provinces: any;
  districts: any;
  years: any[];
  months: any[];
  users: any;
  response: any;
  objs: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
      this.loadData();
  }

  loadData(){
    this.apiService.getAllDataValidations().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  download(item: any){
    this.apiService.exportToExcel(this.response?.data, item.year +"-"+ item.month + "-dtb");
  }
  validate(item: any){
    console.log(item);
    if(window.confirm("Are you sure to validate?")){
      this.apiService.validateDataValidation(item.id).subscribe(response => {
        console.log(response);
        this.toastrService.success("Database has been validate");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }

  reject(item: any){
    if(window.confirm("Are you sure to reject?")){
      this.apiService.rejectDataValidation(item.id).subscribe(response => {
        this.toastrService.success("Rejected");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }


  resend(item: any){
    if(window.confirm("Are you sure to resend?")){
      this.apiService.resendDataValidation(item.id).subscribe(response => {
        this.toastrService.success("Resent");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }


}

