<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Departments</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Departments</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">

        <button type="button" class="col-lg-2 mb-1 btn btn-primary btn-sm"  (click)="addObjectClicked()">Add New</button>
        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

            <tr class="bg-primary">
              <!-- <th [appSort]="objs" data-order="desc" data-name="name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</th> -->
              <td [appSort]="objs" data-order="desc" data-name="name" scope="col">
                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>
                &nbsp;Name</td>
              <td [appSort]="objs" data-order="desc" data-name="code" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Code</td>
              <td scope="col">Action</td>

            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.name}}</td>
              <td>{{item.code}}</td>
              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

          <div class="row">
            <div class="col-lg-4">
              <label  class="form-label">Name</label>
              <input type="type" formControlName="name" class="form-control" placeholder="">
            </div>
            <div class="col-lg-4">
              <label  class="form-label">Code</label>
              <input type="text" formControlName="code" class="form-control" placeholder="">
            </div>
            <div class="col-lg-12 mt-1">
              <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
                 Submit
               </button>
           </div>
          </div>

        </form>


      </div>
  </div>
</section>
