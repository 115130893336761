
<div class="row">
  <div class="col-lg-2">
    <h5>Sessions:</h5>
  </div>
  <div class="col-lg-10">
<form [formGroup]="formGroup" (ngSubmit)="submit()">

  <div class="row">

    <div class="col-lg-3">
      <ng-select [items]="activities" bindLabel="title" bindValue="id" class="form-select"
        formControlName="grant_activity_id">
      </ng-select>
      <label class="form-label">Activity</label>
    </div>

    <div class="col-lg-3">
      <ng-select [items]="disaggregations" bindLabel="name" bindValue="id" class="form-select"
        formControlName="disaggregation">
      </ng-select>
      <label class="form-label">Disaggregation</label>
    </div>

    <div class="col-lg-3">
      <input type="number" formControlName="overall_target" class="form-control" placeholder="">
      <label class="form-label">Overall Target</label>
    </div>

          <div class="col-lg-3">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
              <i class="fa fa-add"></i>
            </button>
          </div>
        </div>

</form>


</div>
</div>
