<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Kobo Collected Data</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/grant/overview">Kobo Form Data</a></li>
          <li class="breadcrumb-item active">Kobo Collected Form</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card card-body" id="content" #content>
      <div class="row">
        <div class="col-lg-8">
          <div class="btn-group" role="group">
            <!-- <a type="button" class="btn btn-primary btn-sm" (click)="savePDF()">Export PDF</a>
            <a type="button" class="btn btn-success btn-sm" (click)="saveExcel()">Export Excel</a> -->
          </div>
        </div>
        <div class="input-group col-lg-4">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span>
          </div>
          <!-- <input (input)="filter($event.target.value)" class="form-control" placeholder="Search..."> -->
        </div>
      </div>

      <div *ngIf="koboData.length > 0" style="overflow: auto;">
        <table id="dtBasicExample" class="table table-sm table-bordered table-responsive-sm table-responsive-md">
          <thead>
            <tr class="bg-primary">
              <th>#</th>
              <th>Grant</th>
              <th>Province</th>
              <th>District</th>
              <th>Village</th>
              <th>Site Code</th>
              <th>Intervention Modality</th>
              <th>Registration Date</th>
              <th>Beneficiary Code</th>
              <th>Name</th>
              <th>Father or Husband Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Marital Status</th>
              <th>Child of Beneficiary Code</th>
              <th>Age of Child of Beneficiary</th>
              <th>Phone</th>
              <th>Household Status</th>
              <th>Literacy Level</th>
              <th>Disability Type</th>
              <th>GBV Survivor</th>
              <th>Referred for Protect MHPSS GBV Services</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of koboData; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.Grant }}</td>
              <td>{{ item.Province }}</td>
              <td>{{ item.District }}</td>
              <td>{{ item.Village }}</td>
              <td>{{ item.Site_Code }}</td>
              <td>{{ item.Intervention_Modality }}</td>
              <td>{{ item.Registration_Date }}</td>
              <td>{{ item.Beneficiary_Code }}</td>
              <td>{{ item.Name }}</td>
              <td>{{ item.Father_or_Husband_Name }}</td>
              <td>{{ item.Age }}</td>
              <td>{{ item.Gender }}</td>
              <td>{{ item.Marital_Status }}</td>
              <td>{{ item.Child_of_Beneficiary_Code }}</td>
              <td>{{ item.Age_of_child_of_Beneficiary }}</td>
              <td>{{ item.Phone }}</td>
              <td>{{ item.Household_Status }}</td>
              <td>{{ item.Literacy_Level }}</td>
              <td>{{ item.Disability_Type }}</td>
              <td>{{ item.GBV_Survivor }}</td>
              <td>{{ item.Referred_for_Protect_MHPSS_GBV_services }}</td>
              <td>
                <button type="button" class="btn btn-success" (click)="process(item)">Validate</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="koboData.length === 0">
        No data available.
      </div>
    </div>
  </div>
</section>
