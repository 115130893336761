<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h4 class="m-0 text-dark">All Submitted Beneficiaries</h4>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/dtbs/submission-list">DTBs</a></li>
                    <li class="breadcrumb-item active">All Submitted Beneficiaries</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
        <div class="card card-body">
  
          <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add Submission</button> -->
          <div class="row">
            <div class="col-lg-8"></div>
            <div class="input-group col-lg-4">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
              </div>
              <input class="input" (input)="filter($event.target.value)"  class="form-control" placeholder="">
            </div>
          </div>
  
          <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" >
  
              <tr class="bg-primary">
                <td data-order="desc" data-name="id" scope="col"> <i class="fa fa-sort-desc"
                                                                                                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>#
                </td>
                <td data-order="desc" data-name="grant_title" scope="col"> <i class="fa fa-sort-desc"
                                                                                                         aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Grant
                </td>
                <td data-order="desc" data-name="province_name" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Province
                </td>
                <td data-order="desc" data-name="district_name" scope="col"> <i class="fa fa-sort-desc"
                                                                                                           aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>District
                </td>
                <td data-order="desc" data-name="village" scope="col"> <i class="fa fa-sort-desc"
                                                                                                     aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Village
                </td>
                <td data-order="desc" data-name="site_code" scope="col"> <i class="fa fa-sort-desc"
                                                                                                       aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Site Code
                </td>
                <td data-order="desc" data-name="intervention_modality" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Intervention Modality
                </td>
                <td data-order="desc" data-name="registration_date" scope="col"> <i class="fa fa-sort-desc"
                                                                                                               aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Registration Date
                </td>
                <td data-order="desc" data-name="beneficiary_code" scope="col"> <i class="fa fa-sort-desc"
                                                                                                              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Beneficiary Code
                </td>
                <td data-order="desc" data-name="client_name" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Name
                </td>
                <td data-order="desc" data-name="father_or_husband_name" scope="col"> <i class="fa fa-sort-desc"
                                                                                                                    aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Father or Husband Name
                </td>
                <td data-order="desc" data-name="age" scope="col"> <i class="fa fa-sort-desc"
                                                                                                 aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Age
                </td>
                <td data-order="desc" data-name="gender" scope="col"> <i
                  class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                  [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Gender
                </td>
                <td data-order="desc" data-name="marital_status" scope="col"> <i class="fa fa-sort-desc"
                                                                                                            aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Marital Status
                </td>
                <td data-order="desc" data-name="child_of_beneficiary_code" scope="col"> <i class="fa fa-sort-desc"
                                                                                                                       aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Child of Beneficiary Code
                </td>
                <td data-order="desc" data-name="age_of_child_of_bnf" scope="col"> <i class="fa fa-sort-desc"
                                                                                                                 aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Age of child of Beneficiary
                </td>
                <td data-order="desc" data-name="client_phone" scope="col"> <i class="fa fa-sort-desc"
                                                                                                          aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Phone
                </td>
                <td data-order="desc" data-name="household_status_of_bnf" scope="col"> <i class="fa fa-sort-desc"
                                                                                                                     aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Household Status
                </td>
                <td data-order="desc" data-name="literacy_level_of_bnf" scope="col"> <i class="fa fa-sort-desc"
                                                                                                                   aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Literacy Level
                </td>
                <td data-order="desc" data-name="disability_type" scope="col"> <i class="fa fa-sort-desc"
                                                                                                             aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Disability Type
                </td>
                <td data-order="desc" data-name="gbv_survivor" scope="col"> <i class="fa fa-sort-desc"
                                                                                                          aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>GBV Survivor
                </td>
                <td data-order="desc" data-name="referred_for_protection" scope="col"> <i class="fa fa-sort-desc"
                                                                                                                     aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>Referred for Protection (MHPSS, GBV) services?
                </td>
                <td scope="col">Action</td>
  
              </tr>
            <tbody class="table table-sm -group-divider">
              <tr *ngFor="let item of response?.data; let i = index">
                <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
                <td>{{item.grant.title}}</td>
                <td>{{item.province.name}}</td>
                <td>{{item.district.name}}</td>
                <td>{{item.village}}</td>
                <td>{{item.site_code}}</td>
                <td>{{item.intervention_modality}}</td>
                <td>{{item.registration_date | date}}</td>
                <td>{{item.beneficiary_code}}</td>
                <td>{{item.client_name}}</td>
                <td>{{item.father_or_husband_name}}</td>
                <td>{{item.age}}</td>
                <td>{{item.gender}}</td>
                <td>{{item.marital_status}}</td>
                <td>{{item.child_of_beneficiary_code}}</td>
                <td>{{item.age_of_child_of_bnf}}</td>
                <td>{{item.client_phone}}</td>
                <td>{{item.household_status_of_bnf}}</td>
                <td>{{item.literacy_level_of_bnf}}</td>
                <td>{{item.disability_type}}</td>
                <td>{{item.gbv_survivor}}</td>
                <td>{{item.referred_for_protection}}</td>
                <td>
                  <div class="btn-group" role="group" >
                    <button type="button" class="btn btn-primary"  (click)="download(item)">Download</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
  
             <div class="d-flex justify-content-center mt-1">
            <span *ngFor="let item of response?.links" >
              <button  (click)="clickLink(item.url ? item.url : response.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
            </span>
          </div>
  
  
        </div>
    </div>
  </section>
  