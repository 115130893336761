<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Project Code: {{grant?.code}}</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="/grant/overview">Grants</a></li>
          <li class="breadcrumb-item"><a href="/grant/details/{{grant?.id}}">{{grant?.code}}</a></li>

          <li class="breadcrumb-item active">Details</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">

      <table class="table table-sm  table-bordered table-responsive-sm table-responsive-md">
        <tr>
          <td class="labelinfo">Code:</td>
          <td>{{grant?.code}}</td>
        </tr>

        <tr>
          <td class="labelinfo">Title:</td>
          <td>{{grant?.title}}</td>
        </tr>

        <tr>
          <td class="labelinfo">Description:</td>
          <td>{{grant?.description}}</td>
        </tr>

        <tr>
          <td class="labelinfo">Donor:</td>
          <td>{{grant?.donor}}</td>
        </tr>

        <tr>
          <td class="labelinfo">Start Date:</td>
          <td>{{grant?.start_date | date}}</td>
        </tr>

        <tr>
          <td class="labelinfo">End Date:</td>
          <td>{{grant?.end_date | date}}</td>
        </tr>


        <tr>
          <td class="labelinfo">Manager:</td>
          <td>{{grant?.manager.name}} / {{grant?.manager.email}}</td>
        </tr>
        <tr>
          <td class="labelinfo">Reporting Type:</td>
          <td>{{grant?.reporting_type}}</td>

        </tr>
        <tr>
          <td class="labelinfo">Reporting Interim:</td>
          <td>{{grant?.number_of_report_interims}}</td>
        </tr>
        <tr>
          <td class="labelinfo">Status:</td>
          <td>{{grant?.status}}</td>
        </tr>
      </table>

      <hr />

      <h5>1. Goals</h5>
      <table class="table table-sm  table-responsive">
        <thead>
          <tr>
            <th>#</th>
            <th [appSort]="objs" data-order="desc" data-name="title" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Title
            </th>
            <th [appSort]="objs" data-order="desc" data-name="status" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Status
            </th>

          </tr>
        </thead>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of objs; let i = index">
            <td>{{i+1}}</td>
            <td>{{item.title}}</td>
            <td>{{item.status}}</td>
          </tr>
        </tbody>
      </table>

      <hr />
      <h5>2. Outcomes</h5>
      <table class="table table-sm  table-responsive">
        <thead>
          <tr>
            <th>#</th>
            <th [appSort]="outcomes" data-order="desc" data-name="title" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Title
            </th>
            <th [appSort]="outcomes" data-order="desc" data-name="status" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Status
            </th>
            <th [appSort]="outcomes" data-order="desc" data-name="baseline" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Baseline
            </th>
            <th [appSort]="outcomes" data-order="desc" data-name="target" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Target
            </th>
          </tr>
        </thead>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of outcomes; let i = index">
            <td>{{i+1}}</td>
            <td>{{item.title}}</td>
            <td>{{item.status}}</td>
            <td>{{item.baseline}}</td>
            <td>{{item.target}}</td>

          </tr>
        </tbody>
      </table>


      <hr />
      <h5>3. Outputs</h5>


      <table class="table table-sm  table-responsive">
        <thead>
          <tr>
            <th>#</th>
            <th [appSort]="outputs" data-order="desc" data-name="title" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Title
            </th>
            <th [appSort]="outputs" data-order="desc" data-name="status" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Status
            </th>

            <th [appSort]="outputs" data-order="desc" data-name="baseline" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Baseline
            </th>
            <th [appSort]="outputs" data-order="desc" data-name="target" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Target
            </th>
          </tr>
        </thead>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of outputs; let i = index">
            <th scope="row">{{i+1}}</th>
            <td>{{item.title}}</td>
            <td>{{item.status}}</td>
            <td>{{item.baseline}}</td>
            <td>{{item.target}}</td>
          </tr>
        </tbody>
      </table>



      <hr />
      <h5>4. Activities</h5>

      <table class="table table-sm  table-responsive">
        <thead>
          <tr>
            <th>#</th>
            <th [appSort]="activities" data-order="desc" data-name="title" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Title
            </th>
            <th [appSort]="activities" data-order="desc" data-name="status" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Status
            </th>
            <th [appSort]="activities" data-order="desc" data-name="acf" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>ACF
            </th>
            <th [appSort]="activities" data-order="desc" data-name="total" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Total
            </th>
            <th [appSort]="activities" data-order="desc" data-name="baseline_target" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Baseline
            </th>
            <th [appSort]="activities" data-order="desc" data-name="total_target" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Target
            </th>
          </tr>
        </thead>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of activities; let i = index">
            <th scope="row">{{i+1}}</th>
            <td>{{item.title}}</td>
            <td>{{item.status}}</td>
            <td>{{item.acf}}</td>
            <td>{{item.total}}</td>
            <td>{{item.baseline_target}}</td>
            <td>{{item.total_target}}</td>

          </tr>
        </tbody>
      </table>



    </div>
  </div>
</section>
