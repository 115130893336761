import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public formGroup: FormGroup;
  public isLoading: boolean = false;
  user: any;
  selectedImage: any;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['', []],
      name: ['', Validators.required],
      email: ['', Validators.required],
      image: [null, []]
    });

    this.loadData();
  }

  onSelectImage(event: any) {
    this.selectedImage = event.target.files[0];
  }

  loadData() {
    this.apiService.getActiveUser().subscribe(response => {
      this.user = response;
      this.formGroup.controls['id'].setValue(response.id);
      this.formGroup.controls['name'].setValue(response.name);
      this.formGroup.controls['email'].setValue(response.email);
    });
  }

  submit() {
    if (!this.formGroup?.valid) {
      return;
    }

    this.formGroup.controls['id'].setValue(this.user.id);

    const formData = new FormData();
    formData.append("id", this.user.id);
    formData.append("name", this.formGroup.value.name);
    formData.append("email", this.formGroup.value.email);
    formData.append("image", this.selectedImage, this.selectedImage.name);

    this.apiService.updateProfile(formData).subscribe(response => {
      this.isLoading = false;
      this.toastrService.success("Update succeeded");
      this.formGroup.reset();
      this.loadData();
    }, (e) => {
      this.toastrService.error("Update failed!");
      this.isLoading = false;
    });
  }
}
