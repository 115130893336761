import { AfterViewInit, Component, Input } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-community-dialogue-followups',
  templateUrl: './community-dialogue-followups.component.html',
  styleUrls: ['./community-dialogue-followups.component.scss']
})
export class CommunityDialogueFollowupsComponent implements OnInit, AfterViewInit{
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  beneficiaryId: any;
  addValue: any = true;
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  beneficiary: any;
  activities: any;
  dialogueId: any;
  dialogu: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder
      ){


    }

  ngAfterViewInit(){

  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',],
      parent_id: ['',],
      beneficiary_id: ['',[]],
      grant_id: ['',[]],
      user: ['',[]],
      role_in_community: ['',[Validators.required]],
      cd_date: ['',[Validators.required]],
      cd_type: ['',[Validators.required]],
      received_incentive: ['',[Validators.required]],
      incentive_amount: ['',[]],
      session_type: ['',[Validators.required]],
      remarks: ['',[]],
      initial_date: [],
      initial_session_type: []
    });

    this.activedRoute.params.subscribe((params) => {
      this.dialogueId = params['id'];
      this.apiService.getCommunityDialogueById(this.dialogueId).subscribe(data => {
        console.log("dialogue", data);
        this.dialogu = data;

        this.formGroup.controls['session_type'].setValue("Follow-up");
        this.formGroup.controls['parent_id'].setValue(this.dialogu?.id);
        this.formGroup.controls['beneficiary_id'].setValue(this.dialogu?.beneficiary_id);
        this.formGroup.controls['grant_id'].setValue(this.dialogu?.grant_id);
        this.formGroup.controls['role_in_community'].setValue(this.dialogu?.role_in_community);
        this.formGroup.controls['received_incentive'].setValue(this.dialogu?.received_incentive);
        this.formGroup.controls['incentive_amount'].setValue(this.dialogu?.incentive_amount);
        this.formGroup.controls['cd_type'].setValue(this.dialogu?.cd_type);
        this.formGroup.controls['initial_date'].setValue(this.dialogu?.cd_date);
        this.formGroup.controls['initial_session_type'].setValue(this.dialogu?.session_type);




        this.apiService.getBeneficiary(this.dialogu.beneficiary_id).subscribe((response) => {
          this.beneficiary = response;
        });
      });

    });



      this.loadData();
  }

  loadData(){
    this.apiService.getCommunityDialogueFollowUps(this.dialogueId).subscribe(response => {
      this.objs = response;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteCommunityDialogue(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['role_in_community'].setValue(item.role_in_community);
      this.formGroup.controls['cd_date'].setValue(item.cd_date);
      this.formGroup.controls['cd_type'].setValue(item.cd_type);
      this.formGroup.controls['received_incentive'].setValue(item.received_incentive);
      this.formGroup.controls['incentive_amount'].setValue(item.incentive_amount);
      this.formGroup.controls['session_type'].setValue(item.session_type);

      this.formGroup.controls['remarks'].setValue(item.remarks);

      this.editObj = item;
    }
  }




  submit(){
    this.formGroup.controls['grant_id'].setValue(this.beneficiary.grant_id);
    this.formGroup.controls['beneficiary_id'].setValue(this.beneficiary.id);

    this.apiService.createCommunityDialogue(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      this.toastrService.success("Registration succeeded");
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }


}

