<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">My Space</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">My Space</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="row justify-content-center">


        <div class="card border-primary mb-3 mr-1 col-sm-12" style="max-width: 25rem;">
            <div class="card-header bg-primary">Beneficiaries Database</div>
            <div class="card-body">
              <p class="card-text">You can manage Beneficiaries and Activities in this section</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries')"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
        <div class="card border-primary mb-3 mr-1 col-sm-12" style="max-width: 25rem;">
            <div class="card-header bg-primary">Kit Distribution</div>
            <div class="card-body">
              <p class="card-text">You can manage Kit Distributions in this section</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/search/kit-distribution')"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="card border-primary mb-3 mr-1 col-sm-12" style="max-width: 25rem;">
            <div class="card-header bg-primary">Psycheducation and Awareness</div>
            <div class="card-body">
              <p class="card-text">You can manage Psycheducation and Awareness in this section</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/psychoeducations')"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
        <div class="card border-primary mb-3 mr-1 col-sm-12" style="max-width: 25rem;">
            <div class="card-header bg-primary">Community Dialogue</div>
            <div class="card-body">
              <p class="card-text">You can find the rejected assessments with this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/search/community-dialogue')"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
        <!-- <div class="card border-primary mb-3 mr-1 col-sm-12" style="max-width: 25rem;">
            <div class="card-header bg-primary">Referral</div>
            <div class="card-body">
              <p class="card-text">You can manage the Referrals in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/search/referral')"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div> -->
        <div class="card border-primary mb-3 mr-1 col-sm-12" style="max-width: 25rem;">
            <div class="card-header bg-primary">Training</div>
            <div class="card-body">
              <p class="card-text">You can manage the Trainings in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/myspace/participants')"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

<!-- <div class="col-lg-4 col-sm-12">
  <div (click)="route('/')" >
  <div class="small-box" >
      <div class="inner">
          <h3>EQUIP</h3>

          <p>You can manage EQUIP in this section</p>
      </div>
      <div class="icon">
          <i class="fas fa-arrow-circle-right"></i>
      </div>
  </div>
</div>
</div> -->

</div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
