import {Component, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';
import { catchError } from 'rxjs';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;
    imageUrl: any = null;
    defaultUrl: any = 'assets/img/default-profile.png';

    constructor(private appService: AppService, private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.user = this.appService.user;

        if(!this.user){
            this.appService.returnProfile().subscribe((response: any) =>{
                this.user = response.success;
            });
        }

          this.appService.loadUserProfileImage().pipe(
            catchError(error => {
              // handle the error
              console.error(error);
              return [];
            })
          ).subscribe(blob => {
            // create the safe URL and assign it to the variable


            blob.arrayBuffer().then(buffer => {
              if (buffer.byteLength === 0) {
                // the blob is empty
                console.log("empty buffer");
                this.imageUrl = this.defaultUrl;

              } else {
                // the blob is not empty
                this.imageUrl = this.createImageURL(blob);
              }
            })
            // console.log(blob);

            // if(blob.message){

            // }else{
            //   this.imageUrl = this.createImageURL(blob);
            // }
          });

    }

    createImageURL(blob: Blob) {
      const url = URL.createObjectURL(blob);
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    logout() {
        this.appService.logout();
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
