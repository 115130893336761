<div class="card card-outline card-primary">
    <h5 *ngIf="isBlocked" style="color:red"> You are blocked. <br/> Contact your administrator.</h5>

    <div class="card-body" *ngIf="!isBlocked">
        <p class="login-box-msg" style="color: red;" *ngIf="message">{{message?.message}}</p>

        <div class="text-center" style="margin-bottom: 10px;">
            <pf-image src="assets/logo.png" class="brand-image" alt="AAH Logo" width="200"></pf-image>
        </div>

        <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
            <div class="mb-3">
                <input
                    formControlName="username"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    autocomplete="on"
                />
                <div *ngIf="submitted && loginForm.controls.username.invalid" class="text-danger">
                    <small *ngIf="loginForm.controls.username.errors?.required">Email is required.</small>
                    <small *ngIf="loginForm.controls.username.errors?.email">Email must be valid.</small>
                </div>
            </div>
            <div class="mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    autocomplete="on"
                />
                <div *ngIf="submitted && loginForm.controls.password.invalid" class="text-danger">
                    <small *ngIf="loginForm.controls.password.errors?.required">Password is required.</small>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="icheck-primary">
                        <input type="checkbox" id="remember" formControlName="remember_me"/>
                        <label for="remember"> Remember Me </label>
                    </div>
                </div>
                <div class="col-12">
                    <button type="submit" [disabled]="loginForm.invalid" style="width: 100%;" class="btn btn-success">
                      Log In
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
