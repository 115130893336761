<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Profile</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Profile</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content" style="padding: 1%;">
  <div class="container-fluid">
    <div class="card card-body">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div class="mb-3">
          <label class="form-label">Name</label>
          <input type="text" formControlName="name" class="form-control" placeholder="">
          <print-error [control]="formGroup.get('name')"></print-error>
        </div>

        <div class="mb-3">
          <label class="form-label">Email address</label>
          <input type="email" formControlName="email" class="form-control" placeholder="">
          <print-error [control]="formGroup.get('email')"></print-error>
        </div>

        <div class="mb-3">
          <label class="form-label">Profile Picture</label>
          <input type="file" id="image" formControlName="image" class="form-control" (change)="onSelectImage($event)">
          <print-error [control]="formGroup.get('image')"></print-error>
        </div>

        <div class="row">
          <div class="col-6">
            <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
              Submit
            </button>
          </div>
          <div class="col-6"></div>
        </div>
      </form>
    </div>
  </div>
</section>
