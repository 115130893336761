import { Component } from '@angular/core';

@Component({
  selector: 'app-equip',
  templateUrl: './equip.component.html',
  styleUrls: ['./equip.component.scss']
})
export class EquipComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

}
