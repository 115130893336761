<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Device Manager</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/users">Users</a></li>
                  <li class="breadcrumb-item active">Device Manager</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->



<div class="container-fluid mt-2">
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(1)" [ngClass]="currentStep == 1 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab1">Pending</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(2)" [ngClass]="currentStep == 2 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab2">Approved</a>
        </li>
      </ul>

    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <form #multiStepForm="ngForm">
            <div [ngSwitch]="currentStep">
              <!-- Step 1 -->
              <div *ngSwitchCase="1">



                        <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button> -->


                        <div *ngIf="!addObj">

                          <div class="row">
                            <div class="col-lg-8"></div>
                            <div class="input-group col-lg-4">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
                              </div>
                              <input class="input" (input)="filterPending($event.target.value)"  class="form-control" placeholder="">
                            </div>
                          </div>
                        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" >
                            <tr class="bg-primary">
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</td>
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>User</td>
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="device_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Device Info</td>
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="platform" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Platform</td>
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="browser" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Browser</td>
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="browser_version" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Version</td>
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="ip" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>IP Address</td>
                              <td [appSort]="pendingResponses?.data" data-order="desc" data-name="ip" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Status</td>

                              <td scope="col">Action</td>

                            </tr>
                          <tbody class="table table-sm -group-divider">
                            <tr *ngFor="let item of pendingResponses?.data; let i = index">
                              <th scope="row">{{pendingResponses?.from == 1 ? i+1 :pendingResponses?.from + i}}</th>
                              <td>{{item.user.name}}</td>
                              <td>{{item.device_name}}</td>
                              <td>{{item.platform}}</td>
                              <td>{{item.browser}}</td>
                              <td>{{item.browser_version}}</td>
                              <td>{{item.ip}}</td>
                              <td>{{item.is_allowed ? 'Allowed' : 'Under Review'}}</td>
                              <td>


                                <div class="btn-group" role="group" >
                                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                                  <button type="button" *ngIf="!item.is_allowed" class="btn btn-success" (click)="activate(item)">Allow</button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                           <div class="d-flex justify-content-center mt-1">
                          <span *ngFor="let item of pendingResponses?.links" >
                            <button  (click)="clickLinkPending(item.url ? item.url : pendingResponses.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && pendingResponses?.next_page_url == null ) || (item.label.includes('Previous') && pendingResponses?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
                            [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
                          </span>
                        </div>

                        </div>


                  <!-- End of list -->
                <hr />
                <button type="button" class="btn btn-success ml-1" (click)="nextStep()">Next</button>
              </div>


              <!-- Step 2 -->
              <div *ngSwitchCase="2">



                        <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button> -->


                        <div *ngIf="!addObj">

                          <div class="row">
                            <div class="col-lg-8"></div>
                            <div class="input-group col-lg-4">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
                              </div>
                              <input class="input" (input)="filterApproved($event.target.value)"  class="form-control" placeholder="">
                            </div>
                          </div>
                        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" >
                            <tr class="bg-primary">
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</td>
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="user_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>User</td>
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="device_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Device Info</td>
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="platform" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Platform</td>
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="browser" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Browser</td>
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="browser_version" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Version</td>
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="ip" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>IP Address</td>
                              <td [appSort]="approvedResponses?.data" data-order="desc" data-name="ip" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Status</td>

                              <td scope="col">Action</td>

                            </tr>
                          <tbody class="table table-sm -group-divider">
                            <tr *ngFor="let item of approvedResponses?.data; let i = index">
                              <th scope="row">{{approvedResponses?.from == 1 ? i+1 :approvedResponses?.from + i}}</th>
                              <td>{{item.user.name}}</td>
                              <td>{{item.device_name}}</td>
                              <td>{{item.platform}}</td>
                              <td>{{item.browser}}</td>
                              <td>{{item.browser_version}}</td>
                              <td>{{item.ip}}</td>
                              <td>{{item.is_allowed ? 'Allowed' : 'Under Review'}}</td>
                              <td>


                                <div class="btn-group" role="group" >
                                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                                  <button type="button" *ngIf="!item.is_allowed" class="btn btn-success" (click)="activate(item)">Allow</button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                           <div class="d-flex justify-content-center mt-1">
                          <span *ngFor="let item of approvedResponses?.links" >
                            <button  (click)="clickLinkApproved(item.url ? item.url : approvedResponses.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && approvedResponses?.next_page_url == null ) || (item.label.includes('Previous') && approvedResponses?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
                            [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
                          </span>
                        </div>

                        </div>

                <button type="button" class="btn btn-secondary" (click)="prevStep()">Previous</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
