import { Component, Input } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-beneficiary-meal',
  templateUrl: './beneficiary-meal.component.html',
  styleUrls: ['./beneficiary-meal.component.scss']
})
export class BeneficiaryMealComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  @Input() beneficiaryId: any;
  addValue: any = true;
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  beneficiary: any;
  activities: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder
      ){

        // this.activedRoute.params.subscribe((params) => {
        //   this.beneficiaryId = params['id'];
        //   this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
        //     this.beneficiary = response;
        //     // this.apiService.getGrantActivities(this.beneficiary.grant_id).subscribe(response => {
        //     //   this.activities = response;
        //     // });
        //   });
        // });
    }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',],
      grant_id: ['', []],
      beneficiary_id: ['', []],
      who_baseline_date: ['', [Validators.required]],
      who_endline_date: ['', [Validators.required]],
      activate_baseline: ['', []],
      activate_endline: ['', []],
      baseline_total_score: ['', []],
      endline_total_score: ['', []],
      percentage_of_improvement: ['', []],
      evaluation_baseline_date: ['', []],
      evaluation_baseline_level: ['', []],
      evaluation_endline_date: ['', []],
      evaluation_endline_level: ['', []],
      remarks: ['', []],
    });

      this.loadData();
  }

  loadData(){

    this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
      this.beneficiary = response;
    });

    this.apiService.getBeneficiaryMealTools(this.beneficiaryId).subscribe(response => {
      this.objs = response;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteBeneficiaryMealTools(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['grant_activity_id'].setValue(item.activity.id);
      this.formGroup.controls['activity_type'].setValue(item.activity_type);
      this.formGroup.controls['activity_category'].setValue(item.activity_category);
      this.formGroup.controls['session_id'].setValue(item.session_id);
      this.formGroup.controls['session_topic'].setValue(item.session_topic);
      this.formGroup.controls['description'].setValue(item.description);
      this.formGroup.controls['session_date'].setValue(item.session_date);

      this.editObj = item;
    }
  }



  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);
    this.formGroup.controls['grant_id'].setValue(this.beneficiary.grant_id);
    this.formGroup.controls['beneficiary_id'].setValue(this.beneficiary.id);

    this.apiService.createBeneficiaryMealTools(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}


