
<div class="row">

  <div class="col-lg-12">
<form [formGroup]="formGroup" (ngSubmit)="submit()">

  <div class="row">

    <div class="mb-3 col-lg-3">
      <label class="fw-light">Area of Work</label>

      <select class="form-control" formControlName="area">
        <option value="Improved Mental health and psychosocial well-being">Improved Mental health and psychosocial well-being </option>
        <option value="Total transfers for the MHPSS & Protection sector">Total transfers for the MHPSS & Protection sector</option>
        <option value="Reach of Care Practices">Reach of Care Practices</option>
        <option value="Reach of Mental health and Psychosocial Support and Protection" selected>Reach of Mental health and Psychosocial Support and Protection</option>
        <option value="Reach of MHPSS, Care Practices and Protection capacity building activities" selected>Reach of MHPSS, Care Practices and Protection capacity building activities</option>
        <option value="Reach of MHPSS, Care Practices and Protection kits deliveries" selected>Reach of MHPSS, Care Practices and Protection kits deliveries</option>
      </select>
      <print-error [control]="formGroup.get('area')"></print-error>

    </div>

    <div class="mb-3 col-lg-3">
      <label class="fw-light">Activities</label>

      <ng-select [items]="activities" [closeOnSelect]="false" bindLabel="title" bindValue="id" formControlName="activities"
        [multiple]="true">
      </ng-select>

      <print-error [control]="formGroup.get('activities')"></print-error>

    </div>


          <div class="col-lg-3">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary custom-btn-margin">
              <i class="fa fa-add"></i>
            </button>
          </div>
        </div>

</form>


</div>
</div>


<!-- <hr/> -->

<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Area</th>
      <th scope="col">Activities</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of isp3s; let i = index">

      <th scope="row" >{{i+1}}</th>
      <td>{{item.area}}</td>
      <td>{{getActivities(item.activities)}}</td>
    </tr>

  </tbody>
</table>
