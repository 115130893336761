<!-- <div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Evaluation and Termination Services</h4>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
                  <li class="breadcrumb-item active">Evaluation</li>
              </ol>
          </div>
      </div>
  </div>
</div> -->
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">

        <!-- <button type="button" *ngIf="addValue" class="col-lg-2 mb-1 btn btn-primary btn-sm" (click)="addObjectClicked()">Add New</button>


        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

            <tr>
              <td  [appSort]="objs" data-order="desc" data-name="client_evaluation_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="client_evaluation_results" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Results</td>
              <td  [appSort]="objs" data-order="desc" data-name="client_evaluation_otder" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Evaluation Otder</td>
              <td  [appSort]="objs" data-order="desc" data-name="client_satisfaction_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Satisfaction Assessment Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="statisfation_level" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Satisfaction Level</td>
              <td  [appSort]="objs" data-order="desc" data-name="client_satisfaction_otder" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Satisfaction Otder</td>
              <td  [appSort]="objs" data-order="desc" data-name="client_satisfaction_otder" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Discharge Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="client_discharge_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Discharge Reasons</td>
              <td  [appSort]="objs" data-order="desc" data-name="reasons_for_discharge" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Discharge Otder</td>
              <td  [appSort]="objs" data-order="desc" data-name="discharge_other_reasons" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</td>

<td scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <td>{{item.client_evaluation_date | date}}</td>
              <td>{{item.client_evaluation_results}}</td>
              <td>{{item.client_evaluation_other}}</td>
              <td>{{item.client_satisfaction_date | date}}</td>
              <td>{{item.statisfation_level}}</td>
              <td>{{item.client_satisfaction_other}}</td>
              <td>{{item.client_discharge_date | date}}</td>
              <td>{{item.reasons_for_discharge}}</td>
              <td>{{item.discharge_other_reasons}}</td>

              <td>{{item.remarks}}</td>

              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/> -->

        <form [formGroup]="formGroup"  (ngSubmit)="submit()">


          <p class="header-banner text-center">Evaluation of the client (How did you find the session(s))</p>

          <div class="row">

        <div class="mb-3 col-lg-6">
          <label  class="form-label">Client Evaluation Date</label>
          <input type="date" formControlName="client_evaluation_date" class="form-control" placeholder="">
        </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Client Evaluation Results</label>
            <ng-select [items]="clientEvaluationResults"
              bindLabel="displayValue"
              bindValue="value"
              formControlName="client_evaluation_results"
              [multiple]="true">
            </ng-select>
            <small>{{formGroup.get('client_evaluation_results').value}}</small>
          </div>

          <div class="mb-3 col-lg-12">
            <label  class="form-label">Client Evaulation Other</label>
            <input type="text" formControlName="client_evaluation_other" class="form-control" placeholder="">
          </div>
          </div>


          <p class="header-banner text-center">Satisfaction of the client</p>

          <div class="row">
            <div class="mb-3 col-lg-6">
              <label  class="form-label">Satisfaction Assessment Date</label>
              <input type="date" formControlName="client_satisfaction_date" class="form-control" placeholder="">
            </div>

              <div class="mb-3 col-lg-6">
                <label  class="form-label">Satisfaction Level</label>
                <ng-select [items]="satisfactionLevel"
                  bindLabel="displayValue"
                  bindValue="value"
                  formControlName="statisfation_level"
                >
              </ng-select>

              </div>

              <div class="mb-3 col-lg-12">
                <label  class="form-label">Client Satisfaction Other</label>
                <input type="text" formControlName="client_satisfaction_other" class="form-control" placeholder="">
              </div>

              </div>


              <p class="header-banner text-center">Discharge (Reasons of discharge)</p>

              <div class="row">

              <div class="mb-3 col-lg-6">
                <label  class="form-label"> Discharge Date</label>
                <input type="date" formControlName="client_discharge_date" class="form-control" placeholder="">
              </div>

                <div class="mb-3 col-lg-6">
                  <label  class="form-label">Discharge Reason(s)</label>

                  <ng-select [items]="reasonsForDischarge"
                    bindLabel="displayValue"
                    bindValue="value"
                    formControlName="reasons_for_discharge"
                    [multiple]="true">
                  </ng-select>
                  <small>{{formGroup.get('reasons_for_discharge').value}}</small>
                </div>

                <div class="mb-3 col-lg-12">
                  <label  class="form-label">Client Discharge Other</label>
                  <input type="text" formControlName="discharge_other_reasons" class="form-control" placeholder="">
                </div>


                <!-- <div class="mb-3 col-lg-4">
            <label  class="form-label">Remarks</label>
            <input type="text" formControlName="remarks" class="form-control" placeholder="">
          </div> -->

        </div>
        <hr>
          <div class="row">
            <div class="col-lg-2">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Save
              </button>
          </div>

          </div>

        </form>


      </div>
  </div>
</section>
