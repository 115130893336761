<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Grants</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Grants</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="row">

        <div class="col-lg-4 col-lg-4 col-sm-12" *ngIf="apiService.hasRole(['admin', 'dataentry'])">
          <!-- small box -->
          <div (click)="route('/grant/create')">
          <div class="small-box" >
              <div class="inner">
                  <h3>New Grant </h3>

                  <p>You can register a new grant in this section</p>
              </div>
              <div class="icon">
                  <i class="fas fa-arrow-circle-right"></i>
              </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-6">
        <!-- small box -->
        <div (click)="route('/grant/list')" *ngIf="apiService.hasRole(['admin', 'validator'])">
        <div class="small-box" >
            <div class="inner">
                <h3>List of Grants</h3>

                <p>You can access grant list</p>
            </div>
            <div class="icon">
                <i class="fas fa-arrow-circle-right"></i>
            </div>

        </div>
      </div>
      </div>

<!--

    <div class="col-lg-4 col-lg-4 col-sm-12">
      <div (click)="route('/tna/cleared')" *ngIf="apiService.hasRole(['admin', 'validator', 'internal_viewer', 'external_viewer'])">
      <div class="small-box" >
          <div class="inner">
              <h3>Cleared</h3>

              <p>You can see the cleared assessments in this section</p>
          </div>
          <div class="icon">
              <i class="fas fa-arrow-circle-right"></i>
          </div>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-lg-4 col-sm-12">
    <div (click)="route('/tna/rejected')" *ngIf="apiService.hasRole(['admin', 'dataentry'])">
    <div class="small-box" >
        <div class="inner">
            <h3>Rejected</h3>

            <p>You can find the rejected assessments with this option</p>
        </div>
        <div class="icon">
            <i class="fas fa-arrow-circle-right"></i>
        </div>
    </div>
  </div>
</div>


<div class="col-lg-4 col-lg-4 col-sm-12">
  <div (click)="route('/tna/archived')" *ngIf="apiService.hasRole(['admin', 'validator'])">
  <div class="small-box" >
      <div class="inner">
          <h3>Archived</h3>

          <p>You can find the archived assessments with this option</p>
      </div>
      <div class="icon">
          <i class="fas fa-arrow-circle-right"></i>
      </div>
  </div>
</div>
</div>

<div class="col-lg-4 col-6">
  <div (click)="route('/tna/export')" *ngIf="apiService.hasRole(['admin'])">
  <div class="small-box" >
      <div class="inner">
          <h3>Extract</h3>

          <p>You can extract partial or full data in Excel with option</p>
      </div>
      <div class="icon">
          <i class="fas fa-arrow-circle-right"></i>
      </div>
  </div>
</div> -->
<!-- </div> -->

</div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
