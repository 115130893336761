import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-beneficiary-referral',
  templateUrl: './beneficiary-referral.component.html',
  styleUrls: ['./beneficiary-referral.component.scss']
})
export class BeneficiaryReferralComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  @Input() beneficiaryId: number;
  addValue: any = true;
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  beneficiary: any;
  activities: any;

  services = [
    { value: "Alternative care", label: "Alternative care" },
    { value: "Security (e.g. safe shelter)", label: "Security (e.g. safe shelter)" },
    { value: "Education (formal)", label: "Education (formal)" },
    { value: "Non-formal education", label: "Non-formal education" },
    { value: "S/GBV specialized care", label: "S/GBV specialized care" },
    { value: "Family tracing and reunification", label: "Family tracing and reunification" },
    { value: "Basic psychosocial support", label: "Basic psychosocial support" },
    { value: "Food", label: "Food" },
    { value: "Non-food items", label: "Non-food items" },
    { value: "Cash assistance", label: "Cash assistance" },
    { value: "Livelihoods", label: "Livelihoods" },
    { value: "General Health & Medical support", label: "General Health & Medical support" },
    { value: "Nutrition", label: "Nutrition" },
    { value: "Legal support", label: "Legal support" },
    { value: "Documentation", label: "Documentation" },
    { value: "Services for beneficiary with disabilities", label: "Services for beneficiary with disabilities" },
    { value: "Shelter", label: "Shelter" },
    { value: "WASH", label: "WASH" },
    { value: "Relocation", label: "Relocation" },
    { value: "Sexual & reproductive health", label: "Sexual & reproductive health" },
    { value: "Protection issues", label: "Protection issues" },
    { value: "Child Protection issues", label: "Child Protection issues" },
    { value: "Other", label: "Other" }
]
  referral: any;
  selectedServices: any;
  @ViewChild('content') content:ElementRef;



  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder,
      private cdr: ChangeDetectorRef){}

  ngOnInit() {


    this.formGroup = this.formBuilder.group({
      id: ['',],
      grant_id: ['', []],
      beneficiary_id: ['', []],
      is_there_concern_before_referral: ['', [Validators.required]],
      concern_before_referral: ['', []],
      is_there_need_for_further_support: ['', [Validators.required]],
      read_and_understood_the_questions: ['', [Validators.required]],
      case_no: ['', []],
      referral_date: ['', [Validators.required]],
      referral_type: ['', [Validators.required]],
      has_customer_consent: ['', [Validators.required]],
      why_client_not_provided_consent: ['', []],

      referredby_name: ['', []],
      referredby_agency: ['', []],
      referredby_position: ['', []],
      referredby_phone: ['', []],
      referredby_email: ['', []],
      referredby_address: ['', []],


      referredto_name: ['', []],
      referredto_agency: ['', []],
      referredto_position: ['', []],
      referredto_phone: ['', []],
      referredto_email: ['', []],
      referredto_address: ['', []],


      reasons_for_referral: ['', []],
      mental_health_alert: ['', []],
      mental_health_description: ['', []],
      type_services_requested: ['', []],
      type_services_requested_other: ['', []],
      expected_outcome_of_services: ['', []],

      service_provider_accept_referral: ['', []],
      why_referral_not_accepted: ['', []],


      remarks: ['', []],
      // readUnderstood: ['', [Validators.required]]

      client_name: ['', []],
      father_or_husband_name: ['', []],
      age: ['', []],
      gender: ['', []],
      client_phone: ['', []],
      address: ['', []],
      dob: ['',[]],
      nid: ['',[]],
      language: ['',[]],
      marital_status: ['', []],

      relation: ['',[]],

    });


    this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
      this.beneficiary = response;
      this.formGroup.controls['client_name'].setValue(this.beneficiary?.client_name);
      this.formGroup.controls['father_or_husband_name'].setValue(this.beneficiary?.father_or_husband_name);
      this.formGroup.controls['age'].setValue(this.beneficiary?.age);
      this.formGroup.controls['gender'].setValue(this.beneficiary?.gender);
      this.formGroup.controls['client_phone'].setValue(this.beneficiary?.client_phone);
      this.formGroup.controls['marital_status'].setValue(this.beneficiary?.marital_status);
    });




      this.loadData();
  }

  loadData(){
    this.apiService.getBeneficiaryReferral(this.beneficiaryId).subscribe(response => {
      this.referral = response;
      this.formGroup.controls['id'].setValue(this.referral?.id);
      this.formGroup.controls['beneficiary_id'].setValue(this.referral?.beneficiary_id);

      this.formGroup.controls['is_there_concern_before_referral'].setValue(this.referral?.is_there_concern_before_referral);
      this.formGroup.controls['concern_before_referral'].setValue(this.referral?.concern_before_referral);
      this.formGroup.controls['is_there_need_for_further_support'].setValue(this.referral?.is_there_need_for_further_support);
      this.formGroup.controls['read_and_understood_the_questions'].setValue(this.referral?.read_and_understood_the_questions);
      this.formGroup.controls['case_no'].setValue(this.referral?.case_no);
      this.formGroup.controls['referral_date'].setValue(this.referral?.referral_date);
      this.formGroup.controls['referral_type'].setValue(this.referral?.referral_type);
      this.formGroup.controls['has_customer_consent'].setValue(this.referral?.has_customer_consent);

      this.formGroup.controls['referredby_name'].setValue(this.referral?.referredby_name);
      this.formGroup.controls['referredby_agency'].setValue(this.referral?.referredby_agency);
      this.formGroup.controls['referredby_position'].setValue(this.referral?.referredby_position);
      this.formGroup.controls['referredby_phone'].setValue(this.referral?.referredby_phone);
      this.formGroup.controls['referredby_email'].setValue(this.referral?.referredby_email);
      this.formGroup.controls['referredby_address'].setValue(this.referral?.referredby_address);

      this.formGroup.controls['referredto_name'].setValue(this.referral?.referredto_name);
      this.formGroup.controls['referredto_agency'].setValue(this.referral?.referredto_agency);
      this.formGroup.controls['referredto_position'].setValue(this.referral?.referredto_position);
      this.formGroup.controls['referredto_phone'].setValue(this.referral?.referredto_phone);
      this.formGroup.controls['referredto_email'].setValue(this.referral?.referredto_email);
      this.formGroup.controls['referredto_address'].setValue(this.referral?.referredto_address);


      this.formGroup.controls['nid'].setValue(this.beneficiary?.nid );
      this.formGroup.controls['dob'].setValue(this.beneficiary?.dob);
      this.formGroup.controls['age'].setValue(this.beneficiary?.age);
      this.formGroup.controls['address'].setValue(this.beneficiary?.address);
      this.formGroup.controls['language'].setValue(this.beneficiary?.language);


      this.formGroup.controls['reasons_for_referral'].setValue(this.referral?.reasons_for_referral);
      this.formGroup.controls['mental_health_alert'].setValue(this.referral?.mental_health_alert);


      this.formGroup.controls['mental_health_description'].setValue(this.referral?.mental_health_description);
      this.formGroup.controls['type_services_requested_other'].setValue(this.referral?.type_services_requested_other);
      this.formGroup.controls['expected_outcome_of_services'].setValue(this.referral?.expected_outcome_of_services);
      this.formGroup.controls['service_provider_accept_referral'].setValue(this.referral?.service_provider_accept_referral);
      this.formGroup.controls['why_referral_not_accepted'].setValue(this.referral?.why_referral_not_accepted);
      this.formGroup.controls['relation'].setValue(this.referral?.relation);
      this.formGroup.controls['grant_id'].setValue(this.beneficiary?.grant_id);
      this.setServicesFromString();
    });


  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteBeneficiaryReferral(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

    // Function to split comma-separated values and assign to services array
    setServicesFromString(): void {
      if (this.referral?.type_services_requested) {
        this.selectedServices = this.referral.type_services_requested
          .split(',')
          .map(value => ({ value: value.trim(), label: value.trim() }));

        // Assuming this.formGroup is your form group containing the 'type_services_requested' form control
        // this.formGroup.controls['type_services_requested'].setValue(this.services);
      }
    }

    savePDF(){

      const DATA = this.content.nativeElement;
      html2canvas(DATA).then(canvas => {
        let fileWidth = 200;
        let fileHeight = canvas.height * fileWidth / canvas.width;

        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 5, 5, fileWidth, fileHeight)
        PDF.save('referral.pdf');
      });

    }




  confirmReferral(){
    this.apiService.confirmReferral(this.referral?.id).subscribe(response => {
      if(response){
        this.toastrService.info("Case Number Generated!");
        this.loadData();
      }
    })
  }

  edit(){
    this.toastrService.info("Edit mode is enabled!")
  }

  details(item:any)
  {
    this.router.navigate(['/beneficiaries/referrals/details/'+ item.id]);
  }



  submit(){

    // this.isLoading = true;
    console.log("data", this.beneficiary);
    this.formGroup.controls['grant_id'].setValue(this.beneficiary.grant_id);
    this.formGroup.controls['beneficiary_id'].setValue(this.beneficiary.id);

    this.apiService.createBeneficiaryReferral(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}
