import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-psychoeducation-details',
  templateUrl: './psychoeducation-details.component.html',
  styleUrls: ['./psychoeducation-details.component.scss']
})
export class PsychoeducationDetailsComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  obj: any;
  id: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
    ){

      this.activedRoute.params.subscribe((params) => {
        this.id = params['id'];
        this.apiService.getPsychoeducationId(this.id).subscribe((response) => {
          this.obj = response;
        });


      });}

  ngOnInit() {
  }

  }
