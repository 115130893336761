import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KoboService {

  private apiUrl = 'https://apr-actionagainsthunger.org/api';

  constructor(private http: HttpClient) { }

  getKoboData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/kobo-data`);
  }
  processBeneficiaryForm(item: any): Observable<any> {
    console.log(item);
    return this.http.post(`${this.apiUrl}/save-data`, item);
  }
}
