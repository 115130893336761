import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AppService } from '@services/app.service';
import { Route, Router } from '@angular/router';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  constructor(private appService:AppService, private route: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token');
    if(token){
        return next.handle(request.clone({ setHeaders: { authorization: `Bearer ${token}` }})).pipe(tap(() => {},
        (err: any) => {
          if (err.status !== 401) {
            return;
          }
          // this.appService.logout();
          this.route.navigate(['/401']);
        }
      ));
    }

    return next.handle(request);

  }
}
