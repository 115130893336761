import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-dtb-approved-list',
  templateUrl: './dtb-approved-list.component.html',
  styleUrls: ['./dtb-approved-list.component.scss']
})
export class DtbApprovedListComponent implements OnInit, AfterViewInit {
  public formGroup: UntypedFormGroup;
  public isLoading: boolean = false;
  response: any;
  objs: any;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.apiService.getAllValidatedData().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
      this.initializeDataTable(); // Initialize DataTable after data load
    });
  }

  ngAfterViewInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    // Destroy existing DataTable instance if exists
    if ($.fn.DataTable.isDataTable('#approvedTable')) {
      $('#approvedTable').DataTable().destroy();
    }

    // Initialize DataTable
    $(document).ready(() => {
      $('#approvedTable').DataTable({
        paging: true,
        searching: true,
        ordering: true,
        info: true,
        lengthChange: true,
        autoWidth: false,
        responsive: true,
        columnDefs: [
          { targets: 'no-sort', orderable: false }  // Disable sorting on the options column
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search grants..."
        }
      });

      // Attach search input functionality to DataTable
      $('#searchInput').on('keyup', function () {
        $('#approvedTable').DataTable().search($(this).val()).draw();
      });
    });
  }

  clickLink(url: string) {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname + location.search;

    this.apiService.getPaginationItems(path).subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
      this.initializeDataTable();  // Reinitialize DataTable on data change
    });
  }

  download(item: any) {
    this.apiService.exportToExcel(this.response?.data, item.entryyear + "-" + item.entrymonth + "-dtb");
  }

  finalize(item: any) {
    if (window.confirm("Are you sure to finalize?")) {
      this.apiService.finalizeDataValidation(item.id).subscribe(response => {
        this.toastrService.success("Database has been validated");
        this.loadData();
      }, () => {
        this.toastrService.error("Operation Failed!");
      });
    }
  }

  reject(item: any) {
    if (window.confirm("Are you sure to reject?")) {
      this.apiService.rejectDataValidation(item.id).subscribe(response => {
        this.toastrService.success("Rejected");
        this.loadData();
      }, () => {
        this.toastrService.error("Operation Failed!");
      });
    }
  }

  resend(item: any) {
    if (window.confirm("Are you sure to resend?")) {
      this.apiService.resendDataValidation(item.id).subscribe(response => {
        this.toastrService.success("Resent");
        this.loadData();
      }, () => {
        this.toastrService.error("Operation Failed!");
      });
    }
  }
}
