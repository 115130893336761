<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Kit Distribution Services </h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
                  <li class="breadcrumb-item active">Kit Distributions </li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <button type="button" *ngIf="addValue" class="col-lg-1 mb-1 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add New</button>


        <table class="table table-sm  table-responsive-sm table-bordered table-responsive-md" *ngIf="!addObj">
            <tr>
              <td  [appSort]="objs" data-order="desc" data-name="distribution_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;stribution Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="kit_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Kit Type</td>
              <td  [appSort]="objs" data-order="desc" data-name="no_kits" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;No of Kits</td>
              <td  [appSort]="objs" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Remarks</td>

<td scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.distribution_date | date}}</td>
              <td>{{item.kit_type}}</td>
              <td>{{item.no_kits}}</td>

              <td>{{item.remarks}}</td>

              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">
          <div class="row">


          <div class="mb-3 col-lg-2">
            <label  class="form-label">Distribution Date</label>
            <input type="date" formControlName="distribution_date" class="form-control" placeholder="">
          </div>

          <!-- <div class="mb-3 col-lg-6">
            <label  class="form-label">Kit Type</label>
            <select class="form-control" formControlName="kit_type" >
              <option value="IEHK">IEHK</option>
              <option value="TESK">TESK</option>
              <option value="SAM">SAM</option>
            </select>
          </div> -->

          <div class="mb-3 col-lg-2">
            <label  class="form-label">Kit Type</label>
            <input type="text" formControlName="kit_type" class="form-control" placeholder="">
          </div>




          <div class="mb-3 col-lg-2">
            <label  class="form-label">No of Kits</label>
            <input type="number" formControlName="no_kits" class="form-control" placeholder="">
          </div>


          <div class="mb-3 col-lg-6">
            <label  class="form-label">Remarks</label>
            <textarea type="text" formControlName="remarks" class="form-control" placeholder=""></textarea>
          </div>


        </div>
          <div class="row">
            <div class="col-lg-2">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          <div class="col-6">

        </div>

          </div>

        </form>


      </div>
  </div>
</section>
