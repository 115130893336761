

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">


        <p class="header-banner text-center">{{pageTitle}}</p>

        <!-- <button type="button" *ngIf="addValue" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add New</button> -->
        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

            <tr>
              <td  [appSort]="objs" data-order="desc" data-name="activity" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Session ID</td>

              <!--
                            <td  [appSort]="objs" data-order="desc" data-name="activity_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Activity Type</td>
                            <td  [appSort]="objs" data-order="desc" data-name="activity_category" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Activity Category</td>
                            <td  [appSort]="objs" data-order="desc" data-name="session_id" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Session ID</td> -->
              <td  [appSort]="objs" data-order="desc" data-name="description" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="session_topic" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>&nbsp;Session Topic</td>

              <td scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs; let i = index">
              <td>Session {{i+1}}</td>
              <!-- <td>{{item.activity.title}}</td>
              <td>{{item.activity_type}}</td>
              <td>{{item.activity_category}}</td>
              <td>{{item.id}}</td>
              <td>{{item.session_date}}</td> -->
              <td>{{item.session_date}}</td>
              <td>{{item.session_topic}}</td>
              <td>

                <div class="btn-group btn-group-sm" role="group" >
                  <button type="button" class="btn btn-danger btn-sm" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success btn-sm" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <p class="header-banner text-center"> Total # of Session: {{objs?.length}}</p>


        <hr/>


        <form [formGroup]="formGroup" (ngSubmit)="submit()">
          <div class="row">

          <!-- <div class="mb-3 col-lg-4">
            <label  class="form-label">Grant Activity</label>

            <ng-select [items]="activities"
              bindLabel="title"
              bindValue="id"
              formControlName="grant_activity_id"
            >
           </ng-select>
          </div> -->

          <!-- <div class="mb-3 col-lg-4">
            <label  class="form-label">Activity Type</label>

            <input type="text" formControlName="activity_type" class="form-control form-control-sm" placeholder="" readonly>
          </div> -->

                <!-- <select class="form-control form-control-sm" formControlName="activity_type" >
              <option value="GROUP">GROUP</option>
              <option value="INDIVIDUAL">INDIVIDUAL</option>
            </select> -->

          <!-- <div class="mb-3 col-lg-4">
            <label  class="form-label">Activity Category</label>
            <input type="text" formControlName="activity_category" class="form-control form-control-sm" placeholder="" readonly>

          </div> -->

                    <!-- <select class="form-control form-control-sm" formControlName="activity_category" >
              <option value="Adult Psychosocial Support">Adult Psychosocial Support</option>
              <option value="Child Psychosocial Support / Adolescent Wellbeing">Child Psychosocial Support / Adolescent Wellbeing </option>
              <option value="Parenting Skills">Parenting Skills  </option>
              <option value="Child Care Practices (Stimulation Sessions)">Child Care Practices (Stimulation Sessions) </option>
              <option value="Other">Other </option>
            </select> -->

          <!-- <div class="mb-3 col-lg-4">
            <label  class="form-label">Session ID</label>
            <input type="type" formControlName="session_id" class="form-control form-control-sm" placeholder="">
          </div> -->


          <div class="mb-3 col-lg-6">
            <label  class="form-label">Session Date</label>
            <input type="date" formControlName="session_date" class="form-control form-control-sm" placeholder="">
          </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Session Topic</label>
            <input type="text" formControlName="session_topic" class="form-control form-control-sm" placeholder="">
          </div>

          <!-- <div class="mb-3 col-lg-12">
            <label  class="form-label">Session Description</label>
            <input type="text" formControlName="description" class="form-control form-control-sm" placeholder=""/>
          </div> -->


        </div>
          <div class="row">
            <div class="col-lg-4">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary btn-sm">
                Save
              </button>
          </div>

          <div class="col-6">

        </div>

          </div>

        </form>


      </div>
  </div>
</section>
