<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark"><strong>{{category.charAt(0).toUpperCase() + category.slice(1)}}</strong> Registration
          for Beneficiary</h4>
      </div>
      <!-- /.col -->
      <!-- <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Search Beneficiaries</li>
              </ol>
          </div> -->
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-lg-3">
            <label class="form-label">Search By:</label>
            <select class="form-control" formControlName="category">
              <option value="name" selected>Name</option>
              <option value="phone">Phone</option>
              <option value="code">Beneficiary Code</option>
            </select>
          </div>

          <div class="col-lg-7">
            <label class="form-label">Value:</label>
            <input type="type" formControlName="value" class="form-control" placeholder="type here...">
          </div>
          <div class="col-lg-2 mt-1">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary mt-4">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>
      <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="objs">
        <thead>
          <tr>
            <!-- <th scope="col">#</th> -->
            <th scope="col">Code</th>
            <th scope="col">Name</th>
            <th scope="col">Age</th>
            <th scope="col">Gender</th>
            <th scope="col">Eduction</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of objs">
            <!-- <th scope="row">{{item.id}}</th> -->
            <td>{{item.beneficiary_code}}</td>
            <td>{{item.client_name}} / {{item.father_or_husband_name}} </td>
            <td>{{item.age}}</td>
            <td>{{item.gender}}</td>
            <td>{{item.literacy_level_of_bnf}}</td>

            <td>

              <div class="btn-group" role="group">
                <!-- <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button> -->
                <!-- <button type="button" class="btn btn-warning" (click)="details(item)">Details</button> -->
                <button type="button" class="btn btn-primary" (click)="addServices(item)">Register
                  {{category.charAt(0).toUpperCase() + category.slice(1)}}</button>

              </div>
            </td>
          </tr>
        </tbody>
      </table>



    </div>
  </div>
</section>
