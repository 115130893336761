import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dtb-submission',
  templateUrl: './dtb-submission.component.html',
  styleUrls: ['./dtb-submission.component.scss']
})
export class DtbSubmissionComponent implements OnInit {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading: boolean = false;
  objs: any;
  years: number[] = [];
  months: string[] = [];
  selectedYear: number | null = null;
  errorMessage: string = '';


  addObj: boolean = false;
  editObj: any;
  grants: any;
  provinces: any;
  districts: any;
  users: any;
  dtbSubmissions: any[] = [];


  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['', []],
      grant_id: ['', [Validators.required]],
      year: ['', [Validators.required]],
      province_id: ['', [Validators.required]],
      month: ['', [Validators.required]],
      manager: ['', [Validators.required]],
      dtb_name: ['', [Validators.required]],
      remarks: ['', []],
    });

    this.loadData();
  }


  onYearChange(year: number): void {
    this.selectedYear = year;
    if (year) {
      this.apiService.getMonthsByYear(year).subscribe(
        (data: string[]) => {
          console.log('Received months:', data);
          this.months = data;
        },
        (error) => {
          this.errorMessage = error;
          console.error('Error loading months:', error);
        }
      );
    } else {
      this.months = [];
    }
  }

  loadYears(): void {
    this.apiService.getDataYears({}).subscribe(
      (data: any) => {
        console.log('Received data:', data);
        if (Array.isArray(data)) {
          this.years = data;
        } else {
          console.error('Expected an array but got:', data);
        }
      },
      (error) => {
        this.errorMessage = error;
        console.error('Error loading months:', error);
      }
    );
  }


  loadData() {
    this.apiService.getAllDataValidations().subscribe((response) => {
      this.objs = response;
      this.dtbSubmissions = response.data;
    });
    this.apiService.getAllGrants().subscribe((response) => {
      this.grants = response.data.data;
    });

    this.loadYears();
    this.apiService.getAllUsers().subscribe((response) => {
      this.users = response.data.data;
    });
  }

  loadGrantProvinces(value: any) {
    console.log(value);

    this.apiService.getGrantProvinces(value.id).subscribe((response) => {
      this.provinces = response;
    });
  }

  loadAdminOrgsChildern(value: any) {
    this.apiService.getDistricts(value.id).subscribe((response) => {
      this.districts = response;
    });
  }

  addObjectClicked() {
    this.addObj = !this.addObj;
  }

  delete(item: any) {
    if (window.confirm('Are you sure to delete?')) {
      this.apiService.deleteDataValidations(item.id).subscribe(
        (response) => {
          this.toastrService.success('Deletion Succeeded');
          this.loadData();
        },
        (e) => {
          this.toastrService.error('Deletion Failed!');
        }
      );
    }
  }

  edit(item: any) {
    if (window.confirm('Are you sure to edit?')) {
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['grant_id'].setValue(item.grant_id);
      this.formGroup.controls['year'].setValue(item.year);
      this.formGroup.controls['province_id'].setValue(item.province_id);
      this.formGroup.controls['month'].setValue(item.month);
      this.formGroup.controls['manager'].setValue(item.manager);
      this.formGroup.controls['dtb_name'].setValue(item.dtb_name);
      this.formGroup.controls['remarks'].setValue(item.remarks);

      this.editObj = item;
    }
  }

  details(item: any) {
    this.router.navigate(['/beneficiaries/details/' + item.id]);
  }

  addServices(item: any) {
    this.router.navigate(['/beneficiaries/services/' + item.id]);
  }

  submit() {
    this.apiService.createDataValidations(this.formGroup.value).subscribe(
      (response) => {
        this.isLoading = false;
        console.log(response);
        this.toastrService.success('Registration succeeded');
        this.formGroup.reset();
        this.addObj = false;
        this.loadData();
      },
      (e) => {
        this.isLoading = false;
        if (e.status === 409) {
          this.toastrService.error('Record already exists');
        } else {
          this.toastrService.error('Registration failed');
        }
      }
    );
  }
}
