<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Trainings for {{participant?.fullname}}</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <!-- <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li> -->
                  <li class="breadcrumb-item active">Trainings </li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">


        <!-- <div class="row">
          <div class="col-lg-4">
            <button type="button" class="btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button>

          </div>
          <div class="col-lg-4"></div>
          <div class=" input-group col-lg-4" *ngIf="!addObj">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
            </div>
            <input class="input" (input)="filter($event.target.value)" class="form-control"
              placeholder="Search...">
          </div>
        </div> -->



        <hr/>

        <div>
          <ul class="nav nav-tabs">
            <li class="nav-item" *ngFor="let tab of tabs; let i = index">
              <a
                class="nav-link"
                href="javascript:void(0)"
                [class.active]="i === activeTab"
                (click)="setActiveTab(i)"
              >
                {{ tab.title }}
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div
              class="tab-pane"
              *ngFor="let tab of tabs; let i = index"
              [class.active]="i === activeTab"
              style="margin: 10px;"
            >
            <div *ngIf="tab.type == 'Participant'" >

              <p class="header-banner">Participant Information</p>
<!--
              <div>
                <p>Full Name: {{participant?.fullname}} {{participant?.father_or_husband_name}} {{participant?.organization}} {{participant?.job_title}}</p>
                <p>Phone: {{participant?.phone}}</p>
                <p>E-Mail: {{participant?.email}}</p>

              </div> -->



              <div class="row">
                <div class="col-lg-6">

                <div class="card">
                  <div class="card-body">
                      <ul class="list-group">
                          <li class="list-group-item"><strong>Full Name:</strong> {{participant?.fullname}}</li>
                          <li class="list-group-item"><strong>Father or Husband Name:</strong> {{participant?.father_or_husband_name}}</li>
                          <li class="list-group-item"><strong>Organization:</strong> {{participant?.organization}}</li>
                          <li class="list-group-item"><strong>Job Title:</strong> {{participant?.job_title}} </li>
                          <li class="list-group-item"><strong>Email:</strong> {{participant?.email}}</li>
                          <li class="list-group-item"><strong>Phone:</strong> {{participant?.phone}}</li>
                          <!-- Add more details as needed -->
                      </ul>
                  </div>
              </div>

                </div>
              </div>
              <!-- <table class="table table-bordered">
                <tr>
                  <td>Full Name: </td>
                  <td>{{participant?.fullname}}</td>
                </tr>

                <tr>
                  <td>Father or Husband Name: </td>
                  <td>{{participant?.father_or_husband_name}}</td>
                </tr>

                <tr>
                  <td>Organization: </td>
                  <td>{{participant?.organization}}</td>
                </tr>

                <tr>
                  <td>Job Title: </td>
                  <td>{{participant?.job_title}}</td>
                </tr>

                <tr>
                  <td>Phone: </td>
                  <td>{{participant?.phone}}</td>
                </tr>

                <tr>
                  <td>E-Mail: </td>
                  <td>{{participant?.email}}</td>
                </tr>

                <tr>
                  <td>Regarks: </td>
                  <td>{{participant?.remarks}}</td>
                </tr>
              </table>
 -->

              <!-- <p class="header-banner">Participant Trainings</p>
              <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">
                <tr>
                  <td  [appSort]="objs" data-order="desc" data-name="training_topic" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Topic</td>
                  <td  [appSort]="objs" data-order="desc" data-name="training_modality" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Modality</td>
                  <td  [appSort]="objs" data-order="desc" data-name="training_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Type</td>
                  <td  [appSort]="objs" data-order="desc" data-name="start_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Start Date</td>
                  <td  [appSort]="objs" data-order="desc" data-name="end_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>End Date</td>
                  <td  [appSort]="objs" data-order="desc" data-name="facilitator_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Facilitator Name</td>
                  <td  [appSort]="objs" data-order="desc" data-name="facilitator_position" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Facilitator Position</td>

                  <td  [appSort]="objs" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</td>
                   <td scope="col">Action</td>
                </tr>
              <tbody class="table table-sm -group-divider">
                <tr *ngFor="let item of objs">
                  <td>{{item.training_topic}}</td>
                  <td>{{item.training_modality}}</td>
                  <td>{{item.training_type}}</td>
                  <td>{{item.start_date | date}}</td>
                  <td>{{item.end_date |date}}</td>
                  <td>{{item.facilitator_name}}</td>
                  <td>{{item.facilitator_position}}</td>
                  <td>{{item.remarks}}</td>

                  <td>

                    <div class="btn-group" role="group" >
                      <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                      <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table> -->


            </div>

            <div *ngIf="tab.type == 'Topic'">
              <app-training-registration-form [participantId]="participantId" [pageNumber]="i"></app-training-registration-form>
            </div>

<!--
            <div *ngIf="tab.type == 'Evaluation'" >
                <app-training-stastisfaction-evaluation [partcipantId]="participantId"></app-training-stastisfaction-evaluation>
            </div> -->

            </div>
          </div>
        </div>

      </div>
  </div>
</section>
