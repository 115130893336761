import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-grant-status',
  templateUrl: './grant-status.component.html',
  styleUrls: ['./grant-status.component.scss']
})
export class GrantStatusComponent implements OnInit, AfterViewInit {
  showDesc = true;
  showAsc = false;

  addObj: boolean = false;
  editObj: any;
  provinces: any;
  departments: any;
  response: any;
  objs: any;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.initializeDataTable();
  }

  loadData() {
    this.apiService.getGrants().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
      this.initializeDataTable(); // Initialize DataTable after data load
    });
  }

  initializeDataTable() {
    // Destroy existing DataTable instance if exists
    if ($.fn.DataTable.isDataTable('#grantsTable')) {
      $('#grantsTable').DataTable().destroy();
    }
    // Initialize DataTable
    $(document).ready(() => {
      $('#grantsTable').DataTable({
        paging: true,
        searching: true,
        ordering: true,
        info: true,
        lengthChange: true,
        autoWidth: false,
        responsive: true,
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search grants..."
        }
      });
    });
  }

  clickLink(url: string) {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname + location.search;

    this.apiService.getPaginationItems(path).subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    })
  }

  delete(item: any) {
    if (window.confirm("Are you sure to delete?")) {
      this.apiService.deleteGrant(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any) {
    this.router.navigate(['/grant/update/' + item.id]);
  }

  configure(item: any) {
    this.router.navigate(['/grant/configure/' + item.id]);
  }

  details(item: any) {
    this.router.navigate(['/grant/details/' + item.id]);
  }
}
