import { Component, Input } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-participants',
  templateUrl: './training-participants.component.html',
  styleUrls: ['./training-participants.component.scss']
})
export class TrainingParticipantsComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  formGroup: any;
  isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  districts: any;
  provinces: any;
  grants: any = [];
  response: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder
      ){
    }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',],
      grant_id: ['', [Validators.required]],
      province_id: ['',[Validators.required]],
      district_id: ['',[Validators.required]],
      location: ['',[Validators.required]],
      fullname: ['',[Validators.required]],
      father_or_husband_name: ['',[Validators.required]],
      organization: ['',[Validators.required]],
      job_title: ['',[Validators.required]],
      gender: ['',[Validators.required]],
      phone: ['',[Validators.required]],
      email: ['',[Validators.required]],
      remarks: ['',[]],
    });

      this.loadData();
  }

  loadData(){
    this.apiService.getAllTrainingParticipants().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });

    this.apiService.getAllGrants().subscribe(response => {
      this.grants = response.data.data;
    });
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    })
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteTrainingParticipant(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['training_id'].setValue(item.training.id);
      this.formGroup.controls['pre_score'].setValue(item.pre_score);
      this.formGroup.controls['post_score'].setValue(item.post_score);
      this.formGroup.controls['remarks'].setValue(item.remarks);

      this.editObj = item;
    }
  }



  loadAdminOrgsChildern(value:any){
    this.apiService.getDistricts(value.id).subscribe(response => {
      this.districts = response;
    })
  }

  loadGrantProvinces(value:any){
    this.apiService.getGrantProvinces(value.id).subscribe(response => {
      this.provinces = response;
    });
  }

  submit(){

    // this.formGroup.controls['training_id'].setValue(this.trainingId);
    console.log(this.formGroup.value);

    this.apiService.createTrainingParticipant(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

  trainings(item){
    this.router.navigate(['/myspace/trainings/' + item.id ]);
  }

  filter(text:any)
  {
      text = text.toLowerCase();
      this.objs =  this.objs.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.objs = this.objs;
      }
  }

  addEvaluation(){
    this.router.navigate(['/myspace/trianing-evaluations' ]);
  }

}

