import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-beneficiary-tab-services',
  templateUrl: './beneficiary-tab-services.component.html',
  styleUrls: ['./beneficiary-tab-services.component.scss']
})
export class BeneficiaryTabServicesComponent {

  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default


  id: any;
  currentStep = 1;
  beneficiary: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder : FormBuilder,
      ) {

        this.activedRoute.params.subscribe((params) => {
          this.id = params['id'];
          // console.log('id', this.id);
          this.apiService.getBeneficiary(this.id).subscribe((response) => {
            this.beneficiary = response;
            console.log(this.beneficiary);

          });
        });
      }


  ngOnInit() {
      this.loadData();
  }

  nextStep(): void {
    this.currentStep = this.currentStep+1;
  }

  prevStep(): void {
    this.currentStep = this.currentStep - 1;
  }

  changeCurrentStep(step:number){
    this.currentStep = step;
  }


  getLength(items:any){
    return items.filter(item => item.status === 'Under_Review').length;
  }

  loadData(){
  }



}

