<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Disaggregations</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Disaggregations</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">

      <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add
        new</button>

      <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

        <tr class="bg-primary">
          <td [appSort]="objs" data-order="desc" data-name="id" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>#
          </td>
          <td [appSort]="objs" data-order="desc" data-name="name" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Name
          </td>

          <td [appSort]="objs" data-order="desc" data-name="level" scope="col"> <i class="fa fa-sort-desc"
            aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
            [hidden]="!showAsc"></i>Level
        </td>
          <td scope="col">Action</td>
        </tr>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of objs; let i = index">
            <th scope="row">{{i+1}}</th>
            <td>{{item.name}}</td>
            <td>{{item.level}}</td>

            <td>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

        <div class="row">
          <div class="col-lg-4">
            <label class="form-label">Name</label>
            <input type="type" formControlName="name" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('name')"></print-error>

          </div>

          <div class="col-lg-4">
            <label class="fw-light">Level</label>
            <select class="form-control" formControlName="type">
              <option value="SESSION">Session</option>
              <option value="Individual">Individual</option>
            </select>
            <print-error [control]="formGroup.get('type')"></print-error>

          </div>
          <div class="col-lg-12 mt-1">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
