import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-disaggregation',
  templateUrl: './disaggregation.component.html',
  styleUrls: ['./disaggregation.component.scss']
})
export class DisaggregationComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router
  ) {}

  ngOnInit() {
      this.formGroup = new UntypedFormGroup({
          id: new UntypedFormControl(null,[]),
          name: new UntypedFormControl(null, Validators.required),
          type: new UntypedFormControl(null, Validators.required),

      });

      this.loadData();
  }

  loadData(){
    this.apiService.getDisaggregations().subscribe(response => {
      // console.log("obj", response);

      this.objs = response;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteDisaggregation(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['name'].setValue(item.name);
      this.formGroup.controls['type'].setValue(item.type);
      this.editObj = item;
    }
  }



  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createDisaggregation(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}

