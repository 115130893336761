import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appDefaultImage]'
})
export class DefaultImageDirective implements OnInit {
  @Input() appDefaultImage: string; // the default image source
  @Input() src: string; // the original image source

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    // check if the original image source is null or empty
    console.log(this.src);

    if (!this.src) {
      // set the default image source as the src attribute
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.appDefaultImage);
    }
    // else {
    //   // create a new image object and try to load the original image source
    //   const image = new Image();
    //   image.src = this.src;
    //   image.onload = () => {
    //     // if the image loads successfully, set the original image source as the src attribute
    //     this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);
    //   };
    //   image.onerror = () => {
    //     // if the image fails to load, set the default image source as the src attribute
    //     this.renderer.setAttribute(this.el.nativeElement, 'src', this.appDefaultImage);
    //   };
    // }
  }
}
