<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Indicator Groups</h4>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Indicator Groups</li>
              </ol>
          </div>
      </div>
  </div>
</div>
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">
        <button type="button" class="col-lg-2 btn btn-primary btn-sm" (click)="addObjectClicked()">Add New</button>
        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">
            <tr class="bg-primary">
              <td scope="col">#</td>
              <td scope="col">Name</td>
              <td scope="col">Code</td>
              <td scope="col">Indicators</td>
              <td scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs; let i = index">
              <th scope="row">{{i+1}}</th>
              <td>{{item.name}}</td>
              <td>{{item.code}}</td>
              <td>{{getName(item.activities)}}</td>
              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-primary" (click)="edit(item)"><i class="fa fa-download"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

          <div class="row">
            <div class="col-lg-4">
              <label  class="form-label">Name</label>
              <input type="type" formControlName="name" class="form-control" placeholder="">
            </div>
            <div class="col-lg-4">
              <label  class="form-label">Code</label>
              <input type="type" formControlName="code" class="form-control" placeholder="">
            </div>
            <div class="col-lg-4">
              <label  class="form-label">Indicators</label>

              <ng-select [items]="activities"
              bindLabel="title"
              bindValue="id"
              formControlName="grant_activity_ids"
              [multiple]="true">
            </ng-select>
            </div>
            <div class="col-lg-12 mt-1">
              <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
                 Submit
              </button>
           </div>
          </div>
        </form>
      </div>
  </div>
</section>
