import { Component, Input } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-registration-form',
  templateUrl: './training-registration-form.component.html',
  styleUrls: ['./training-registration-form.component.scss']
})
export class TrainingRegistrationFormComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  addValue: any = true;
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  activities: any;
  districts: any;
  provinces: any;
  grants: any;
  @Input() participantId: any;
  @Input() pageNumber: any;

  participant: any;
  loadedItem: any;
  formEnabled: boolean = true;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder
      ){
          this.apiService.getTrainingParticipantById(this.participantId).subscribe((response) => {
            this.participant = response;
          });

        // this.activedRoute.params.subscribe((params) => {
        //   this.participantId = params['id'];


        //   this.apiService.getTrainingsByParticipantId(this.participantId).subscribe(response => {
        //     this.objs = response;
        //   });
        // });
    }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',],
      participant_id: [''],
      grant_id: [''],
      province_id: [''],
      district_id: [''],
      location: [''],


      number_of_days: ['',[Validators.required]],
      training_topic: ['',[Validators.required]],
      training_modality: ['',[Validators.required]],
      training_type: ['',[Validators.required]],
      start_date: ['',[Validators.required]],
      end_date: ['',[Validators.required]],
      facilitator_name: ['',[Validators.required]],
      facilitator_position: ['',[Validators.required]],

      informative_level: ['',[]],
      usefulness_level: ['',[]],
      understaning_level: ['',[]],
      relevance_level: ['',[]],
      accessability_level: ['',[]],
      percentage_of_positive_evaluation: ['',[]],

      pre_score: ['',[]],
      post_score: ['',[]],

      remarks: ['',[]],
    });

      this.loadData();
  }

  loadData(){

    // this.apiService.getTrainingsByParticipantId(this.participantId).subscribe(response => {
    //   this.objs = response;
    // });

    // this.apiService.getProvinces().subscribe(response => {
    //   this.provinces = response;
    // });

    this.apiService.getAllGrants().subscribe(response => {
      this.grants  = response;
    })
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteTraining(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['distribution_date'].setValue(item.distribution_date);
      this.formGroup.controls['kit_type'].setValue(item.kit_type);
      this.formGroup.controls['no_kits'].setValue(item.no_kits);
      this.formGroup.controls['remarks'].setValue(item.remarks);
      this.editObj = item;
    }
  }

  filter(text:any)
  {
      text = text.toLowerCase();
      this.objs =  this.objs.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.objs = this.objs;
      }
  }



  loadGrantProvinces(value:any){
    this.apiService.getGrantProvinces(value.id).subscribe(response => {
      this.provinces = response;
    });
  }


  submit(){

    this.formGroup.controls['grant_id'].setValue(this.participant.grant_id);
    this.formGroup.controls['province_id'].setValue(this.participant.province_id);
    this.formGroup.controls['district_id'].setValue(this.participant.district_id);
    this.formGroup.controls['location'].setValue(this.participant.location);
    this.formGroup.controls['participant_id'].setValue(this.participant.id);

    this.apiService.createTrainings(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }


  participants(item){
    this.router.navigate(['/myspace/trainings/participants/' + item.id ]);
  }

  loadAdminOrgsChildern(value:any){
    this.apiService.getDistricts(value.id).subscribe(response => {
      this.districts = response;
    })
  }

  loadTraining(item:any){
    this.loadedItem = item;
  }

  enableForm()
  {
    this.formEnabled= !this.formEnabled;
  }

}

