<div class="container">



<div class="row">
  <div class="col-lg-10">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-lg-2">
          <button class="btn btn-sm btn-secondary text-center custom-btn-margin" [disabled]="!provinces || provinces.length <1" (click)="download()">
            Master APR
          </button>
        </div>
        <div class="col-lg-6">
              <div>
              <label class="form-label">Province Based / Master APR</label>
              <ng-select  [items]="filterProvinces" formControlName="provinces" bindLabel="name" bindValue="id" class="form-select" (change)="onSelectionChange()"
                 [multiple]="true" [closeOnSelect]="false">
              </ng-select>
            </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-lg-2">
    <button class="btn btn-sm btn-primary text-center" (click)="download()">
      <i class="fa fa-download"></i>
    </button>


  </div>

</div>

<table class="table table-sm  table-responsive-sm table-responsive-sm table-bordered " *ngIf="!provinces || provinces.length <1">
  <thead>
    <tr>
      <!-- <th>#</th> -->
      <th [appSort]="objs" data-order="desc" data-name="title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>Goal
      </th>
      <th [appSort]="objs" data-order="desc" data-name="outcome?.title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>Outcome
      </th>

      <th [appSort]="objs" data-order="desc" data-name="baseline" scope="col"> <i
        class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
        [hidden]="!showDesc"></i>
      <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
        [hidden]="!showAsc"></i>Output
    </th>

    <th [appSort]="objs" data-order="desc" data-name="total_target" scope="col"> <i
      class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
      [hidden]="!showDesc"></i>
    <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
      [hidden]="!showAsc"></i>Activity
  </th>

  <th [appSort]="objs" data-order="desc" data-name="total_target" scope="col"> <i
    class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
    [hidden]="!showDesc"></i>
  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
    [hidden]="!showAsc"></i>Sub Activity Name
  </th>

  <th [appSort]="objs" data-order="desc" data-name="total_target" scope="col"> <i
    class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
    [hidden]="!showDesc"></i>
  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
    [hidden]="!showAsc"></i>Target
  </th>

<th>Actions</th>

  <!-- <th *ngFor="let item of provinces">{{item.name}}</th> -->

    </tr>
  </thead>
  <tbody class="table table-sm table-group-divider table-bordered">
    <tr *ngFor="let item of uniqueActivities ; let i = index">

      <!-- <th scope="row">{{i+1}}</th> -->
      <td >{{item.goaltitle}}</td>
      <td>{{item.outcometitle}}</td>
      <td>{{item.outputtitle}}</td>
      <td>{{item.activitytitle}}</td>
      <td>{{item.disaggregation_name}}</td>
      <td>{{item.target_value}}</td>
      <!-- <td *ngFor="let pr of provinces">
        {{getTargetValue(pr.id, item.target_province)}}
      </td> -->
      <td>
        <div class="btn-group btn-group-sm" role="group" >
          <button type="button" class="btn btn-danger btn-sm" (click)="deleteActivityTarget(item.activityid)"><i class="fa fa-trash"></i></button>
        </div>
      </td>
    </tr>
  </tbody>
</table>


<div *ngIf="provinces">


  <table class="table table-sm table-responsive-sm table-bordered">
    <thead>
      <tr>
        <th scope="col">Province</th>
        <!-- <th scope="col" [appSort]="objs" data-order="desc" data-name="title">
          <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i> Goal
        </th> -->
        <!-- <th scope="col" [appSort]="objs" data-order="desc" data-name="outcome?.title">
          <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i> Outcome
        </th>
        <th scope="col" [appSort]="objs" data-order="desc" data-name="baseline">
          <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i> Output
        </th>
        <th scope="col" [appSort]="objs" data-order="desc" data-name="total_target">
          <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i> Activity
        </th>
        <th scope="col" [appSort]="objs" data-order="desc" data-name="total_target">
          <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i> Target
        </th> -->
      </tr>
    </thead>
    <tbody class="table table-sm">
      <tr *ngFor="let province of provinces; let i = index">
        <span *ngFor="let item of getProvinceRecord(province.id)">
          <td>{{ province.name }}</td>
          <td>{{ item.goaltitle }}</td>
          <td>{{ item.outcometitle }}</td>
          <td>{{ item.outputtitle }}</td>
          <td>{{ item.activitytitle }}</td>
          <td>{{ item.target_value }}</td>
        </span>
      </tr>
    </tbody>
  </table>


</div>


</div>
