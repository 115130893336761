<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h4 class="m-0 text-dark">APR Reporting</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item"><a href="/apr/overview">apr overview</a></li>
            <li class="breadcrumb-item active">APR Reporting</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  
  <section class="content">
    <div class="container-fluid">
      <div class="card card-body">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="ac1_1" data-toggle="tab" href="#ac1.1" role="tab" aria-controls="data" aria-selected="true">Activity 1.1.1</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="ac1_2" data-toggle="tab" href="#ac1.2" role="tab" aria-controls="data" aria-selected="true">Activity 1.1.2</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="ac1_3" data-toggle="tab" href="#ac1.3" role="tab" aria-controls="data" aria-selected="true">Activity 1.1.3</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="ac1_4" data-toggle="tab" href="#ac1.4" role="tab" aria-controls="data" aria-selected="true">Activity 1.1.4</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="ac2_1" data-toggle="tab" href="#ac2.1" role="tab" aria-controls="data" aria-selected="true">Activity 2.1</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="ac2_2" data-toggle="tab" href="#ac2.2" role="tab" aria-controls="data" aria-selected="true">Activity 2.2</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="ac2_3" data-toggle="tab" href="#ac2.3" role="tab" aria-controls="data" aria-selected="true">Activity 2.3</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="ac2_4" data-toggle="tab" href="#ac2.4" role="tab" aria-controls="data" aria-selected="true">Activity 2.4</a>
          </li>
        </ul>
  
        <!-- Tab panes -->
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="#ac1.1" role="tabpanel" aria-labelledby="data-tab">
            <div *ngIf="koboData.length > 0" style="overflow: auto;">
              <table id="dtBasicExample" class="table table-sm table-bordered table-responsive-sm table-responsive-md">
                <thead>
                  <tr class="bg-primary">
                    <th>#</th>
                    <th>Grant</th>
                    <th>Province</th>
                    <th>District</th>
                    <th>Village</th>
                    <th>Site Code</th>
                    <th>Intervention Modality</th>
                    <th>Registration Date</th>
                    <th>Beneficiary Code</th>
                    <th>Name</th>
                    <th>Father or Husband Name</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Marital Status</th>
                    <th>Child of Beneficiary Code</th>
                    <th>Age of Child of Beneficiary</th>
                    <th>Phone</th>
                    <th>Household Status</th>
                    <th>Literacy Level</th>
                    <th>Disability Type</th>
                    <th>GBV Survivor</th>
                    <th>Referred for Protect MHPSS GBV Services</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of koboData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.Grant }}</td>
                    <td>{{ item.Province }}</td>
                    <td>{{ item.District }}</td>
                    <td>{{ item.Village }}</td>
                    <td>{{ item.Site_Code }}</td>
                    <td>{{ item.Intervention_Modality }}</td>
                    <td>{{ item.Registration_Date }}</td>
                    <td>{{ item.Beneficiary_Code }}</td>
                    <td>{{ item.Name }}</td>
                    <td>{{ item.Father_or_Husband_Name }}</td>
                    <td>{{ item.Age }}</td>
                    <td>{{ item.Gender }}</td>
                    <td>{{ item.Marital_Status }}</td>
                    <td>{{ item.Child_of_Beneficiary_Code }}</td>
                    <td>{{ item.Age_of_child_of_Beneficiary }}</td>
                    <td>{{ item.Phone }}</td>
                    <td>{{ item.Household_Status }}</td>
                    <td>{{ item.Literacy_Level }}</td>
                    <td>{{ item.Disability_Type }}</td>
                    <td>{{ item.GBV_Survivor }}</td>
                    <td>{{ item.Referred_for_Protect_MHPSS_GBV_services }}</td>
                    <td>
                      <button type="button" class="btn btn-success" (click)="process(item)">Validate</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="koboData.length === 0">
              No data available.
            </div>
          </div>
  
          <div class="tab-pane fade" id="#ac1.2" role="tabpanel" aria-labelledby="export-tab">
            <div class="btn-group mt-3" role="group">
            </div>
          </div>
  
          <div class="tab-pane fade" id="#ac1.3" role="tabpanel" aria-labelledby="search-tab">
            <div class="input-group mt-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  