import { Component } from '@angular/core';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

}
