<table class="table table-sm">
  <div class="row">
    <div class="col-lg-2">
      <tr>
        <td>Created by FC/HoD:</td>
        <td>
          <input type="checkbox" name="createdbyfc" id="" [checked]="contains(grant?.approval_history_flag, ['NEW', 'SUBMITTED', 'LOCALLY_FINALIZED', 'HQ_FINALIZED'])"
          [disabled]="contains(grant?.approval_history_flag, ['NEW', 'SUBMITTED', 'LOCALLY_FINALIZED', 'HQ_FINALIZED'])" class="form-control form-control-sm">
        </td>
      </tr>


      <tr>
        <td>Submited by FC/HoD:</td>
        <td>
          <input type="checkbox" name="createdbyfc" (change)="checked('SUBMITTED')" id="" [checked]="contains(grant?.approval_history_flag, ['SUBMITTED', 'LOCALLY_FINALIZED', 'HQ_FINALIZED'])"
          [disabled]="contains(grant?.approval_history_flag, ['SUBMITTED', 'LOCALLY_FINALIZED', 'HQ_FINALIZED'])" class="form-control form-control-sm">
        </td>
      </tr>


      <tr>
        <td>Grant Finalization:</td>
        <td>
          <input type="checkbox" name="createdbyfc" (change)="checked('LOCALLY_FINALIZED')" id="" [checked]="contains(grant?.approval_history_flag, ['LOCALLY_FINALIZED', 'HQ_FINALIZED'])"
          [disabled]="contains(grant?.approval_history_flag, ['LOCALLY_FINALIZED', 'HQ_FINALIZED'])" class="form-control form-control-sm">
        </td>
      </tr>


      <tr>
        <td>HQ Finalization:</td>
        <td>
          <input type="checkbox" name="createdbyfc" (change)="checked('HQ_FINALIZED')" id="" [checked]="contains(grant?.approval_history_flag, ['HQ_FINALIZED'])"
          [disabled]="contains(grant?.approval_history_flag, ['HQ_FINALIZED'])" class="form-control form-control-sm">
        </td>
      </tr>
    </div>


    <div class="col-lg-8">

<table class="table table-sm table-bordered">
  <thead>
    <tr>
      <th>#</th>
      <th [appSort]="objs" data-order="desc" data-name="title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>From
      </th>
      <th [appSort]="objs" data-order="desc" data-name="outcome?.title" scope="col"> <i
          class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
          [hidden]="!showDesc"></i>
        <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
          [hidden]="!showAsc"></i>To
      </th>

      <th [appSort]="objs" data-order="desc" data-name="baseline" scope="col"> <i
        class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
        [hidden]="!showDesc"></i>
      <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
        [hidden]="!showAsc"></i>User
    </th>

    <th [appSort]="objs" data-order="desc" data-name="baseline" scope="col"> <i
      class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
      [hidden]="!showDesc"></i>
    <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
      [hidden]="!showAsc"></i>Remarks
  </th>

    </tr>
  </thead>
  <tbody class="table table-sm -group-divider">
    <tr *ngFor="let item of objs; let i = index">
      <th scope="row">{{i+1}}</th>
      <td>{{item.from_status}}</td>
      <td>{{item.to_status}}</td>
      <td>{{item.user?.name}}</td>
      <td>{{item.remarks}}</td>
    </tr>
  </tbody>
</table>


    </div>


    <div class="col-lg-2">


      <!-- Form -->
    <div class="row" *ngIf="status">
      <div class="col-lg-12">
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <p>Selected Status: {{status}}</p>

      <div class="row">


        <div class="col-lg-12">
          <textarea type="number" formControlName="remarks" class="form-control" placeholder=""></textarea>
          <label class="form-label">Remarks</label>
        </div>

              <div class="col-lg-12">
                <button type="submit" [disabled]="formGroup.invalid" class="btn btn-success form-control">
                  <i class="fa fa-add"></i>
                </button>
              </div>
            </div>

        </form>
        </div>
        </div>


        </div>


  </div>



</table>
