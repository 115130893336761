import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dtb-overview',
  templateUrl: './dtb-overview.component.html',
  styleUrls: ['./dtb-overview.component.scss']
})
export class DtbOverviewComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  submittedBeneficiariesCount: number;
  submittedKitDistributionCount: number;
  submittedPsychoEducationCount: number;
  submittedCommunityDialogueCount: number;
  submittedTrainingsCount: number;

  addObj: boolean = false;
  editObj: any;
  provinces: any;
  departments: any;
  response: any;
  objs: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
  ) {}

  ngOnInit() {
      // this.loadData();
      this.getSubmittedRecordsCount();
      this.getSubmittedKitDistributionsCount();
      this.getSubmittedPsychoEducationCount();
      this.getSubmittedCommunityDialogueCount();
      this.getTotalSubmittedTrainingsCount();

  }

  getSubmittedRecordsCount(): void {
    this.apiService.getTotalSubmittedBeneficiaryCount().subscribe(count => {
      this.submittedBeneficiariesCount = count;
    });
  }

  getSubmittedKitDistributionsCount(): void {
    this.apiService.getTotalSubmittedKitDitributionCount().subscribe(count => {
      this.submittedKitDistributionCount = count;
    });
  }

  getSubmittedPsychoEducationCount(): void {
    this.apiService.getTotalSubmittedPsychoEducationCount().subscribe(count => {
      this.submittedPsychoEducationCount = count;
      console.log(count);
      
    });
  }

  getSubmittedCommunityDialogueCount(): void {
    this.apiService.getTotalSubmittedTrainingsCount().subscribe(count => {
      this.submittedCommunityDialogueCount = count;
      console.log(count);
      
    });
  }

  getTotalSubmittedTrainingsCount(): void {
    this.apiService.getTotalSubmittedTrainingsCount().subscribe(count => {
      this.submittedTrainingsCount = count;
      console.log(count);
      
    });
  }

  loadData(){
    this.apiService.getAllBeneficiaries().subscribe(response => {
      console.log(response.data.data);
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  navigateToSubmittedBeneficiaryDetail(): void {
    this.router.navigate(['dtbs/overview/submitted-beneficiary']);
  }
  navigateToSubmittedKitDistributionDetail(): void {
    this.router.navigate(['dtbs/overview/submitted-kit-distribution']);
  }
  navigateToSubmittedPsychoEducationDetail(): void {
    this.router.navigate(['dtbs/overview/submitted-psycho-education']);
  }
  navigateToSubmittedCommunityDialoguesDetail(): void {
    this.router.navigate(['dtbs/overview/submitted-community-dialogues']);
  }
  navigateToSubmittedTrainingsDetail(): void {
    this.router.navigate(['dtbs/overview/submitted-trainings']);
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  download(item: any){
    this.apiService.exportToExcel(this.response?.data, item.entryyear +"-"+ item.entrymonth + "-dtb");
  }


  approve(item: any){
    if(window.confirm("Are you sure to approve?")){
      this.apiService.approveBeneficiary(item.id).subscribe(response => {
        this.toastrService.success("Approved");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }

  reject(item: any){
    if(window.confirm("Are you sure to reject?")){
      this.apiService.rejectBeneficiary(item.id).subscribe(response => {
        this.toastrService.success("Rejected");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }

  resend(item: any){
    if(window.confirm("Are you sure to resend?")){
      this.apiService.resendBeneficiary(item.id).subscribe(response => {
        this.toastrService.success("Resent");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Operation Failed!");
      })
    }
  }
}
