<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">List of Validated DTB for Finalization</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">List of Validated DTB Finalization</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card card-body">
      
      <table id="approvedTable" class="table table-sm table-responsive-sm table-responsive-md table-bordered">
        <thead>
          <tr class="bg-primary">
            <th>#</th>
            <th>Manager</th>
            <th>Project</th>
            <th>Province</th>
            <th>Year</th>
            <th>Month</th>
            <th>Status</th>
            <th class="no-sort">Options</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of response?.data; let i = index"
            [ngStyle]="{'background-color': (item.validation_status=='VALIDATED') ? '#fff' : (item.validation_status=='FINALIZED') ? '#73c7af' : 'red'}">
            <td>{{response?.from == 1 ? i+1 :response?.from + i}}</td>
            <td>{{item.manager.name}}</td>
            <td>{{item.grant.title}}</td>
            <td>{{item.province.name}}</td>
            <td>{{item.year}}</td>
            <td>{{item.month}}</td>
            <td>{{item.validation_status}}</td>
            <td>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-success" *ngIf="item.validation_status =='VALIDATED'"
                  (click)="finalize(item)">Finalized</button>
                <button type="button" class="btn btn-danger" *ngIf="item.validation_status =='VALIDATED'"
                  (click)="reject(item)">Reject</button>
                <button type="button" class="btn btn-primary" (click)="download(item)">Download</button>
                <button type="button" class="btn btn-info" *ngIf="item.validation_status =='REJECTED'"
                  (click)="resend(item)">Resend</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
