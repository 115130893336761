import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-administrative-home',
  templateUrl: './administrative-home.component.html',
  styleUrls: ['./administrative-home.component.scss']
})
export class AdministrativeHomeComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  provinces: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router
  ) {}

  ngOnInit() {
      this.formGroup = new UntypedFormGroup({
          id: new UntypedFormControl(null,[]),
          name: new UntypedFormControl(null, Validators.required),
          namepa: new UntypedFormControl(null, Validators.required),
          namefa: new UntypedFormControl(null, Validators.required),
          parent_id: new UntypedFormControl(null, Validators.required)
      });

      this.loadData();
  }

  loadData(){
    this.apiService.getProvinces().subscribe(response => {
      console.log("obj", response);
      this.objs = response;
    });

    this.apiService.getProvinces().subscribe(response => {
      this.provinces = response;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteUser(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['name'].setValue(item.name);
      this.formGroup.controls['namepa'].setValue(item.namepa);
      this.formGroup.controls['namefa'].setValue(item.namefa);
      this.editObj = item;

      // this.apiService.deleteUser(item.id).subscribe(response => {
      //   this.toastrService.success("Update Succeeded");
      //   this.loadData();
      // }, (e) => {
      //   this.toastrService.error("Updated Failed!");
      // })
    }
  }

  showDistricts(province: any){
    this.apiService.getDistricts(province.id).subscribe(response => {
        const districts = [];
        response.forEach(item => {
          districts.push(item.name +" \n");
        })
        alert(districts);
    });
  }


  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createDistrict  (this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

  getRoles(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.name);
    });

    return values;
  }

}
