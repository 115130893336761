<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Psychoducation Details </h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Psychoducation Details</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button> -->


        <table class="table table-sm  table-bordered " >

          <tr>
            <td class="labelinfo">Grant: </td>
            <td>{{obj?.grant.title}}</td>
            </tr>

            <tr class="labelinfo">
            <td>Province:</td>
            <td>{{obj?.province.name}}</td>
            </tr>

            <tr>
            <td class="labelinfo">District:</td>
            <td>{{obj?.district.name}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Village:</td>
            <td>{{obj?.village}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Site Code: </td>
            <td>{{obj?.site_code}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Date:</td>
            <td>{{obj?.awareness_date}}</td>
            </tr>

            <tr>
            <td class="labelinfo">Topic: </td>
            <td>{{obj?.awareness_topic}}</td>
</tr>

<tr>
            <td class="labelinfo">Intervention Modality: </td>
            <td>{{obj?.intervention_modality}}</td>
</tr>

            <tr>
            <td class="labelinfo">Host Community:</td>
            <td>{{obj?.host_community}}</td>
          </tr>


          <tr>
            <td class="labelinfo"># of IDP - Male:</td>
            <td>{{obj?.male_idp}}</td>

          </tr>
          <tr>
            <td class="labelinfo"># of Refugee - Male:</td>
            <td>{{obj?.male_refugee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Returnee - Male:</td>
            <td>{{obj?.male_returnee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Disable - Male:</td>
            <td>{{obj?.male_disable}}</td>
          </tr>



          <tr>
            <td class="labelinfo"># of IDP - Female:</td>
            <td>{{obj?.female_idp}}</td>

          </tr>
          <tr>
            <td class="labelinfo"># of Refugee - Female:</td>
            <td>{{obj?.female_refugee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Returnee - Female:</td>
            <td>{{obj?.female_returnee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Disable - Female:</td>
            <td>{{obj?.female_disable}}</td>
          </tr>


          <tr>
            <td class="labelinfo"># of IDP - Boy:</td>
            <td>{{obj?.boy_idp}}</td>

          </tr>
          <tr>
            <td class="labelinfo"># of Refugee - Boy:</td>
            <td>{{obj?.boy_refugee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Returnee - Boy:</td>
            <td>{{obj?.boy_returnee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Disable - Boy:</td>
            <td>{{obj?.boy_disable}}</td>
          </tr>


          <tr>
            <td class="labelinfo"># of IDP - Girl:</td>
            <td>{{obj?.girl_idp}}</td>

          </tr>
          <tr>
            <td class="labelinfo"># of Refugee - Girl:</td>
            <td>{{obj?.girl_refugee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Returnee - Girl:</td>
            <td>{{obj?.girl_returnee}}</td>
          </tr>
          <tr>
            <td class="labelinfo"># of Disable - Girl:</td>
            <td>{{obj?.girl_disable}}</td>
          </tr>



          <tr>
            <td class="labelinfo">Remarks:</td>
            <td>{{obj?.remarks}}</td>
          </tr>




        </table>


      </div>
  </div>
</section>
