import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-submitted-community-dialogue-details',
  templateUrl: './submitted-community-dialogue-details.component.html',
  styleUrls: ['./submitted-community-dialogue-details.component.scss']
})
export class SubmittedCommunityDialogueDetailsComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  response: any;
  objs: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
      this.loadData();
  }

  loadData(){
    this.apiService.getAllCommunityDialogueByStatus("SUBMITTED").subscribe(response => {
      this.response = response.data;
      console.log(response.data.data);
      this.objs = response.data.data;
    });
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  validate(item: any) {
    this.apiService.validateCommunityDialogue(item.id).subscribe(
      response => {
        this.toastrService.success('Status updated to VALIDATED');
        this.loadData(); // Refresh the data after updating the status
      },
      error => {
        this.toastrService.error('Failed to update status');
      }
    );
  }

  download(item: any){
    this.apiService.exportToExcel(this.response?.data, item.entryyear +"-"+ item.entrymonth + "-dtb");
  }

}