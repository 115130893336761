<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Beneficiary Services</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
                  <li class="breadcrumb-item active">Beneficiary Services</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="row justify-content-center">

        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Activity</div>
            <div class="card-body">
              <p class="card-text">You can manage the Activities in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/activities/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Referral</div>
            <div class="card-body">
              <p class="card-text">You can manage the Referrals in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/referrals/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Meal Tools</div>
            <div class="card-body">
              <p class="card-text">You can manage the Meal tools in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/meals/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Evaluation and Termination</div>
            <div class="card-body">
              <p class="card-text">Manage the Evaluation and Termination in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/evaluation-terminations/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Kit Distribution</div>
            <div class="card-body">
              <p class="card-text">Manage the Kit Distribution in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/kit-distributions/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Community Dialogue</div>
            <div class="card-body">
              <p class="card-text">Manage the Dialogue with communitites in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/community-dialogues/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Training</div>
            <div class="card-body">
              <p class="card-text">Manage the trainings in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/trainings/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        <div class="card border-primary mb-3 mr-1 col-lg-3 col-sm-12" style="max-width: 19rem;">
            <div class="card-header bg-primary">Training Pre & Post Evaluation</div>
            <div class="card-body">
              <p class="card-text">Manage the trainings Pre and Post Evaluation in this option</p>
              <a class="btn btn-primary float-sm-right" (click)="route('/beneficiaries/trainings-test/' + id)"><i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

</div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->

<!-- <app-beneficiary-activity [beneficiaryId]="id" [addValue]="true"></app-beneficiary-activity>
<app-beneficiary-meal></app-beneficiary-meal>
<app-beneficiary-referral></app-beneficiary-referral>
<app-beneficiary-evaluation-termination></app-beneficiary-evaluation-termination> -->
