
    <div class="row">
          <div class="col-lg-10"></div>
          <div class="col-lg-2">
            <button type="button" class="btn btn-success float-right" (click)="enableForm()"><i class="fa fa-plus"></i></button>
          </div>
          <hr/>
    </div>

        <form [formGroup]="formGroup" (ngSubmit)="submit()">


          <!-- <h4>Topic {{pageNumber}}</h4> -->

          <p class="text-center header-banner">Training Topic {{pageNumber}} - Training Information</p>

          <div class="row">

            <div class="mb-3 col-lg-3">
              <label  class="form-label">Training Topic</label>
              <input type="text" formControlName="training_topic" class="form-control" placeholder="" [readOnly]="formEnabled">
              <print-error [control]="formGroup.get('training_topic')"></print-error>

            </div>

            <div class="mb-3 col-lg-3">
              <label  class="form-label">Training Modality</label>

              <input type="text" formControlName="training_modality" *ngIf="formEnabled" class="form-control" placeholder="" [readOnly]="formEnabled">


              <select class="form-control" formControlName="training_modality" *ngIf="!formEnabled" >
                <option value="ONLINE">ONLINE</option>
                <option value="FACE-TO-FACE">FACE-TO-FACE </option>
              </select>
              <print-error [control]="formGroup.get('training_modality')"></print-error>
            </div>

            <div class="mb-3 col-lg-3">
              <label  class="form-label">Training Type</label>
              <input type="text" formControlName="training_modality" *ngIf="formEnabled" class="form-control" placeholder="" [readOnly]="formEnabled">

              <select class="form-control" formControlName="training_type" *ngIf="!formEnabled">
                <option value="ONLINE">ONLINE</option>
                <option value="FACE-TO-FACE">FACE-TO-FACE </option>
              </select>
              <print-error [control]="formGroup.get('training_type')"></print-error>

            </div>

          <div class="mb-3 col-lg-3">
            <label  class="form-label">Start Date</label>
            <input type="date" formControlName="start_date" class="form-control" placeholder="" [readOnly]="formEnabled">
            <print-error [control]="formGroup.get('start_date')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label  class="form-label">End Date</label>
            <input type="date" formControlName="end_date" class="form-control" placeholder="" [readOnly]="formEnabled">
            <print-error [control]="formGroup.get('end_date')"></print-error>
          </div>


          <div class="mb-3 col-lg-3">
            <label  class="form-label">Number of training days</label>
            <input type="number" formControlName="number_of_days" class="form-control" placeholder="" [readOnly]="formEnabled">
            <print-error [control]="formGroup.get('number_of_days')"></print-error>
          </div>



          <div class="mb-3 col-lg-3">
            <label  class="form-label">Facilitator Name</label>
            <input type="text" formControlName="facilitator_name" class="form-control" placeholder="" [readOnly]="formEnabled">
            <print-error [control]="formGroup.get('facilitator_name')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label  class="form-label">Facilitator Position</label>
            <input type="text" formControlName="facilitator_position" class="form-control" placeholder="" [readOnly]="formEnabled">
            <print-error [control]="formGroup.get('facilitator_position')"></print-error>
          </div>

          <div class="mb-3 col-lg-9">
            <label  class="form-label">Remarks</label>
            <textarea type="text" formControlName="remarks" class="form-control" placeholder="" [readOnly]="formEnabled"></textarea>
          </div>


        </div>

        <p class="text-center header-banner">Training Evaluation</p>
        <div class="row">

        <hr>

        <div class="mb-3 col-lg-6">
          <label  class="form-label">Pre Score</label>
          <input type="number" formControlName="pre_score" class="form-control" placeholder="" [readOnly]="formEnabled">
        </div>

        <div class="mb-3 col-lg-6">
          <label  class="form-label">Post Score</label>
          <input type="number" formControlName="post_score" class="form-control" placeholder="" [readOnly]="formEnabled">
        </div>


        <hr>

        </div>


        <!-- <h5>Stastisfication</h5> -->
        <!-- <div class="row">

        <div class="mb-3 col-lg-4">
          <label  class="form-label">Informative Level</label>
          <select class="form-control" formControlName="informative_level" >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>


        <div class="mb-3 col-lg-4">
          <label  class="form-label">Usefulness Level</label>
          <select class="form-control" formControlName="usefulness_level" >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>


        <div class="mb-3 col-lg-4">
          <label  class="form-label">Understanding Level</label>
          <select class="form-control" formControlName="understaning_level" >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>


        <div class="mb-3 col-lg-4">
          <label  class="form-label">Relevance Level</label>
          <select class="form-control" formControlName="relevance_level" >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>


        <div class="mb-3 col-lg-4">
          <label  class="form-label">Accessibility Level</label>
          <select class="form-control" formControlName="accessability_level" >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>


        <div class="mb-3 col-lg-4">
          <label  class="form-label">Percentage of Positive Evaluation</label>
          <input type="number" formControlName="percentage_of_positive_evaluation" class="form-control" placeholder="">
        </div>

        </div> -->
          <div class="row">
            <div class="col-lg-2">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          </div>

        </form>
