import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apr-preview',
  templateUrl: './apr-preview.component.html',
  styleUrls: ['./apr-preview.component.scss']
})
export class AprPreviewComponent {
  objs:  any[];

  @Input() grantId : any;
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  provinces: any;
  formGroup: FormGroup;
  provinceTargets: any[];
  filterProvinces: any;
  uniqueActivities: any[];


    constructor(private apiService: ApiService, private formBuilder : FormBuilder, private toastrService: ToastrService ){

    }

    ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
      this.formGroup = this.formBuilder.group({
        provinces:[]
      });

      this.loadData();
    }




    loadData()
    {

      this.apiService.getAprPreview(this.grantId).subscribe((response:any) =>{
        this.objs = response;
        this.provinceTargets = this.getProvinceTargets();
        this.uniqueActivities = this.getUniqueActivities(response);
      })

      this.apiService.getGrantProvinces(this.grantId).subscribe(response => {
        this.filterProvinces= response;
      })
    }


    getUniqueActivities(apr){
      // Assuming apr is your array of objects
      const uniqueRecords = Array.from(new Set(apr?.map(item => item.activitytitle)))
      .map(activitytitle => apr?.find(item => item.activitytitle === activitytitle));
      return uniqueRecords;

    }

    getProvinceTargets(){
      const filteredRecords = this.objs?.filter(record => record.target_type === 'PROVINCE_TARGET');
      console.log("filtered", filteredRecords);
      return filteredRecords

    }

    onSelectionChange() {
      const selectedValues = this.formGroup.get('provinces')?.value;
      console.log('Selected Values:', selectedValues);

      const filteredRecords = this.objs?.filter(record => record.target_type === 'PROVINCE_TARGET');


      this.provinces = this.filterProvinces.filter(item => selectedValues.includes(item.id));
      this.provinceTargets = this.getProvinceTargets().filter(item => selectedValues.includes(item.target_province));

    }

    download(){
      this.apiService.exportToExcel(this.objs, "APR-Master")
    }

    getTargetValue(provinceId:number, targetProvince:number){
      if(provinceId == targetProvince){
        return 100;
      }
    }


    getProvinceRecord(provinceId:number):any{
      const filteredRecords = this.objs?.filter(record => record.target_type === 'PROVINCE_TARGET');
      console.log("province_target", filteredRecords);

      const result = filteredRecords?.filter(record => record.target_province === provinceId);
      console.log("byprovince_id", result);

      return result;
    }

    deleteActivityTarget(itemId:number){

      if(window.confirm("Are you sure to delete the target calculation?"))  {

        this.apiService.deleteActivityTargetCalc(itemId).subscribe(response => {
          this.loadData();
          this.toastrService.success("Deletion Done!");
        }, (err) => {
          this.toastrService.error("Deletion Failed");
        })

      }
    }

}
