import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss']
})
export class BeneficiaryComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  // objs: any;

  addObj: boolean = false;
  editObj: any;
  grants: any;
  provinces: any;
  districts: any;
  response: any;
  objs: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',[]],
      grant_id: ['', [Validators.required]],
      district_id: ['', [Validators.required]],
      health_facility: ['', []],
      province_id: ['', [Validators.required]],
      village: ['', [Validators.required]],
      site_code: ['', [Validators.required]],
      intervention_modality: ['', [Validators.required]],
      registration_date: ['', [Validators.required]],
      beneficiary_code: ['', []],
      client_name: ['', [Validators.required]],
      father_or_husband_name: ['', [Validators.required]],
      age: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      marital_status: ['', [Validators.required]],
      child_of_beneficiary_code: ['', []],
      age_of_child_of_bnf: ['', []],
      client_phone: ["+937", [Validators.required, Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]],
      household_status_of_bnf: ['', [Validators.required]],
      literacy_level_of_bnf: ['', [Validators.required]],
      disability_type: ['', [Validators.required]],
      gbv_survivor: ['', [Validators.required]],
      referred_for_protection: ['', [Validators.required]],
    });

      this.loadData();
  }


  loadData(){
    // this.apiService.getBeneficiaries().subscribe(response => {
    //   this.objs = response;
    // });
    this.apiService.getAllGrants().subscribe(response => {
      this.grants = response.data.data;
    })

    this.apiService.getBeneficiaries().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    })
  }


  loadGrantProvinces(value:any){
    this.apiService.getGrantProvinces(value.id).subscribe(response => {
      this.provinces = response;
    });

  }

  loadAdminOrgsChildern(value:any){
    this.apiService.getDistricts(value.id).subscribe(response => {
      this.districts = response;
    })
  }


  getAllDistricts(){
    this.apiService.getAllDistricts().subscribe(response => {
      this.districts = response;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteBeneficiary(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){

      this.loadGrantProvinces(item);
      this.getAllDistricts();

      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['client_name'].setValue(item.client_name);
      this.formGroup.controls['beneficiary_code'].setValue(item.beneficiary_code);
      this.formGroup.controls['village'].setValue(item.village);
      this.formGroup.controls['site_code'].setValue(item.site_code);
      this.formGroup.controls['intervention_modality'].setValue(item.intervention_modality);
      this.formGroup.controls['registration_date'].setValue(item.registration_date);
      this.formGroup.controls['age'].setValue(item.age);
      this.formGroup.controls['gender'].setValue(item.gender);
      this.formGroup.controls['marital_status'].setValue(item.marital_status);
      this.formGroup.controls['age_of_child_of_bnf'].setValue(item.age_of_child_of_bnf);
      this.formGroup.controls['household_status_of_bnf'].setValue(item.household_status_of_bnf);
      this.formGroup.controls['child_of_beneficiary_code'].setValue(item.child_of_beneficiary_code);
      this.formGroup.controls['gbv_survivor'].setValue(item.gbv_survivor);
      this.formGroup.controls['disability_type'].setValue(item.disability_type);
      this.formGroup.controls['referred_for_protection'].setValue(item.referred_for_protection);
      this.formGroup.controls['client_phone'].setValue(item.client_phone);
      this.formGroup.controls['literacy_level_of_bnf'].setValue(item.literacy_level_of_bnf);
      this.formGroup.controls['grant_id'].setValue(item.grant_id);
      this.formGroup.controls['province_id'].setValue(item.province_id);
      this.formGroup.controls['district_id'].setValue(item.district_id);
      this.formGroup.controls['father_or_husband_name'].setValue(item.father_or_husband_name);


      this.editObj = item;
    }
  }


  details(item: any)
  {
    this.router.navigate(['/beneficiaries/details/' + item.id ])
  }

  addServices(item:any)
  {
    this.router.navigate(['/beneficiaries/services/tab/' + item.id ])
  }

  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createBeneficiary(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

  getRoles(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.name);
    });

    return values;
  }

}

