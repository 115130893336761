import { Component, Input } from '@angular/core';
import {  OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-beneficiary-activity',
  templateUrl: './beneficiary-activity.component.html',
  styleUrls: ['./beneficiary-activity.component.scss']
})
export class BeneficiaryActivityComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  @Input() beneficiaryId: number;
  addValue: any = true;
  public formGroup: any;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  beneficiary: any;
  activities: any;

  @Input() activityType:any ="";
  @Input() activityGroup:any = "";
  @Input() pageTitle:any = "";

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder: FormBuilder
      ){

    }

  ngOnInit() {

    this.apiService.getBeneficiary(this.beneficiaryId).subscribe((response) => {
      this.beneficiary = response;
      this.apiService.getGrantActivities(this.beneficiary.grant_id).subscribe(response => {
        this.activities = response;
      });
    });

    this.formGroup = this.formBuilder.group({
      id: ['',],
      grant_id: ['', []],
      beneficiary_id: ['', []],
      activity_type: ['', []],
      group_id: ['', []],
      session_id: ['', []],
      session_date: ['', [Validators.required]],
      session_topic: ['', [Validators.required]],
      description: ['', []],
      grant_activity_id: ['', []],
      activity_category: ['', []]
    });
    console.log(this.beneficiaryId);
    this.formGroup.controls['activity_type'].setValue(this.activityType);
    this.formGroup.controls['activity_category'].setValue(this.activityGroup);

      this.loadData();
  }

  loadData(){
    const data = {
      "id": this.beneficiaryId,
      "type": this.activityType,
      "category":this.activityGroup
    }
    this.apiService.getBeneficiaryActivitiesByIdAndGroupAndType(data).subscribe(response => {
      this.objs = response;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteBeneficiaryActivity(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    // if(window.confirm("Are you sure to edit?")){
      // this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['grant_activity_id'].setValue(item.activity?.id);
      this.formGroup.controls['activity_type'].setValue(item.activity_type);
      this.formGroup.controls['activity_category'].setValue(item.activity_category);
      this.formGroup.controls['session_id'].setValue(item.session_id);
      this.formGroup.controls['session_topic'].setValue(item.session_topic);
      this.formGroup.controls['description'].setValue(item.description);
      this.formGroup.controls['session_date'].setValue(item.session_date);
      this.editObj = item;
    // }
  }



  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);
    this.formGroup.controls['grant_id'].setValue(this.beneficiary.grant_id);
    this.formGroup.controls['beneficiary_id'].setValue(this.beneficiary.id);

    this.apiService.createBeneficiaryActivities(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      this.toastrService.success("Registration succeeded");
      // this.formGroup.reset();
      this.formGroup.controls['session_topic'].setValue('');
      this.formGroup.controls['session_date'].setValue('');

      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}

