// modal.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apr-finalization',
  templateUrl: './apr-finalization.component.html',
  styleUrls: ['./apr-finalization.component.scss']
})
export class AprFinalizationComponent {

  @Input() grantId: any;
  @Input() grant: any;
  formGroup: UntypedFormGroup;
  status: string;
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  objs: any;


  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    private formBuilder : FormBuilder
    ) {
        // console.log('id', this.id);
        // this.apiService.getGrant(this.grantId).subscribe((response) => {
        //   this.grant = response;
        // });
    }


ngOnInit() {
    this.formGroup = this.formBuilder.group({
        grant_id: ['',[]],
        from_status: [null,[]],
        to_status: [null,[]],
        remarks: ['', [Validators.required]],
      });
      this.loadData();
  }

  loadData(){

    this.apiService.getGrantApprovalHistories(this.grantId).subscribe(response => {
      this.objs = response;
      console.log(this.objs);

    });
  }


  submit(){

    if(this.status){
      this.formGroup.controls['grant_id'].setValue(this.grantId);
      this.formGroup.controls['to_status'].setValue(this.status);
      this.formGroup.controls['from_status'].setValue(this.grant?.approval_history_flag);

      console.log(this.formGroup.value);

      this.apiService.grantStatusChange(this.formGroup.value).subscribe(response => {
        this.toastrService.success("Registration succeeded");
        this.formGroup.reset();
        this.status='';
        this.grant = response;
        this.loadData();
      }, (e) => {
        this.toastrService.error("Registration failed");
      })
    }

  }


  checked(status:string){
    this.status = status;
  }

  contains (status:string, states : string[]){
    return states.includes(status);

  }

}
