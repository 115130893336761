import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html',
  styleUrls: ['./error-log.component.scss']
})
export class ErrorLogComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  objs: any;
  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
  ) {}

  ngOnInit() {
      this.loadData();
  }

  loadData(){
    this.apiService.getErrorLogs().subscribe(response => {
      this.objs = response;
    });


  }





}
