import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  public formGroup: FormGroup;
  public isLoading :boolean = false;

  addObj: boolean = false;
  editObj: any;
  response: any;
  objs: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router
  ) {}

  ngOnInit() {

      this.loadData();
  }

  loadData(){
    this.apiService.getPermissions().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }
}
