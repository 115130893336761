import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grant-target',
  templateUrl: './grant-target.component.html',
  styleUrls: ['./grant-target.component.scss']
})
export class GrantTargetComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  @Input() grantId: any;
  @Input() activity: any;
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  provinces: any;
  addSecondObj: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router
  ) {
    // console.log("activity", this.activity);

  }

  ngOnInit() {
      this.formGroup = new UntypedFormGroup({
          id: new UntypedFormControl(null,[]),
          grant_id: new UntypedFormControl(null,[]),
          grant_activity_id: new UntypedFormControl(null,[]),
          administrative_org_id: new UntypedFormControl(null, Validators.required),
          number_of_team: new UntypedFormControl(null, Validators.required),
          number_of_sessions: new UntypedFormControl(null, Validators.required),
          number_of_group_sessions: new UntypedFormControl(null, Validators.required),
          number_of_onetoone: new UntypedFormControl(null, Validators.required),

      });

      this.loadData();
  }

  loadData(){
    this.apiService.loadGrantTargets(this.activity.grant_id).subscribe(response => {
      // console.log("obj", response);
      this.objs = response;
    });

    this.apiService.getProvinces().subscribe(response => {
      this.provinces = response;
    })
  }

  addObjectClicked(){
    this.addSecondObj= false;
    this.addObj = !this.addObj;
  }

  addSecondObjectClicked(){
    this.addObj = false;
    this.addSecondObj = !this.addSecondObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteDepartment(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['name'].setValue(item.name);
      this.formGroup.controls['code'].setValue(item.code);
      this.editObj = item;
    }
  }



  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);
    this.formGroup.controls['grant_id'].setValue(this.activity.grant_id);
    this.formGroup.controls['grant_activity_id'].setValue(this.activity.id);

    this.apiService.createGrantGroupTarget(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

  getRoles(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.name);
    });

    return values;
  }

}

