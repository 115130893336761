import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-grant-list',
  templateUrl: './grant-list.component.html',
  styleUrls: ['./grant-list.component.scss']
})
export class GrantListComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  // objs: any;

  addObj: boolean = false;
  editObj: any;
  provinces: any;
  departments: any;
  headElements = ['id', 'code', 'donor', 'startDate', 'endDate', 'status', 'action'];
  @ViewChild('content') content:ElementRef;
  response: any;
  objs: any;




  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
  ) {

    //  for (let i = 1; i <= 15; i++) {
    //   this.objs.push({ id: i, code: 'Code ' + i, donor: 'Donor ', startDate: 'Start Date', endDate: 'End Date', status: 'Status' , action: 'Action ' + i });
    //  }



  }

  ngOnInit() {
    this.loadData();


  }


  loadData(){
    this.apiService.getGrants().subscribe(response => {
      this.response = response.data;
      this.objs =response.data.data;
    });
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs =response.data.data;
      // console.log(this.response);

    })
  }




  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteGrant(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }


  edit(item: any){
    // if(window.confirm("Are you sure to edit?")){
      this.router.navigate(['/grant/create/'+ item.id]);
    // }
  }

  configure(item: any){
      this.router.navigate(['/grant/configure/'+ item.id]);
  }


  details(item: any){
    this.router.navigate(['/grant/details/'+ item.id]);
  }


  savePDF(){

    const DATA = this.content.nativeElement;
    html2canvas(DATA).then(canvas => {
      let fileWidth = 200;
      let fileHeight = canvas.height * fileWidth / canvas.width;

      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 5, 5, fileWidth, fileHeight)
      PDF.save('grants-overview.pdf');
    });

  }

  saveExcel(){
    this.apiService.exportToExcel(this.response?.data, "grants-overview");

  }


}
