import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Route, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-portifolio-summary',
  templateUrl: './portifolio-summary.component.html',
  styleUrls: ['./portifolio-summary.component.scss']
})
export class PortifolioSummaryComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
 tnaFigures: any;
  total: number = 0;
  provinceFigures: any;
  departmentFigures: any;
  grants: any;
  departments: any;
  deptStats: Array<any> = [];

  constructor(private router: Router, public apiService: ApiService) {
    Chart.register(...registerables);

  }
  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.apiService.getDepartmentsGrantsStats().subscribe(response => {

      this.deptStats = response;

          new Chart("myChart", {
              type: 'bar',
              data: {
                  labels: this.deptStats.map(x => x.name),
                  datasets: [{
                      label: '# of Grants by Department',
                      data: this.deptStats.map(x => x.no_grants),
                      backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(255, 159, 64, 0.2)'
                      ],
                      // borderColor: [
                      //     'rgba(255, 99, 132, 1)',
                      //     'rgba(54, 162, 235, 1)',
                      //     'rgba(255, 206, 86, 1)',
                      //     'rgba(75, 192, 192, 1)',
                      //     'rgba(153, 102, 255, 1)',
                      //     'rgba(255, 159, 64, 1)'
                      // ],
                      borderWidth: 1
                  }]
              },
          });


      new Chart("pieChart", {
              type: 'pie',
              data: {
                  labels: this.deptStats.map(x => x.status),
                  datasets: [{
                      label: '# of Grants by Status',
                      data: this.deptStats.map(x => x.no_grants),
                      borderWidth: 1
                  }]
        }
      });
    })

  }


  loadData() {

    this.apiService.getAllGrants().subscribe(response => {
      this.grants = response.data.data;
    });
    this.apiService.getDepartments().subscribe(response => {
      this.departments = response;
    });
  }

  details(item: any){
    this.router.navigate(['/grant/details/'+ item.id]);
  }

}
