import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {CommonModule, NgIf, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import { TranslocoRootModule } from './transloco-root.module';
import { HttpInterceptorInterceptor } from './http-interceptor.interceptor';
import { UserHomeComponent } from './pages/user/user-home/user-home.component';
import { TrainingHomeComponent } from './pages/training/training-home/training-home.component';
import { DepartmentHomeComponent } from './pages/department/department-home/department-home.component';
import { AdministrativeHomeComponent } from './pages/administrative/administrative-home/administrative-home.component';
import { MyspaceHomeComponent } from '@pages/myspace/myspace-home/myspace-home.component';
import { GrantHomeComponent } from '@pages/grant/grant-home/grant-home.component';
import { PermissionComponent } from '@pages/user/permission/permission.component';
import { RoleComponent } from '@pages/user/role/role.component';
import { BlockedIpComponent } from '@pages/user/blocked-ip/blocked-ip.component';
import { DisaggregationComponent } from '@pages/settings/disaggregation/disaggregation.component';
import { GrantCreateComponent } from '@pages/grant/grant-create/grant-create.component';
import { GrantListComponent } from '@pages/grant/grant-list/grant-list.component';
import { NgTempusdominusBootstrapModule } from 'ngx-tempusdominus-bootstrap';
import { GrantConfigureComponent } from '@pages/grant/grant-configure/grant-configure.component';
import { GrantDetailsComponent } from '@pages/grant/grant-details/grant-details.component';
import { GrantUpdateComponent } from '@pages/grant/grant-update/grant-update.component';
import { GrantStatusComponent } from '@pages/grant/grant-status/grant-status.component';
import { AssessmentComponent } from '@pages/myspace/assessment/assessment.component';
import { BeneficiaryComponent } from '@pages/myspace/beneficiary/beneficiary.component';
import { ReferralComponent } from '@pages/myspace/referral/referral.component';
import { KitDistributionComponent } from '@pages/myspace/kit-distribution/kit-distribution.component';
import { SubmittedKitDistributionDetailsComponent } from '@pages/management/dtb-overview/submitted-kit-distribution-details/submitted-kit-distribution-details.component';
import { PsychoeducationComponent } from '@pages/myspace/psychoeducation/psychoeducation.component';
import { EquipComponent } from '@pages/myspace/equip/equip.component';
import { DtbComponent } from '@pages/myspace/dtb/dtb.component';
import { BeneficiaryServicesComponent } from '@pages/myspace/beneficiary-services/beneficiary-services.component';
import { BeneficiaryDetailsComponent } from '@pages/myspace/beneficiary-details/beneficiary-details.component';
import { BeneficiaryActivityComponent } from '@pages/myspace/beneficiary-activity/beneficiary-activity.component';
import { BeneficiaryMealComponent } from '@pages/myspace/beneficiary-meal/beneficiary-meal.component';
import { BeneficiaryReferralComponent } from '@pages/myspace/beneficiary-referral/beneficiary-referral.component';
import { BeneficiaryEvaluationTerminationComponent } from '@pages/myspace/beneficiary-evaluation-termination/beneficiary-evaluation-termination.component';
import { BeneficiaryReferralDetailsComponent } from '@pages/myspace/beneficiary-referral-details/beneficiary-referral-details.component';
import { BeneficiarySearchComponent } from '@pages/myspace/beneficiary-search/beneficiary-search.component';
import { CommunityDialogueComponent } from '@pages/myspace/community-dialogue/community-dialogue.component';
import { PsychoeducationDetailsComponent } from '@pages/myspace/psychoeducation-details/psychoeducation-details.component';
import { TrainingComponent } from '@pages/myspace/training/training.component';
import { TrainingPrePostEvaluationComponent } from '@pages/myspace/training-pre-post-evaluation/training-pre-post-evaluation.component';
import { TrainingStastisfactionEvaluationComponent } from '@pages/myspace/training-stastisfaction-evaluation/training-stastisfaction-evaluation.component';
import { DtbOverviewComponent } from '@pages/management/dtb-overview/dtb-overview.component';
import { DtbSubmissionComponent } from '@pages/management/dtb-submission/dtb-submission.component';
import { UserLogComponent } from '@pages/logs/user-log/user-log.component';
import { ErrorLogComponent } from '@pages/logs/error-log/error-log.component';
import { IndicatorGroupComponent } from '@pages/settings/indicator-group/indicator-group.component';
import { DtbSubmissionListComponent } from '@pages/management/dtb-submission-list/dtb-submission-list.component';
import { DtbApprovedListComponent } from '@pages/management/dtb-approved-list/dtb-approved-list.component';
import { FinalizedSubmissionsComponent } from '@pages/management/finalized-submissions/finalized-submissions.component';
import { PortifolioSummaryComponent } from '@pages/portifolio-summary/portifolio-summary.component';
import { GrantTargetComponent } from '@pages/grant-target/grant-target.component';
import { ProfileComponent } from '@pages/user/profile/profile.component';
import { RejectedSubmissionsComponent } from '@pages/management/rejected-submissions/rejected-submissions.component';
import { SortDirective } from './directive/sort.directive';
import { PrintErrorComponent } from '@modules/print-error/print-error.component';
import { GrantCreateConfigurationsComponent } from '@pages/grant/grant-create-configurations/grant-create-configurations.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DeviceManagerComponent } from '@pages/user/device-manager/device-manager.component';
import { FilterByCategoryPipe } from '@pages/user/role/category-filter.pip';
import { DefaultImageDirective } from '@modules/main/header/user/default-image.directive';
import { UnauthorizedComponent } from '@pages/errors/unauthorized/unauthorized.component';
import { ActivityBaselineTargetComponent } from '@pages/grant/targetcalculation/activity-baseline-target/activity-baseline-target.component';
import { ActivityProviceWiseTargetComponent } from '@pages/grant/targetcalculation/activity-provice-wise-target/activity-provice-wise-target.component';
import { ActivityProviceWiseTeamComponent } from '@pages/grant/targetcalculation/activity-provice-wise-team/activity-provice-wise-team.component';
import { ActivitySessionTargetComponent } from '@pages/grant/targetcalculation/activity-session-target/activity-session-target.component';
import { ActivityIndividualTargetComponent } from '@pages/grant/targetcalculation/activity-individual-target/activity-individual-target.component';
import { Isp3Component } from '@pages/grant/isp3/isp3.component';
import { LfaComponent } from '@pages/grant/lfa/lfa.component';
import { AprFinalizationComponent } from '@pages/grant/apr-finalization/apr-finalization.component';
import { AprPreviewComponent } from '@pages/grant/apr-preview/apr-preview.component';
import { TargetCalcComponent } from '@pages/grant/target-calc/target-calc.component';
import { CommunityDialogueFollowupsComponent } from '@pages/myspace/community-dialogue-followups/community-dialogue-followups.component';
import { TrainingParticipantsComponent } from '@pages/myspace/training-participants/training-participants.component';
import { TrainingRegistrationFormComponent } from '@pages/myspace/training-registration-form/training-registration-form.component';
import { BeneficiaryTabServicesComponent } from '@pages/myspace/beneficiary-tab-services/beneficiary-tab-services.component';
import { KoboComponent } from '@pages/kobo/kobo.component';
import { AllSubmittedComponent } from '@pages/management/dtb-overview/submitted-beneficiary-details/submitted-beneficiary-details.component';
import { SubmittedPsychoEducationDetailsComponent } from '@pages/management/dtb-overview/submitted-psycho-education-details/submitted-psycho-education-details.component';
import { SubmittedCommunityDialogueDetailsComponent } from '@pages/management/dtb-overview/submitted-community-dialogue-details/submitted-community-dialogue-details.component';
import { SubmittedTrainingsDetailsComponent } from '@pages/management/dtb-overview/submitted-trainings-details/submitted-trainings-details.component';
import { SettingComponent } from '@pages/user/setting/setting.component';
import {  AprOverviewComponent } from '@pages/apr-overview/apr-overview.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        ProfileComponent,
        SettingComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        UserHomeComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        AprOverviewComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        UserHomeComponent,
        TrainingHomeComponent,
        DepartmentHomeComponent,
        AdministrativeHomeComponent,
        MyspaceHomeComponent,
        GrantHomeComponent,
        PermissionComponent,
        RoleComponent,
        BlockedIpComponent,
        DisaggregationComponent,
        GrantCreateComponent,
        GrantHomeComponent,
        GrantListComponent,
        GrantConfigureComponent,
        GrantDetailsComponent,
        GrantUpdateComponent,
        GrantStatusComponent,
        AssessmentComponent,
        BeneficiaryComponent,
        ReferralComponent,
        KitDistributionComponent,
        SubmittedKitDistributionDetailsComponent,
        SubmittedPsychoEducationDetailsComponent,
        PsychoeducationComponent,
        TrainingHomeComponent,
        EquipComponent,
        DtbComponent,
        AllSubmittedComponent,
        BeneficiaryServicesComponent,
        BeneficiaryDetailsComponent,
        BeneficiaryActivityComponent,
        BeneficiaryMealComponent,
        BeneficiaryReferralComponent,
        BeneficiaryEvaluationTerminationComponent,
        BeneficiaryReferralDetailsComponent,
        BeneficiarySearchComponent,
        CommunityDialogueComponent,
        SubmittedCommunityDialogueDetailsComponent,
        PsychoeducationDetailsComponent,
        TrainingComponent,
        TrainingPrePostEvaluationComponent,
        TrainingStastisfactionEvaluationComponent,
        DtbOverviewComponent,
        DtbSubmissionComponent,
        SubmittedTrainingsDetailsComponent,
        UserLogComponent,
        ErrorLogComponent,
        IndicatorGroupComponent,
        DtbSubmissionListComponent,
        DtbApprovedListComponent,
        FinalizedSubmissionsComponent,
        PortifolioSummaryComponent,
        GrantTargetComponent,
        ProfileComponent,
        RejectedSubmissionsComponent,
        SortDirective,
        PrintErrorComponent,
        GrantCreateConfigurationsComponent,
        DeviceManagerComponent,
        FilterByCategoryPipe,
        DefaultImageDirective,
        UnauthorizedComponent,
        ActivityBaselineTargetComponent,
        ActivityProviceWiseTargetComponent,
        ActivityProviceWiseTeamComponent,
        ActivitySessionTargetComponent,
        ActivityIndividualTargetComponent,
        Isp3Component,
        LfaComponent,
        AprFinalizationComponent,
        AprPreviewComponent,
        TargetCalcComponent,
        CommunityDialogueFollowupsComponent,
        TrainingParticipantsComponent,
        TrainingRegistrationFormComponent,
        BeneficiaryTabServicesComponent,
        KoboComponent
    ],
    imports: [
        ProfabricComponentsModule,
        CommonModule,
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TranslocoRootModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgTempusdominusBootstrapModule,
        NgSelectModule,


        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
          ],
    providers: [
        {
                   provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorInterceptor, multi:true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
