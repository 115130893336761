<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Targets for {{activity?.title}}</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Targets</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <div class="row">
        <button type="button" class="col-6 btn btn-warning btn-sm" (click)="addObjectClicked()" style="float: left;">Activity General Target</button>
        <button type="button" class="col-6 btn btn-danger btn-sm" (click)="addSecondObjectClicked()">Disaggregation Based Target</button>
      </div>

        <table class="table table-sm  table-responsive" *ngIf="!addObj && !addSecondObj">
          <thead>
            <tr>
              <!-- <th scope="col">#</th> -->
              <th scope="col"># of Teams</th>
              <th scope="col">Target for Sessions</th>
              <th scope="col">Target for Group Sessions</th>
              <th scope="col">Target for One-to-one Sessions</th>
              <th scope="col">Action</th>

            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.province.name}}</td>
              <td>{{item.number_of_team}}</td>
              <td>{{item.number_of_sessions}}</td>
              <td>{{item.number_of_group_sessions}}</td>
              <td>{{item.number_of_onetoone}}</td>
              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)">Details</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

          <div class="row">
          <div class="mb-3 col-lg-6">
            <label  class="form-label">Provinces</label>

            <ng-select [items]="provinces"
              bindLabel="name"
              bindValue="id"
              formControlName="adminsitrative_org_id"
              >
           </ng-select>
          </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Number of Teams</label>
            <input type="number" formControlName="number_of_team" class="form-control" placeholder="">
          </div>


          <div class="mb-3 col-lg-6">
            <label  class="form-label">Target for Sessions</label>
            <input type="number" formControlName="number_of_sessions" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Target for Group Sessions</label>
            <input type="number" formControlName="number_of_group_sessions" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Target for One-to-One</label>
            <input type="number" formControlName="number_of_onetoone" class="form-control" placeholder="">
          </div>



        </div>

          <div class="row">
            <div class="col-6">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          <div class="col-6">

        </div>

          </div>

        </form>




        <form [formGroup]="formGroup" *ngIf="addSecondObj" (ngSubmit)="submit()">

          <div class="row">
          <div class="mb-3 col-lg-6">
            <label  class="form-label">Provinces</label>

            <ng-select [items]="provinces"
            bindLabel="name"
            bindValue="id"
            formControlName="administrative_org_id"
            >
           </ng-select>
          </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Number of Teams</label>
            <input type="number" formControlName="number_of_team" class="form-control" placeholder="">
          </div>


          <div class="mb-3 col-lg-6">
            <label  class="form-label">Target for Sessions</label>
            <input type="number" formControlName="number_of_sessions" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Target for Group Sessions</label>
            <input type="number" formControlName="number_of_group_sessions" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-6">
            <label  class="form-label">Target for One-to-One</label>
            <input type="number" formControlName="number_of_onetoone" class="form-control" placeholder="">
          </div>
        </div>

          <div class="row">
            <div class="col-6">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          <div class="col-6">

        </div>

          </div>

        </form>

      </div>
  </div>
</section>
