<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Roles</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Roles</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->
<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">

        <button type="button" class="col-lg-2 mb-1 btn btn-primary btn-sm" (click)="addObjectClicked()">Add New Role</button>
        <table class="table table-sm  table-bordered table-responsive-sm table-responsive-md" *ngIf="!addObj">
            <tr class="bg-primary">
              <td scope="col">#</td>
              <td [appSort]="objs" data-order="desc" data-name="name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Name</td>
              <td [appSort]="objs" data-order="desc" data-name="guard_name" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Guard</td>
              <td scope="col">Action</td>

            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs; let i = index">
              <th scope="row">{{i+1}}</th>
              <td>{{item.name}}</td>
              <td>{{item.guard_name}}</td>
              <td>
                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <!-- <button type="button" class="btn btn-warning" (click)="viewPerms(item)">Permissions</button> -->
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

          <div class="mb-3">
            <label  class="form-label">Role Name</label>
            <input type="type" formControlName="name" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('name')"></print-error>

          </div>

            <label  class="form-label">Permissions</label>
          <!-- <ng-select [items]="permissions"
              bindLabel="name"
              bindValue="id"
              groupBy="category"
              formControlName="permissions"
              [multiple]="true"
              [closeOnSelect]="false"
              [virtualScroll]="true"
              [searchable]="true"
              [clearable]="true"
              [editableSearchTerm] ="true">

            </ng-select> -->


              <div class="row">
                <div class="col-md-4 col-sm-12" *ngFor="let category of categories">
                  <div class="card bg-light">
                    <div class="card-body">
                  <h5 class="text-primary">{{category}}</h5>
                  <div *ngFor="let item of permissions | filterByCategory: category">
                    <input type="checkbox" [value]="item.name" (change)="onCheckChange($event)">
                    {{item.name}}
                  </div>
                  </div>
                  </div>
                </div>




        <!-- <ng-select formControlName="permissions"
             [items]="permissions"
             bindLabel="name"
             bindValue="id"
             groupBy="category"
             [multiple]="true"
             [closeOnSelect]="false"
             >
             <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="item-{{index}}" [checked]="isSelected(item.value)" />
                <label class="form-check-label" for="item-{{index}}">
                  {{item.name}}
                </label>
              </div>
            </ng-template>

            </ng-select> -->
          <!-- <print-error [control]="formGroup.get('permissions')"></print-error> -->
</div>

          <div class="row">
            <div class="col-lg-3">
             <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
                Submit
              </button>
          </div>

          </div>

        </form>


      </div>
  </div>
</section>

