<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Administratives</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Administratives</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">
      <button type="button" class="col-lg-2 mb-1 btn btn-primary btn-sm" (click)="addObjectClicked()">Add
        District</button>
      <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

        <tr class="bg-primary">

          <td [appSort]="objs" data-order="desc" data-name="name" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Name
          </td>
          <td [appSort]="objs" data-order="desc" data-name="namefa" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Name Dr
          </td>
          <td [appSort]="objs" data-order="desc" data-name="namepa" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Name Ps
          </td>
          <td [appSort]="objs" data-order="desc" data-name="type" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Type
          </td>
          <td scope="col">Action</td>

        </tr>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of objs">
            <!-- <th scope="row">{{item.id}}</th> -->
            <td>{{item.name}}</td>
            <td>{{item.namefa}}</td>
            <td>{{item.namepa}}</td>
            <td>{{item.type}}</td>

            <td>


              <div class="btn-group" role="group">
                <!-- <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button> -->
                <button type="button" class="btn btn-primary" (click)="showDistricts(item)">Districts</button>
                <!-- <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <hr />

      <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-lg-3">
            <label class="form-label">Province </label>
            <ng-select [items]="provinces" bindLabel="name" bindValue="id" formControlName="parent_id">
            </ng-select>
          </div>
          <div class="col-lg-3">
            <label class="form-label">District Name En</label>
            <input type="type" formControlName="name" class="form-control">
          </div>
          <div class="col-lg-3">
            <label class="form-label">District Name Dr</label>
            <input type="type" formControlName="namefa" class="form-control">
          </div>
          <div class="col-lg-3">
            <label class="form-label">District Name Ps</label>
            <input type="type" formControlName="namepa" class="form-control">
          </div>
          <div class="col-lg-3 mt-1">
            <button type="submit" [disabled]="formGroup.invalid" class="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
