import { Component, Input } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-isp3',
  templateUrl: './isp3.component.html',
  styleUrls: ['./isp3.component.scss']
})
export class Isp3Component {
  @Input() grantId;
  grant: any;
  formGroup: UntypedFormGroup;
  activities: any;
  isp3s: any;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder : FormBuilder
    ) {
        // console.log('id', this.id);
        this.apiService.getGrant(this.grantId).subscribe((response) => {
          this.grant = response;
        });
    }


ngOnInit() {
    this.formGroup = this.formBuilder.group({
        grant_id: ['',[]],
        activities: [null,[Validators.required]],
        area: ['', [Validators.required]],
      });


      this.loadData();
    }

    loadData(){
      this.apiService.getGrantActivities(this.grantId).subscribe(response => {
        this.activities = response;
      });

      this.apiService.getGrantIsp3(this.grantId).subscribe(response => {
        this.isp3s = response;
        console.log(this.isp3s);

      });
    }


    submit(){
      this.formGroup.controls['grant_id'].setValue(this.grantId);

      this.apiService.createIsp3(this.formGroup.value).subscribe(response => {
        this.toastrService.success("Registration succeeded");
        this.formGroup.reset();
        this.loadData();
      }, (e) => {
        this.toastrService.error("Registration failed");
      })

    }

    getActivities(acts:Array<any>){
      const values = [];
      acts.forEach(act => {
        values.push(act.title);
      });

      return values;
    }


    calculateRowSpan(item: any, currentIndex: number): number {
      let rowSpan = 1;

      // Iterate through previous rows to find consecutive rows with the same goaltitle
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (this.isp3s[i].area === item.area) {
          rowSpan++;
        } else {
          break; // Break the loop when a different goaltitle is encountered
        }
      }

      return rowSpan;
    }


  }
