<div class="content-header">
  <div class="container">
    <div class="row mb-2">
      <!-- /.col -->
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <div class="col-lg-3 col-6">
        <div class="small-box bg-primary">
          <div class="inner">
            <h3>{{core_stats?.grants}}</h3>
            <p>Grants</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="small-box bg-success">
          <div class="inner">
            <h3>{{core_stats?.beneficiaries}}</h3>
            <p>Beneficiaries</p>
          </div>
          <div class="icon">
            <i class="ion ion-stats-bars"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="small-box bg-primary">
          <div class="inner">
            <h3>{{core_stats?.users}}</h3>
            <p>Users</p>
          </div>
          <div class="icon">
            <i class="ion ion-person-add"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="small-box bg-success">
          <div class="inner">
            <h3>{{core_stats?.departments}}</h3>
            <p>Departments</p>
          </div>
          <div class="icon">
            <i class="ion ion-pie-graph"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- /.row -->

    <div class="row">
      <div class="col-8 col-lg-8">
        <table class="table table-sm table-bordered">
          <thead>
            <tr class="bg-primary">
              <th scope="col">Code</th>
              <th scope="col">Title</th>
              <th scope="col">Donor</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of grants; let i = index"
              [ngStyle]="{'color': (item.status=='Ongoing') ? '#17a2b8' : 'black'}">
              <td scope="row">{{item.code}}</td>
              <td>{{item.title}}</td>
              <td>{{item.donor}}</td>
              <td>{{item.start_date | date}}</td>
              <td>{{item.end_date | date}}</td>
              <td>{{item.status}}</td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-primary" (click)="details(item)">Details</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-4 col-lg-4">
        <table class="table table-sm table-bordered">
          <thead>
            <tr class="bg-primary">
              <th scope="col">Name</th>
              <th scope="col">Code</th>
            </tr>
          </thead>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of departments">
              <td>{{item.name}}</td>
              <td>{{item.code}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Add a row for the pie chart -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Project Status</h3>
          </div>
          <div class="card-body">
            <canvas id="pieChart" class="chart-height"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
