<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">{{editObj ? 'Edit Project' : 'Create Project'}}</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/grant/overview">Grants</a></li>
          <li class="breadcrumb-item active">{{editObj ? 'Edit Project' : 'Create Project'}}</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">

        <div class="row">
          <div class="mb-3 col-lg-3">
            <label class="fw-light">Project Code</label>
            <input type="type" formControlName="code" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('code')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="fw-light">Project Title</label>
            <input type="text" formControlName="title" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('title')"></print-error>
          </div>
          <div class="mb-3 col-lg-3">
            <label class="fw-light">Donor</label>
            <input type="text" formControlName="donor" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('donor')"></print-error>

          </div>
          <div class="mb-3 col-lg-3">
            <label class="fw-light">Status</label>
            <select class="form-control" formControlName="status">
              <option value="Ongoing">Ongoing</option>
              <option value="Awaiting">Awaiting</option>
              <option value="Closed">Closed</option>
              <option value="Planned" selected>Planned</option>
            </select>
            <print-error [control]="formGroup.get('status')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="fw-light">Start Date</label>
            <input type="date" formControlName="start_date" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('start_date')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="fw-light">End Date</label>
            <input type="date" formControlName="end_date" class="form-control" placeholder="">
            <print-error [control]="formGroup.get('end_date')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="fw-light">Reporting Type</label>
            <select class="form-control" formControlName="reporting_type">
              <option value="One Time">One Time</option>
              <option value="Monthly" selected>Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Annual">Annual</option>
            </select>
            <print-error [control]="formGroup.get('reporting_type')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="fw-light">Project Manager</label>
            <ng-select [items]="users" bindLabel="name" bindValue="id" formControlName="project_manager">
            </ng-select>
            <print-error [control]="formGroup.get('project_manager')"></print-error>

          </div>


          <div class="mb-3 col-lg-3">
            <label class="fw-light">Provinces</label>
            <ng-select [items]="provinces" bindLabel="name" bindValue="id" formControlName="provinces"
              [multiple]="true">
            </ng-select>
            <print-error [control]="formGroup.get('provinces')"></print-error>

          </div>

          <div class="mb-3 col-lg-3">
            <label class="fw-light">Departments</label>
            <ng-select [items]="departments" bindLabel="name" bindValue="id" formControlName="departments"
              [multiple]="true">
            </ng-select>
            <print-error [control]="formGroup.get('departments')"></print-error>
          </div>
          <div class="mb-3 col-lg-6">
            <label class="fw-light">Project Description</label>
            <textarea type="text" formControlName="description" class="form-control" rows="5"></textarea>
            <print-error [control]="formGroup.get('discription')"></print-error>
          </div>

          <!-- <div class="mb-3 col-lg-4">
            <label  class="fw-light">Project Manager</label>
            <input type="text" formControlName="project_manager" class="form-control" placeholder="">
          </div> -->
          <div class="col-lg-2">
            <button type="submit" [disabled]="formGroup.invalid" class="form-control btn-primary">
              Submit
            </button>
          </div>
        </div>

      </form>


    </div>
  </div>
</section>
