import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-stastisfaction-evaluation',
  templateUrl: './training-stastisfaction-evaluation.component.html',
  styleUrls: ['./training-stastisfaction-evaluation.component.scss']
})
export class TrainingStastisfactionEvaluationComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  public formGroup: UntypedFormGroup;
  public isLoading :boolean = false;

  addObj: boolean = false;
  editObj: any;
  grants: any;
  provinces: any;
  districts: any;
  trainings: any;
  response: any;
  objs: any;

  // @Input() partcipantId:any;


  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',[]],
      participant_id: ['',[]],
      explain_in_one_word: ['',[]],
      useful_a: ['',[]],
      useful_b: ['',[]],
      could_be_better_a: ['',[]],
      could_be_better_b: ['',[]],
      informative_level: ['',[Validators.required]],
      usefulness_level: ['',[Validators.required]],
      understaning_level: ['',[Validators.required]],
      relevance_level: ['',[Validators.required]],
      accessability_level: ['',[Validators.required]],
      percentage_of_positive_evaluation: ['',[Validators.required]],
      participant_code: ['',[Validators.required]],
      remarks: ['',[]],
    });

      this.loadData();
  }

  loadData(){
    // this.apiService.getAllTrainingEvaluation().subscribe(response => {
    //   // console.log("response", response);

    //   this.objs = response;
    // });
    this.apiService.getAllTraining().subscribe(response => {
      this.trainings = response;
    })

    this.apiService.getAllTrainingEvaluation().subscribe(response => {
      this.response = response.data;
      this.objs = response.data.data;
    });
  }

    filter(text:any)
  {
      text = text.toLowerCase();
      this.response.data =  this.response.data.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] != null &&
                 item[key].toString().toLowerCase().includes(text);
        });
      });

      if(!text){
        this.response.data = this.objs;
      }
  }

  clickLink(url:string)
  {
    const location = this.apiService.parseUrl(url);
    const path = location.pathname +location.search;

    this.apiService.getPaginationItems(path).subscribe(response=>{
      this.response = response.data;
      this.objs = response.data.data;
    });
  }


  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteTrainingEvaluation(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);


      this.editObj = item;
    }
  }


  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);
    // this.formGroup.controls['participant_id'].setValue(this.partcipantId);

    console.log(this.formGroup.value);


    this.apiService.createTrainingEvaluation(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    })

  }

}

