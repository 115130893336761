import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  public formGroup: FormGroup;
  public isLoading :boolean = false;
  objs: any;

  addObj: boolean = false;
  editObj: any;
  permissions: any;
  categories: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private fb: FormBuilder) {


      }

  ngOnInit() {
      this.formGroup = new UntypedFormGroup({
          id: new UntypedFormControl(null,[]),
          name: new UntypedFormControl(null, Validators.required),
          permissions: new FormArray([], [Validators.required]),

      });

      this.loadData();
  }

  loadData(){
    this.apiService.getRoles().subscribe(response => {
      // console.log("roles", response);
      this.objs = response;
    });

    this.apiService.getAllPermissions().subscribe(response => {
       console.log("perms", response);
       this.permissions= response.data.data;

       this.categories = this.getCategories(this.permissions);
        // console.log(this.categories); // ["menu", "other"]
    })
  }

  addObjectClicked(){
    this.addObj = !this.addObj;
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteRole(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  isSelected(value: any): boolean {
    return this.formGroup.controls.permissions.value.includes(value);
  }

  onCheckChange(event) {
    const formArray: FormArray = this.formGroup.get('permissions') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onItemSelect(value: any, event: any): void {
    const selected = this.formGroup.controls.permissions.value;
    if (event.target.checked) {
      this.formGroup.controls.permissions.setValue([...selected, value]);
    } else {
      this.formGroup.controls.permissions.setValue(selected.filter((item: any) => item !== value));
    }
  }

  viewPerms(item:any){
    const names = item.permissions.map(item => item.name );
    window.alert(names);
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.addObj = true;
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['name'].setValue(item.name);
      this.formGroup.controls['permissions'].setValue(this.getIds(item.permissions));
      this.editObj = item;

      // this.apiService.updateRole(item.id, this.formGroup.value).subscribe(response => {
      //   this.toastrService.success("Update Succeeded");
      //   this.loadData();
      // }, (e) => {
      //   this.toastrService.error("Updated Failed!");
      // })
    }
  }


  submit(){

    // this.isLoading = true;
    // console.log("data", this.formGroup.value);

    this.apiService.createRole(this.formGroup.value).subscribe(response => {
      this.isLoading= false;
      // console.log(response);
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj= false;
      this.loadData();

    }, (e) => {
      this.toastrService.error("Registration failed! You have entered existing name for the role. Role name should unique!");
      this.isLoading = false;
    })

  }

  getRoles(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.name + "\n");
    });

    return values;
  }

  getCategories(permissions:Array<any>){
    let categories = permissions.map(item => item.category) // get an array of categories
    .filter((value, index, self) => self.indexOf(value) === index) // filter out the duplicates
    .sort((a, b) => b.length - a.length); // optionally, sort the categories alphabetically
    return categories;
  }

  getCommaSeparatedNames(items: any[], category: string): string {
    if (!items || !category) {
      return '';
    }
    // filter the items by category and get an array of names
    let names = items.filter(item => item.category === category).map(item => item.name);
    // join the names by commas and return the result
    return names.join(',');
  }

  getIds(roles:Array<any>){
    const values = [];
    roles.forEach(role => {
      values.push(role.id);
    });

    return values;
  }

}
