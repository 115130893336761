<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1 class="m-0 text-dark">Training Evaluation</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item active">Evaluation</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->


<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">


        <form [formGroup]="formGroup" (ngSubmit)="submit()">

          <div class="row">
            <div class="col-lg-3">
              <label  class="form-label">Beneficiary Code</label>
            </div>
            <div class="col-lg-9">
              <input type="text" formControlName="participant_code" class="form-control" placeholder="">
            </div>
          </div>

          <hr>


          <div class="row">
            <div class="col-lg-3">
              <label  class="form-label">What is one word you would use to describe overall training?</label>
            </div>
            <div class="col-lg-9">
              <input type="text" formControlName="explain_in_one_word" class="form-control" placeholder="">
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3">
              <label  class="form-label">What were two things you found most interesting or useful?</label>
            </div>
            <div class="col-lg-9">
              <input type="text" formControlName="useful_a" class="form-control" placeholder="A: ">
              <input type="text" formControlName="useful_b" class="form-control" placeholder="B: ">
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3">
              <label  class="form-label">What were two things that could have been better?</label>
            </div>
            <div class="col-lg-9">
              <input type="text" formControlName="could_be_better_a" class="form-control" placeholder="A: ">
              <input type="text" formControlName="could_be_better_b" class="form-control" placeholder="B: ">
            </div>
          </div>

          <hr>


          <div class="row">



          <div class="mb-3 col-lg-4">
            <label  class="form-label">Informative Level</label>
            <select class="form-control" formControlName="informative_level" >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>


          <div class="mb-3 col-lg-4">
            <label  class="form-label">Usefulness Level</label>
            <select class="form-control" formControlName="usefulness_level" >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>


          <div class="mb-3 col-lg-4">
            <label  class="form-label">Understanding Level</label>
            <select class="form-control" formControlName="understaning_level" >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>


          <div class="mb-3 col-lg-4">
            <label  class="form-label">Relevance Level</label>
            <select class="form-control" formControlName="relevance_level" >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>


          <div class="mb-3 col-lg-4">
            <label  class="form-label">Accessibility Level</label>
            <select class="form-control" formControlName="accessability_level" >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>


          <div class="mb-3 col-lg-4">
            <label  class="form-label">Percentage of Positive Evaluation</label>
            <input type="number" formControlName="percentage_of_positive_evaluation" class="form-control" placeholder="">
          </div>



          </div>

          <div class="row">
            <div class="col-6">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          <div class="col-6">

        </div>

          </div>

        </form>


      </div>
  </div>
</section>
