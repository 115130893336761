<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Beneficiary Code:{{beneficiary?.beneficiary_code}}</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
          <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
          <li class="breadcrumb-item active">Services</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->


<!-- app.component.html -->
<div class="container-fluid mt-2">
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(1)" [ngClass]="currentStep == 1 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab1">Beneficiary</a>
        </li>

        <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(2)" [ngClass]="currentStep == 2 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab1">Activity</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(3)" [ngClass]="currentStep == 3 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab2">Referral</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(4)" [ngClass]="currentStep == 4 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab3">MEAL Tools</a>
        </li>

        <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(5)" [ngClass]="currentStep == 5 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab3">Evaluation and Termination</a>
        </li>

        <!-- <li class="nav-item">
          <a class="nav-link btn btn-link" (click)="changeCurrentStep(5)" [ngClass]="currentStep == 5 ? 'active' : ''"
            data-bs-toggle="tab" data-bs-target="#tab3">APR Finalization</a>
        </li> -->
      </ul>

    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <form #multiStepForm="ngForm">
            <div [ngSwitch]="currentStep">
              <!-- Step 1 -->

              <div *ngSwitchCase="1">

                <div class="row">
                  <div class="col-lg-8">

                  <div class="card">
                    <div class="card-body">
                        <ul class="list-group">

                            <li class="list-group-item"><strong>Grant / Province / District:</strong>{{beneficiary?.grant.code}} / {{beneficiary?.province.name}} / {{beneficiary?.district.name}}</li>
                            <li class="list-group-item"><strong>Site Code / Village / Health Facility :</strong>{{beneficiary?.site_code}} / {{beneficiary?.village}} / {{beneficiary?.health_facility}}</li>

                            <li class="list-group-item"><strong>Intervention Modality :</strong>{{beneficiary?.intervention_modality}}</li>


                            <li class="list-group-item"><strong>Registration Date:</strong>{{beneficiary?.registration_date | date}}</li>
                              <li class="list-group-item"><strong>Code:</strong>{{beneficiary?.beneficiary_code}}</li>

                              <li class="list-group-item"><strong>Name:</strong>{{beneficiary?.client_name}} {{beneficiary?.father_or_husband_name}}</li>

                              <li class="list-group-item"><strong>Age / Gender / Marital Status:</strong>{{beneficiary?.age}} / {{beneficiary?.gender}} / {{beneficiary?.marital_status}}</li>

                              <li class="list-group-item"><strong>Phone:</strong>{{beneficiary?.client_phone}}</li>
                              <li class="list-group-item"><strong>Household Status:</strong>{{beneficiary?.household_status_of_bnf}}</li>


                            <li class="list-group-item"><strong>Literacy:</strong>{{beneficiary?.literacy_level_of_bnf}}</li>
                            <li class="list-group-item"><strong>Disability Type:</strong>{{beneficiary?.disability_type}}</li>
                            <li class="list-group-item"><strong>GBV Survivor:</strong>{{beneficiary?.gbv_survivor}}</li>
                            <li class="list-group-item"><strong>Referred for Protection:</strong>{{beneficiary?.referred_for_protection}}</li>

                            <!-- Add more details as needed -->
                        </ul>
                    </div>
                </div>

                  </div>
                </div>

                <hr />
                <button type="button" class="btn btn-success ml-1" (click)="nextStep()">Next</button>
              </div>


              <div *ngSwitchCase="2">

                <p class="header-banner text-center">Adult Psychosocial Support</p>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Group
                        </label>
                        <input type="checkbox" #psychosocialGroup class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Individual
                        </label>
                        <input type="checkbox" #psychosocialIndividual class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="psychosocialGroup.checked" [pageTitle]="'Adult Group MHPSS consultations'" [beneficiaryId]="id" [activityType]="'GROUP'" [activityGroup]="'Adult Psychosocial Support'"></app-beneficiary-activity>
                  </div>
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="psychosocialIndividual.checked" [pageTitle]="'Adult Individual MHPSS consultations'"  [beneficiaryId]="id" [activityType]="'INDIVIDUAL'" [activityGroup]="'Adult Psychosocial Support'"></app-beneficiary-activity>
                  </div>
                </div>

                <hr>


                <p class="header-banner text-center">Child Psychosocial Support / Adolescent Wellbeing</p>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Group
                        </label>
                        <input type="checkbox" #childPsychosocialGroup class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Individual
                        </label>
                        <input type="checkbox" #childPsychosocialIndividual class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="childPsychosocialGroup.checked" [pageTitle]="'Child Group MHPSS consultations'"  [beneficiaryId]="id" [activityType]="'GROUP'" [activityGroup]="'Child Psychosocial Support / Adolescent Wellbeing'"></app-beneficiary-activity>
                  </div>
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="childPsychosocialIndividual.checked" [pageTitle]="'Child Individual MHPSS consultations'" [beneficiaryId]="id" [activityType]="'INDIVIDUAL'" [activityGroup]="'Child Psychosocial Support / Adolescent Wellbeing'"></app-beneficiary-activity>
                  </div>
                </div>


                <hr>


                <p class="header-banner text-center">Parenting Skills</p>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Group
                        </label>
                        <input type="checkbox" #parentalSkillsGroup class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Individual
                        </label>
                        <input type="checkbox" #parentalSkillsIndividual class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="parentalSkillsGroup.checked" [beneficiaryId]="id" [pageTitle]="'Group Parenting Skills Sessions'" [activityType]="'GROUP'" [activityGroup]="'Parenting Skills'"></app-beneficiary-activity>
                  </div>
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="parentalSkillsIndividual.checked" [beneficiaryId]="id" [pageTitle]="'Individual Parenting Skills Sessions'" [activityType]="'INDIVIDUAL'" [activityGroup]="'Parenting Skills'"></app-beneficiary-activity>
                  </div>
                </div>



                <hr>


                <p class="header-banner text-center">Child Care Practices (Stimulation Sessions)</p>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Group
                        </label>
                        <input type="checkbox" #childCareGroup class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-check">
                      <label class="custom-control custom-checkbox">
                        <label class="form-check-label">
                          Individual
                        </label>
                        <input type="checkbox" #childCareIndividual class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="childCareGroup.checked" [pageTitle]="'Child Care Practices (Group Stimulation Sessions)'" [beneficiaryId]="id" [activityType]="'GROUP'" [activityGroup]="'Child Care Practices (Stimulation Sessions)'"></app-beneficiary-activity>
                  </div>
                  <div class="col-lg-6" >
                    <app-beneficiary-activity *ngIf="childCareIndividual.checked" [pageTitle]="'Child Care Practices (1:1 Stimulation Sessions)'" [beneficiaryId]="id" [activityType]="'INDIVIDUAL'" [activityGroup]="'Child Care Practices (Stimulation Sessions)'"></app-beneficiary-activity>
                  </div>
                </div>


                <hr />
                <button type="button" class="btn btn-secondary mr-1" (click)="prevStep()">Previous</button>
                <button type="button" class="btn btn-success ml-1" (click)="nextStep()">Next</button>
              </div>


              <div *ngSwitchCase="3">

                <app-beneficiary-referral [beneficiaryId]="id"></app-beneficiary-referral>

                <hr />

                <button type="button" class="btn btn-secondary mr-1" (click)="prevStep()">Previous</button>
                <button type="button" class="btn btn-success" (click)="nextStep()">Next</button>
              </div>

              <!-- Step 4 -->
              <div *ngSwitchCase="4">

                <app-beneficiary-meal [beneficiaryId]="id"></app-beneficiary-meal>
                <hr />

                <button type="button" class="btn btn-secondary mr-1" (click)="prevStep()">Previous</button>
                <button type="button" class="btn btn-primary" (click)="nextStep()">Next </button>
              </div>


              <!-- Step 5 -->
              <div *ngSwitchCase="5">
                  <app-beneficiary-evaluation-termination [beneficiaryId]="id"></app-beneficiary-evaluation-termination>


                <hr />

                <button type="button" class="btn btn-secondary mr-1" (click)="prevStep()">Previous</button>
                <button type="button" class="btn btn-primary" (click)="nextStep()">Next</button>
              </div>

              <!-- <Step 5
                            <div *ngSwitchCase="5">
                              <h3>Step 5: Configure Targets</h3>
                              <button type="button" class="btn btn-secondary" (click)="prevStep()">Previous</button>
                              <button type="button" class="btn btn-primary" (click)="nextStep()">Next</button>
                            </div> -->

              <!-- Step 5 -->
              <!-- <div *ngSwitchCase="5">


                <button type="button" class="btn btn-secondary" (click)="prevStep()">Previous</button>
              </div> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
