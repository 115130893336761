import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { DataExchangeService } from './data-exchange.service';


@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    public BASE_URL="https://apr-actionagainsthunger.org/api"
    //  public BASE_URL="/api"
    //public BASE_URL="https://aah.idevelopgroup.com/api"
    public approvalMessage: any;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private httpClient: HttpClient,
        private dataExchangeService: DataExchangeService
      ) {}

    isIpBlocked(){
      return this.httpClient.post(`${this.BASE_URL}/checkblock`,[]);
    }

    loginByAuth(data: any) {
        let message = "";
        try {
            this.httpClient.post(`${this.BASE_URL}/login`, data).subscribe((response: any) => {
                console.log('Response:', response); // Log the response for debugging
                if (response.success) {
                    localStorage.setItem('token', response.success.token);
                    localStorage.setItem('email', response.success.email);
                    localStorage.setItem('roles', response.success.roles);
                    localStorage.setItem('permissions', response.success.permissions);
    
                    if (data.remember_me === true) {
                        localStorage.setItem('saved_email', response.success.email);
                        localStorage.setItem('saved_password', data.password);
                        localStorage.setItem('remember_me', data.remember_me.toString());
                    } else {
                        localStorage.setItem('saved_email', '');
                        localStorage.setItem('saved_password', '');
                        localStorage.setItem('remember_me', '');
                    }
    
                    this.getProfile();
                    this.router.navigate(['/']);
                    this.toastr.success('Login succeeded');
                    message = "Login Succeeded";
                } else if (response.message) {
                    this.approvalMessage = response.message;
                    message = response.message;
                    this.sendData(message);
                } else {
                    // Handle case where neither success nor message is present in response
                    message = "Unexpected response format.";
                    this.toastr.error(message);
                    this.sendData(message);
                }
            }, (error: any) => {
                console.error('Error:', error); // Log the error for debugging
                message = "Login failed! Username or Password is wrong!";
                this.toastr.error(message);
                this.sendData(message);
            });
        } catch (error: any) {
            console.error('Exception:', error); // Log the exception for debugging
            message = "Login failed! Username or Password is wrong!";
            this.toastr.error(message);
            this.sendData(message);
        }
    }
    

    sendData(text:string)
    {
      this.dataExchangeService.updateData({ message: text });
    }
  async registerByAuth({ email, password }) {
    try {
      const response = await this.httpClient.post(`${this.BASE_URL}/register`, { email, password }).toPromise();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }


  async loginByGoogle() {
    try {
      const response = await this.httpClient.post(`${this.BASE_URL}/login/google`, {}).toPromise();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByGoogle() {
    try {
      const response = await this.httpClient.post(`${this.BASE_URL}/register/google`, {}).toPromise();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByFacebook() {
    try {
      const response = await this.httpClient.post(`${this.BASE_URL}/login/facebook`, {}).toPromise();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByFacebook() {
    try {
      const response = await this.httpClient.post(`${this.BASE_URL}/register/facebook`, {}).toPromise();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

    async getProfile() {
            // this.user = await Gatekeeper.getProfile();
        await this.httpClient.get(`${this.BASE_URL}/details`).subscribe((response:any)=>{
            this.user = response.success;
        });
    }
    returnProfile() {
        return this.httpClient.get(`${this.BASE_URL}/details`);

        // return this.httpClient.get(`${this.BASE_URL}/details`).pipe(
        //     map((response: any) => {
        //       // transform the user data and prepend the storage prefix to the image path
        //       return {
        //         ...response.user,
        //         image: 'storage/' + response.image_path
        //       };
        //     })
        //   );
    }

    loadUserProfileImage(){
      return this.httpClient.get(`${this.BASE_URL}/users/loadimage`,  { responseType: 'blob'});
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem("email");
        localStorage.removeItem("roles");
        localStorage.removeItem("permissions");

        this.user = null;
        this.router.navigate(['/login']);
    }


}
