<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">List of DTB Submissions for Validation</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">List of DTB Submission for Validation</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">

      <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add Submission</button> -->
      <div class="row">
        <div class="col-lg-8"></div>
        <div class="input-group col-lg-4">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
          </div>
          <input class="input" (input)="filter($event.target.value)" class="form-control" placeholder="">
        </div>
      </div>

      <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered">

        <tr class="bg-primary">
          <td [appSort]="response?.data" data-order="desc" data-name="id" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>#
          </td>
          <td [appSort]="response?.data" data-order="desc" data-name="manager" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Manager
          </td>
          <td [appSort]="response?.data" data-order="desc" data-name="grant_id" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Project
          </td>
          <td [appSort]="response?.data" data-order="desc" data-name="province_id" scope="col"> <i
              class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
              [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Province
          </td>
          <td [appSort]="response?.data" data-order="desc" data-name="year" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Year
          </td>
          <td [appSort]="response?.data" data-order="desc" data-name="month" scope="col"> <i class="fa fa-sort-desc"
              aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Month
          </td>
          <td [appSort]="response?.data" data-order="desc" data-name="month" scope="col"> <i class="fa fa-sort-desc"
            aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
          <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
            [hidden]="!showAsc"></i>Dtb Name
        </td>
          <td [appSort]="response?.data" data-order="desc" data-name="validation_status" scope="col"> <i
              class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
              [hidden]="!showDesc"></i>
            <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
              [hidden]="!showAsc"></i>Status
          </td>
          <td scope="col">Action</td>

        </tr>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of response?.data; let i = index"
            [ngStyle]="{
              'background-color': 
                item.validation_status == 'SUBMITTED' ? '#fff' : 
                item.validation_status == 'VALIDATED' ? '#007bff' : 
                item.validation_status == 'FINALIZED' ? '#28a745' : 
                item.validation_status == 'REJECTED' ? 'red' : 'white'
            }">
            <th scope="row">{{response?.from == 1 ? i + 1 : response?.from + i}}</th>
            <td>{{item.manager.name}}</td>
            <td>{{item.grant.title}}</td>
            <td>{{item.province.name}}</td>
            <td>{{item.year}}</td>
            <td>{{item.month}}</td>
            <td>{{item.dtb_name}}</td>
            <td>{{item.validation_status}}</td>
            <td>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-success" *ngIf="item.validation_status == 'SUBMITTED'" (click)="validate(item)">Validate</button>
                <button type="button" class="btn btn-danger" *ngIf="item.validation_status == 'SUBMITTED'" (click)="reject(item)">Reject</button>
                <button type="button" class="btn btn-primary" (click)="download(item)">Download</button>
                <button type="button" class="btn btn-info" *ngIf="item.validation_status == 'REJECTED'" (click)="resend(item)">Resend</button>
              </div>
            </td>
          </tr>
        </tbody>
        
      </table>

      <div class="d-flex justify-content-center">
        <span *ngFor="let item of response?.links">
          <button (click)="clickLink(item.url ? item.url : response.prev_page_url)"
            [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)"
            class="btn btn-outline-primary" [innerHTML]="item.label"
            [style.background-color]="item.active? '#007bff' : ''"
            style="color:rgb(0, 0, 0); border: solid #ccc 1px;"></button>
        </span>
      </div>

    </div>
  </div>
</section>