import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    Route
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '@services/app.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private appService: AppService, private httpClient: HttpClient) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.getProfile();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    async getProfile() {
        if (this.appService?.user) {
          // console.log("User Exist");
            return true;
        }

        try {
          const token = localStorage.getItem("token");
          if(token){

            this.appService.returnProfile().subscribe((response: any) => {
              const profile = response.success;
              // // console.log("profile", profile);

              if(!profile){
                localStorage.removeItem("email");
                localStorage.removeItem("roles");
                localStorage.removeItem("permissions");

                localStorage.removeItem("token");
                this.router.navigateByUrl("/login");
              }
            });

            return true;
          }else{
            localStorage.removeItem("email");
            localStorage.removeItem("roles");
            this.router.navigateByUrl("/login");
          }
        } catch (error) {
            return false;
        }
    }
}
