<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Projects Status</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/grant/overview">Grants</a></li>
          <li class="breadcrumb-item active">Projects Status</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body">
      <table id="grantsTable" class="table table-sm table-bordered">
        <thead>
          <tr class="bg-primary">
            <th scope="col">Code</th>
            <th scope="col">Title</th>
            <th scope="col">Donor</th>
            <th scope="col">Manager</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of response.data" [ngStyle]="{'color': (item.status == 'Ongoing') ? '#17a2b8' : 'black'}">
            <td scope="row">{{item.code}}</td>
            <td>{{item.title}}</td>
            <td>{{item.donor}}</td>
            <td>{{item.manager.name}}</td>
            <td>{{item.start_date | date}}</td>
            <td>{{item.end_date | date}}</td>
            <td>{{item.status}}</td>
            <td>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-primary" (click)="details(item)">Details</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
