import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

import { FormGroup } from '@angular/forms';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-target-calc',
  templateUrl: './target-calc.component.html',
  styleUrls: ['./target-calc.component.scss']
})
export class TargetCalcComponent implements OnInit, AfterViewInit {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  @Input() grantId;
  grant: any;
  formGroup: FormGroup;
  activities: any;
  showtable: boolean;
  sessionDisaggregations: any;
  sessionFormStatus: any;
  individualFormStatus: any;
  dynamicForm: FormGroup;
  dynamicIndividualForm: FormGroup;
  provinceTargetForm: FormGroup;
  provinceTeamForm: FormGroup;

  individualDisaggregations: any;
  provinces: any;
  averageTarget: number;
  averageIndividualTarget: number;
  averageSessionTarget: number;
  enableManualForProvinceTarget: boolean =false;
  enabledIndividualDisaggregations: Array<string> = [];


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission
    }
  }


  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder : FormBuilder,
    ) {
        this.apiService.getGrant(this.grantId).subscribe((response) => {
          this.grant = response;
        });
    }



  ngAfterViewInit(): void {

  }

ngOnInit() {


    this.provinceTeamForm = this.formBuilder.group({});

    this.formGroup = this.formBuilder.group({
        id: ['',[]],
        grant_activity_id: [null,[Validators.required]],
        total_target: ['', [Validators.required]],
        baseline_target: ['', [Validators.required]],
      });
      this.loadData();


  }

  createForm(): void {
    const formGroupConfig = {};

    this.sessionDisaggregations?.forEach((disaggregation) => {
      const cleanedControlName = this.cleanControlName(disaggregation.name);
      formGroupConfig[cleanedControlName] = ['', ];
    });

    this.dynamicForm = this.formBuilder.group(formGroupConfig);
  }


  createIndividualDisaggregationForm(): void {
    const formGroupConfig = {};

    this.individualDisaggregations?.forEach((disaggregation) => {
      const cleanedControlName = this.cleanControlName(disaggregation.name);
      formGroupConfig[cleanedControlName] = ['',];
    });

    this.dynamicIndividualForm = this.formBuilder.group(formGroupConfig);
  }

  createProvinceTargetForm(): void {
    const formGroupConfig = {};

    this.provinces?.forEach((disaggregation) => {
      const cleanedControlName = this.cleanControlName(disaggregation.name);
      formGroupConfig[cleanedControlName] = ['',];
    });

    this.provinceTargetForm = this.formBuilder.group(formGroupConfig);
  }

  createProvinceTeamForm(): void {
    const formGroupConfig = {};

    this.provinces?.forEach((disaggregation) => {
      const cleanedControlName = this.cleanControlName(disaggregation.name);
      formGroupConfig[cleanedControlName] = ['',Validators.required];
    });

    this.provinceTeamForm = this.formBuilder.group(formGroupConfig);
  }

  cleanControlName(controlName: string): string {
    // Remove special characters and spaces
    return controlName.replace(/[^a-zA-Z0-9]/g, '');
  }


  loadData(){

    this.apiService.getGrantActivities(this.grantId).subscribe(response => {
      this.activities = response;
    });


    this.apiService.getIndicatorDisaggregationsByLevel("SESSION").subscribe(response => {
      this.sessionDisaggregations = response;
      this.createForm();
    });

    this.apiService.getIndicatorDisaggregationsByLevel("INDIVIDUAL").subscribe(response => {
      this.individualDisaggregations = response;
      this.createIndividualDisaggregationForm();
    });

    this.apiService.getGrantProvinces(this.grantId).subscribe(response => {
      this.provinces = response;
      console.log(this.provinces);
      this.createProvinceTargetForm();
      this.createProvinceTeamForm();

    });
  }


  submit(){

    // console.log("base",this.formGroup.value);
    // console.log("session",this.dynamicForm.value);
    // console.log("individual",this.dynamicIndividualForm.value);
    // console.log("province target",this.provinceTargetForm.value);
    // console.log("province team",this.provinceTeamForm.value);


    if(window.confirm("This is replace all target calculations of the selected activity. Are you sure to continue?")){

      const request = new FormData();
      request.append('base', JSON.stringify(this.formGroup.value));
      request.append('session', JSON.stringify(this.dynamicForm.value));
      request.append('individual', JSON.stringify(this.dynamicIndividualForm.value));
      request.append('province_target', JSON.stringify(this.provinceTargetForm.value));
      request.append('province_team', JSON.stringify(this.provinceTeamForm.value));

      this.apiService.createTargetCalc(request).subscribe(response => {
        this.toastrService.success("Registration succeeded");
        this.formGroup.reset();
        this.dynamicForm.reset();
        this.dynamicIndividualForm.reset();
        this.provinceTargetForm.reset();
        this.provinceTeamForm.reset();
        this.loadData();
      }, (e) => {
        this.toastrService.error("Registration failed");
      })

    }

  }

  showData(){
    this.showtable=!this.showtable;
  }

  enableSession(event:any){
    this.sessionFormStatus = event.target.value;
  }

  enableIndividual(event:any){
    this.individualFormStatus = event.target.value;
  }

  calculateAbsoluteAverage(){
    const totalTarget = this.formGroup.get('total_target')?.value;

    if(totalTarget){
      this.averageTarget = Math.ceil(totalTarget/this.provinces.length);
      this.assignValueToControlsOfProvinceTargets(this.averageTarget);
      // this.averageIndividualTarget = Math.ceil(totalTarget/this.individualDisaggregations.length);
      this.assignValueToControlsOfIndividualTargets(totalTarget);
      this.averageSessionTarget = Math.ceil(totalTarget/this.sessionDisaggregations.length);
      this.assignValueToControlsOfSessionTargets(this.averageSessionTarget);


    }
  }

  assignValueToControlsOfProvinceTargets(value: number) {
    // Iterate over the controls and set the value for each one
    Object.keys(this.provinceTargetForm.controls).forEach(controlName => {
      this.provinceTargetForm.get(controlName).setValue(value);
    });
  }

  assignValueToControlsOfSessionTargets(value: number) {
    // Iterate over the controls and set the value for each one
    Object.keys(this.dynamicForm.controls).forEach(controlName => {
      this.dynamicForm.get(controlName).setValue(value);
    });
  }


  // assignValueToControlsOfIndividualTargets(totalTarget: number) {
  //   Object.keys(this.dynamicIndividualForm.controls).forEach(controlName => {
  //     this.dynamicIndividualForm.get(controlName).setValue(value);
  //   });
  // }

  assignValueToControlsOfIndividualTargets(totalTarget: number) {
    let enabledFieldsCount = 0;

    // Loop through each control in the form
    Object.keys(this.dynamicIndividualForm.controls).forEach(controlName => {
      const control = this.dynamicIndividualForm.get(controlName);

      // Check if the control is enabled (not disabled/read-only)
      if (!control.disabled) {
        enabledFieldsCount++;
      }
    });

    // Calculate average only if there are enabled fields
    let averageValue = 0;
    if (enabledFieldsCount > 0) {
      averageValue = totalTarget / this.enabledIndividualDisaggregations.length;
    }

    // Set average value to enabled fields
    Object.keys(this.dynamicIndividualForm.controls).forEach(controlName => {
      const control = this.dynamicIndividualForm.get(controlName);

      // Set value only if the control is enabled
      const index = this.enabledIndividualDisaggregations.indexOf(controlName);
      if (index) {
        control.setValue(averageValue);
      }
    });
  }



  enableManualDataEntryForTargetProvince(){
    this.enableManualForProvinceTarget = true;
  }

  toggleInput(name: string, checked: boolean) {
    if (!checked) {
      // If checkbox is not checked, disable the input field
      this.dynamicForm.get(name).disable();
    } else {
      // If checkbox is checked, enable the input field
      this.dynamicForm.get(name).enable();
    }
  }

  toggleInputForIndividualDisaggregation(name: string, checked: boolean) {
    if (!checked) {
      // If checkbox is not checked, disable the input field
      this.dynamicIndividualForm.get(name).disable();
      this.enabledIndividualDisaggregations = this.enabledIndividualDisaggregations.filter(item => item !== name);
    } else {
      // If checkbox is checked, enable the input field
      this.dynamicIndividualForm.get(name).enable();
      this.enabledIndividualDisaggregations.push(name);
    }

    console.log(this.enabledIndividualDisaggregations);

  }
}
