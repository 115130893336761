<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Blocked IPs</h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Blocked IPs</li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
      <div class="card card-body">

        <div class="row">
          <div class="col-lg-4">
            <button type="button" class="btn btn-primary btn-sm" (click)="addObjectClicked()">Register
              New</button>
          </div>
          <div class="col-lg-4"></div>
          <div class=" input-group col-lg-4" *ngIf="!addObj">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
            </div>
            <input class="input" (input)="filter($event.target.value)" class="form-control"
              placeholder="Search....">
          </div>
        </div>

        <!-- <button type="button" class="col-lg-1 mb-1 btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button> -->
        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">
            <tr class="bg-primary">
              <td [appSort]="objs" data-order="desc" data-name="id" scope="col">                 <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>#</td>
              <td [appSort]="objs" data-order="desc" data-name="name" scope="col">                 <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Name</td>
              <td [appSort]="objs" data-order="desc" data-name="host" scope="col">                 <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Host</td>
              <td [appSort]="objs" data-order="desc" data-name="remarks" scope="col">                 <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</td>
              <td  scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of response?.data; let i=index">
              <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
              <td>{{item.name}}</td>
              <td>{{item.host}}</td>
              <td>{{item.remarks}}</td>
              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-center">
          <span *ngFor="let item of response?.links">
            <button (click)="clickLink(item.url ? item.url : response.prev_page_url)"
              [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)"
              class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''"
              style="color:rgb(0, 0, 0); border: solid #ccc 1px;"></button>
          </span>
        </div>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">



          <div class="row">

            <div class="mb-3 col-lg-4">
              <label  class="form-label">Name</label>
              <input type="type" formControlName="name" class="form-control">
              <print-error [control]="formGroup.get('name')"></print-error>

            </div>


            <div class="mb-3 col-lg-4">
              <label  class="form-label">IP Address</label>
              <input type="ip" formControlName="host" class="form-control" placeholder="128.16.1.1" pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
              <print-error [control]="formGroup.get('host')"></print-error>
            </div>


            <div class="mb-3 col-lg-4">
              <label  class="form-label">Remarks</label>
              <input type="text" formControlName="remarks" class="form-control" placeholder=".....">
              <print-error [control]="formGroup.get('remarks')"></print-error>

            </div>
            <div class="col-lg-3">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          <div class="col-6">

        </div>

          </div>

        </form>


      </div>
  </div>
</section>
