import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grant-create',
  templateUrl: './grant-create.component.html',
  styleUrls: ['./grant-create.component.scss']
})
export class GrantCreateComponent implements OnInit, AfterViewInit {
  public formGroup: UntypedFormGroup;
  public isLoading: boolean = false;
  addObj: boolean = false;
  editObj: any;
  provinces: any;
  departments: any;
  grantId: any;
  users: any;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder: FormBuilder,
    private activedRoute: ActivatedRoute
  ) {
    this.activedRoute.params.subscribe((params) => {
      this.grantId = params['id'];
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: ['',],
      code: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', []],
      donor: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      reporting_type: ['', [Validators.required]],
      status: ['', [Validators.required]],
      project_manager: ['', [Validators.required]],
      provinces: ['', [Validators.required]],
      departments: ['', [Validators.required]],
    });

    this.loadData();
  }

  loadData() {
    // Load all necessary data
    this.loadUsers();
    this.loadProvinces();
    this.loadDepartments();

    if (this.grantId) {
      this.apiService.getGrant(this.grantId).subscribe((response) => {
        this.editObj = response;
        this.populateForm();
      });
    }
  }

  loadUsers() {
    this.apiService.getAllUsers().subscribe(response => {
      this.users = response.data.data;
      this.formGroup.controls['project_manager'].setValue(this.users.name);
    });
  }

  loadProvinces() {
    this.apiService.getProvinces().subscribe(response => {
      this.provinces = response;
      console.log(this.provinces);
      // If the data was loaded after the grant, populate the form fields
      if (this.editObj) {
        this.formGroup.controls['provinces'].setValue(this.apiService.getIds(this.editObj.provinces));
      }
    });
  }

  loadDepartments() {
    this.apiService.getDepartments().subscribe(response => {
      this.departments = response;
      console.log(this.departments);
      // If the data was loaded after the grant, populate the form fields
      if (this.editObj) {
        this.formGroup.controls['departments'].setValue(this.apiService.getIds(this.editObj.departments));
      }
    });
  }

  populateForm() {
    this.formGroup.controls['id'].setValue(this.editObj.id);
    this.formGroup.controls['code'].setValue(this.editObj.code);
    this.formGroup.controls['title'].setValue(this.editObj.title);
    this.formGroup.controls['description'].setValue(this.editObj.description);
    this.formGroup.controls['donor'].setValue(this.editObj.donor);
    this.formGroup.controls['start_date'].setValue(this.editObj.start_date);
    this.formGroup.controls['end_date'].setValue(this.editObj.end_date);
    this.formGroup.controls['reporting_type'].setValue(this.editObj.reporting_type);
    this.formGroup.controls['status'].setValue(this.editObj.status);

    // Set the dropdown values only if the data is loaded
    if (this.users) {
      console.log(this.editObj.project_manager);
      
      this.formGroup.controls['project_manager'].setValue(this.editObj.project_manager);
    }
    if (this.provinces) {
      this.formGroup.controls['provinces'].setValue(this.apiService.getIds(this.editObj.provinces));
    }
    if (this.departments) {
      this.formGroup.controls['departments'].setValue(this.editObj.departments);
    }
  }

  ngAfterViewInit() {}

  submit() {
    this.apiService.createGrant(this.formGroup.value).subscribe(response => {
      this.isLoading = false;
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.addObj = false;
      this.loadData();
      this.router.navigate(['/grant/overview']);
    }, (e) => {
      this.toastrService.error("Registration failed");
      this.isLoading = false;
    });
  }
}
