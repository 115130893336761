<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Referral Details </h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active">Referral Details </li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add</button> -->


        <table class="table table-sm  table-bordered table-responsive-sm table-responsive-sm-md" >

          <tr>
            <td>Is there Consent Before Referral</td>
            <td>{{referral?.is_there_concern_before_referral}}</td>
            </tr>

            <tr>
            <td>Explain Cencern Before Referral....</td>
            <td>{{referral?.concern_before_referral}}</td>
            </tr>

            <tr>
            <td>In case you need further support, do you want to be referred to any other service provider for additional support?</td>
            <td>{{referral?.is_there_need_for_further_support}}</td>
            </tr>

            <tr>
            <td>I have read and understood the below information?</td>
            <td>{{referral?.read_and_understood_the_questions}}</td>
            </tr>

            <tr>
            <td>Case No (referring agency)</td>
            <td>{{referral?.case_no}}</td>
            </tr>

            <tr>
            <td>Referral Type</td>
            <td>{{referral?.referral_type}}</td>
            </tr>

            <tr>
            <td>Has consent / assent for this referral been provided by the person being referred for services</td>
            <td>{{referral?.has_customer_consent}}</td>
</tr>

<tr>
            <td>Why No Consent....</td>
            <td>{{referral?.why_client_not_provided_consent}}</td>
</tr>

            <tr>
            <td>Referred By Name</td>
            <td>{{referral?.referredby_name}}</td>
          </tr>
          <tr>
            <td>Referred By Agency</td>
            <td>{{referral?.referredby_agency}}</td>

          </tr>
          <tr>
            <td>Referred By Position</td>
            <td>{{referral?.referredby_position}}</td>
          </tr>
          <tr>
            <td>Referred By Phone</td>
            <td>{{referral?.referredby_phone}}</td>
          </tr>
          <tr>
            <td>Referred By Email</td>
            <td>{{referral?.referredby_email}}</td>
          </tr>
          <tr>
            <td>Referred By Address</td>
            <td>{{referral?.referredby_address}}</td>
            </tr>

            <tr>
            <td>Referred To Name</td>
            <td>{{referral?.referredto_name}}</td>

          </tr>
          <tr>
            <td>Referred To Agency</td>
            <td>{{referral?.referredto_agency}}</td>

          </tr>
          <tr>
            <td>Referred To Position</td>
            <td>{{referral?.referredto_position}}</td>

          </tr>
          <tr>
            <td>Referred To Phone</td>
            <td>{{referral?.referredto_phone}}</td>

          </tr>
          <tr>
            <td>Referred To Email</td>
            <td>{{referral?.referredto_email}}</td>

          </tr>
          <tr>
            <td>Referred To Address</td>
            <td>{{referral?.referredto_address}}</td>
              </tr>

              <tr>
            <td>Mental Health Alert</td>
            <td>{{referral?.mental_health_alert}}</td>
                </tr>
            <tr>
            <td>Mental Health Description</td>
            <td>{{referral?.mental_health_description}}</td>

            <tr>
            <td>Type of Service(s) Requested </td>
            <td>{{referral?.type_services_requested}}</td>
              </tr>
            <tr>
            <td>Other Services Requested</td>
            <td>{{referral?.type_services_requested_other}}</td>
              </tr>
            <tr>
            <td>Does the service provider accept referral?</td>
            <td>{{referral?.service_provider_accept_referral}}</td>
              </tr>
            <tr>
            <td>Why Referral Not Accepted</td>
            <td>{{referral?.why_referral_not_accepted}}</td>
              </tr>
            <tr>
            <td>Remarks</td>
            <td>{{referral?.remarks}}</td>
              </tr>




        </table>


      </div>
  </div>
</section>
