import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lfa',
  templateUrl: './lfa.component.html',
  styleUrls: ['./lfa.component.scss']
})
export class LfaComponent {

  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default


  @Input() id: any;
  grant: any;
  currentStep = 1;

  public formGroup: UntypedFormGroup;
  objs: any;


  public outcomeFormGroup: UntypedFormGroup;
  outcomes: any;


  public outputFormGroup: UntypedFormGroup;
  outputs: any;


  public activitiesFormGroup: UntypedFormGroup;
  activities: any;
  disaggregations: any;
  targetAssignment: boolean = false;
  targetActivity: any;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder : FormBuilder
      ) {


          this.apiService.getGrant(this.id).subscribe((response) => {
            this.grant = response;
          });
      }


  ngOnInit() {
      this.formGroup = new UntypedFormGroup({
          id: new UntypedFormControl(null,[]),
          grant_id: new UntypedFormControl(null,[]),
          title: new UntypedFormControl(null, Validators.required),
      });


      this.outcomeFormGroup = this.formBuilder.group({
        id: [''],
        grant_id: ['', []],
        title: ['', [Validators.required]],
        grant_goal_id: ['', [Validators.required]],
        baseline: [],
        target: [],
      });

      this.outputFormGroup = this.formBuilder.group({
        id: [''],
        grant_id: ['', []],
        title: ['', [Validators.required]],
        grant_outcome_id: ['', [Validators.required]],
        baseline: [],
        target: [],
        indicators: [],
      });

      this.activitiesFormGroup = this.formBuilder.group({
        id: [''],
        grant_id: ['', []],
        title: ['', [Validators.required]],
        grant_output_id: ['', [Validators.required]],
        indicators: ['', []],
        acf: [],
        total: [],
        implemented_by: [],
        disaggregations: ['', []],
        total_target: [],
        baseline_target: [],
      });

      this.loadData();
  }

  nextStep(): void {
    this.currentStep = this.currentStep+1;
  }

  prevStep(): void {
    this.currentStep = this.currentStep - 1;
  }

  changeCurrentStep(step:number){
    this.currentStep = step;
  }

  clearAll(){
    if(window.confirm("Are you sure to clear all project configuration?"))  {
      this.apiService.clearAllGrantConfiguration(this.id).subscribe(response => {
        this.objs = response;
        this.toastrService.success('All configurations are cleared.')
        this.loadData();
      },(e) => {
        this.toastrService.success('Operation Failed.')
      });
    }
  }



  approveAll(){
    if(window.confirm("Are you sure to approve all project configuration?"))  {
      this.apiService.approveAllGrantConfiguration(this.id).subscribe(response => {
        this.objs = response;
        this.toastrService.success('All configurations are approved.')
        this.loadData();
      },(e) => {
        this.toastrService.success('Operation Failed.')
      });
    }
  }

  getLength(items:any){
    return items.filter(item => item.status === 'Under_Review').length;
  }

  loadData(){
    this.apiService.getGrantGoals(this.id).subscribe(response => {
      this.objs = response;
    });

    this.apiService.getGrantOutcomes(this.id).subscribe(response => {
      this.outcomes = response;
    });

    this.apiService.getGrantOutputs(this.id).subscribe(response => {
      this.outputs = response;
      console.log("output", response);

    });

    this.apiService.getGrantActivities(this.id).subscribe(response => {
      this.activities = response;
    });

    this.apiService.getDisaggregations().subscribe(response => {
      this.disaggregations = response;
    });
  }

  delete(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteGrantGoals(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  deleteOutcome(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteGrantOutcome(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  deleteOutput(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteGrantOutputs(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  deleteActivity(item: any){
    if(window.confirm("Are you sure to delete?")){
      this.apiService.deleteGrantActivities(item.id).subscribe(response => {
        this.toastrService.success("Deletion Succeeded");
        this.loadData();
      }, (e) => {
        this.toastrService.error("Deletion Failed!");
      })
    }
  }

  edit(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.formGroup.controls['id'].setValue(item.id);
      this.formGroup.controls['title'].setValue(item.title);
      this.formGroup.controls['grant_id'].setValue(this.id);
    }
  }

  editOutcome(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.outcomeFormGroup.controls['id'].setValue(item.id);
      this.outcomeFormGroup.controls['title'].setValue(item.title);
      this.outcomeFormGroup.controls['grant_id'].setValue(this.id);
      this.outcomeFormGroup.controls['grant_goal_id'].setValue(item.goal.id);
      this.outcomeFormGroup.controls['baseline'].setValue(item.baseline);
      this.outcomeFormGroup.controls['target'].setValue(item.target);
    }
  }

  editOutput(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.outputFormGroup.controls['id'].setValue(item.id);
      this.outputFormGroup.controls['title'].setValue(item.title);
      this.outputFormGroup.controls['grant_id'].setValue(this.id);
      // this.outputFormGroup.controls['grant_outcome_id'].setValue(item.outcome.id);
      this.outputFormGroup.controls['baseline'].setValue(item.baseline);
      this.outputFormGroup.controls['target'].setValue(item.target);
    }
  }

  editActivity(item: any){
    if(window.confirm("Are you sure to edit?")){
      this.activitiesFormGroup.controls['id'].setValue(item.id);
      this.activitiesFormGroup.controls['title'].setValue(item.title);
      this.activitiesFormGroup.controls['grant_id'].setValue(this.id);
      // this.outputFormGroup.controls['grant_outcome_id'].setValue(item.outcome.id);
      this.activitiesFormGroup.controls['total_target'].setValue(item.total_target);
      this.activitiesFormGroup.controls['baseline_target'].setValue(item.baseline_target);

      this.activitiesFormGroup.controls['acf'].setValue(item.acf);
      this.activitiesFormGroup.controls['total'].setValue(item.total);


    }
  }

  submit(){
    this.formGroup.controls['grant_id'].setValue(this.id);
    this.apiService.createGrantGoal(this.formGroup.value).subscribe(response => {
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.loadData();
    }, (e) => {
      this.toastrService.error("Registration failed");
    })
  }

  submitOutcome(){
    this.outcomeFormGroup.controls['grant_id'].setValue(this.id);
    this.apiService.createGrantOutcome(this.outcomeFormGroup.value).subscribe(response => {
      this.toastrService.success("Registration succeeded");
      this.outcomeFormGroup.reset();
      this.loadData();
    }, (e) => {
      this.toastrService.error("Registration failed");
    })
  }

  submitOutput(){
    this.outputFormGroup.controls['grant_id'].setValue(this.id);
    this.apiService.createGrantOutput(this.outputFormGroup.value).subscribe(response => {
      this.toastrService.success("Registration succeeded");
      this.outputFormGroup.reset();
      this.loadData();
    }, (e) => {
      this.toastrService.error("Registration failed");
    })
  }


  submitActivity(){
    this.activitiesFormGroup.controls['grant_id'].setValue(this.id);
    this.apiService.createGrantActivities(this.activitiesFormGroup.value).subscribe(response => {
      this.toastrService.success("Registration succeeded");
      this.activitiesFormGroup.reset();
      this.loadData();
    }, (e) => {
      this.toastrService.error("Registration failed");
    })
  }

  setTarget(item: any){
    this.targetActivity = item;
    this.targetAssignment = !this.targetAssignment;

  }

}
