import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grant-details',
  templateUrl: './grant-details.component.html',
  styleUrls: ['./grant-details.component.scss']
})
export class GrantDetailsComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default
  objs: any;
  id: any;
  grant: any;
  outcomes: any;
  outputs: any;
  activities: any;


  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
    ){

      this.activedRoute.params.subscribe((params) => {
        this.id = params['id'];
        this.apiService.getGrant(this.id).subscribe((response) => {
          this.grant = response;
        });


      });}

  ngOnInit() {
      this.loadData();
  }

  loadData(){
    this.apiService.getGrantGoals(this.id).subscribe(response => {
      this.objs = response;
    });

    this.apiService.getGrantOutcomes(this.id).subscribe(response => {
      this.outcomes = response;
    });

    this.apiService.getGrantOutputs(this.id).subscribe(response => {
      this.outputs = response;
    });

    this.apiService.getGrantActivities(this.id).subscribe(response => {
      this.activities = response;
    });
  }



}


