import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AprFinalizationComponent } from '../apr-finalization/apr-finalization.component';
@Component({
  selector: 'app-grant-create-configurations',
  templateUrl: './grant-create-configurations.component.html',
  styleUrls: ['./grant-create-configurations.component.scss']
})
export class GrantCreateConfigurationsComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default


  id: any;
  grant: any;
  currentStep = 1;

  constructor(
      private toastrService: ToastrService,
      public apiService: ApiService,
      public router: Router,
      private activedRoute: ActivatedRoute,
      private formBuilder : FormBuilder,
      ) {

        this.activedRoute.params.subscribe((params) => {
          this.id = params['id'];
          // console.log('id', this.id);
          this.apiService.getGrant(this.id).subscribe((response) => {
            this.grant = response;
            console.log("grant", this.grant);

          });
        });
      }


  ngOnInit() {
      this.loadData();
  }

  nextStep(): void {
    this.currentStep = this.currentStep+1;
  }

  prevStep(): void {
    this.currentStep = this.currentStep - 1;
  }

  changeCurrentStep(step:number){
    this.currentStep = step;
  }


  getLength(items:any){
    return items.filter(item => item.status === 'Under_Review').length;
  }

  loadData(){
  }



}
