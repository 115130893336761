import { Component, Input } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-activity-baseline-target',
  templateUrl: './activity-baseline-target.component.html',
  styleUrls: ['./activity-baseline-target.component.scss']
})
export class ActivityBaselineTargetComponent{


  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

  @Input() grantId;
  grant: any;
  formGroup: UntypedFormGroup;
  activities: any;
  showtable: boolean;

  constructor(
    private toastrService: ToastrService,
    public apiService: ApiService,
    public router: Router,
    private formBuilder : FormBuilder
    ) {
        // console.log('id', this.id);
        this.apiService.getGrant(this.grantId).subscribe((response) => {
          this.grant = response;
        });
    }


ngOnInit() {
    this.formGroup = this.formBuilder.group({
        id: ['',[]],
        grant_activity_id: [null,[Validators.required]],
        total_target: ['', [Validators.required]],
        baseline_target: ['', [Validators.required]],
      });
      this.loadData();
  }

  loadData(){

    this.apiService.getGrantActivities(this.grantId).subscribe(response => {
      this.activities = response;
    });

  }


  submit(){

    this.apiService.createActivityBaseline(this.formGroup.value).subscribe(response => {
      this.toastrService.success("Registration succeeded");
      this.formGroup.reset();
      this.loadData();
    }, (e) => {
      this.toastrService.error("Registration failed");
    })

  }

  showData(){
    this.showtable=!this.showtable;
  }
}
