<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h4 class="m-0 text-dark">All Submitted Trainings</h4>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/dtbs/submission-list">DTBs</a></li>
                    <li class="breadcrumb-item active">All Submitted Trainings</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
        <div class="card card-body">
  
          <!-- <button type="button" class="col-2 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add Submission</button> -->
          <div class="row">
            <div class="col-lg-8"></div>
            <div class="input-group col-lg-4">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
              </div>
              <input class="input" (input)="filter($event.target.value)"  class="form-control" placeholder="">
            </div>
          </div>
  
          <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" >
  
              <tr class="bg-primary">
                <td data-order="desc" data-name="id" scope="col"> <i class="fa fa-sort-desc"
                    aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                  <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                     [hidden]="!showAsc"></i>#
                </td>
                <td data-order="desc" data-name="grant_title" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Grant
                </td>
                <td data-order="desc" data-name="province_name" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Province
                </td>
                <td data-order="desc" data-name="district_name" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>District
                </td>
                <td data-order="desc" data-name="location" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Location
                </td>
                <td data-order="desc" data-name="user" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>User
                </td>
                <td data-order="desc" data-name="training_topic" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Training Topic
                </td>
                <td data-order="desc" data-name="training_modality" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Training Modality
                </td>
                <td data-order="desc" data-name="training_type" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Training Type
                </td>
                <td data-order="desc" data-name="start_date" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Start Date
                </td>
                <td data-order="desc" data-name="end_date" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>End Date
                </td>
                <td data-order="desc" data-name="facilitator_name" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Facilitator Name
                </td>
                <td data-order="desc" data-name="facilitator_position" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Facilitator Position
                </td>
                <td data-order="desc" data-name="remarks" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Remarks
                </td>
                <td data-order="desc" data-name="participant_id" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Participant ID
                </td>
                <td data-order="desc" data-name="pre_score" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Pre Score
                </td>
                <td data-order="desc" data-name="post_score" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Post Score
                </td>
                <td data-order="desc" data-name="informative_level" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Informative Level
                </td>
                <td data-order="desc" data-name="usefulness_level" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Usefulness Level
                </td>
                <td data-order="desc" data-name="understaning_level" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Understaning Level
                </td>
                <td data-order="desc" data-name="relevance_level" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Relevance Level
                </td>
                <td data-order="desc" data-name="accessability_level" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Accessability Level
                </td>
                <td data-order="desc" data-name="percentage_of_positive_evaluation" scope="col"> <i class="fa fa-sort-desc"
                  aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
                <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>Percentage of positive evaluation
                </td>
                <td scope="col">Action</td>
  
              </tr>
            <tbody class="table table-sm -group-divider">
              <tr *ngFor="let item of response?.data; let i = index">
                <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
                <td>{{item.grant.title}}</td>
                <td>{{item.province.name}}</td>
                <td>{{item.district.name}}</td>
                <td>{{item.location}}</td>
                <td>{{item.user}}</td>
                <td>{{item.training_topic}}</td>
                <td>{{item.training_modality}}</td>
                <td>{{item.training_type}}</td>
                <td>{{item.start_date}}</td>
                <td>{{item.end_date}}</td>
                <td>{{item.facilitator_name}}</td>
                <td>{{item.facilitator_position}}</td>
                <td>{{item.remarks}}</td>
                <td>{{item.participant_id}}</td>
                <td>{{item.pre_score}}</td>
                <td>{{item.post_score}}</td>
                <td>{{item.informative_level}}</td>
                <td>{{item.usefulness_level}}</td>
                <td>{{item.understaning_level}}</td>
                <td>{{item.relevance_level}}</td>
                <td>{{item.accessability_level}}</td>
                <td>{{item.percentage_of_positive_evaluation}}</td>
                <td>
                  <div class="btn-group" role="group" >
                    <button type="button" class="btn btn-primary"  (click)="download(item)">Download</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
  
             <div class="d-flex justify-content-center mt-1">
            <span *ngFor="let item of response?.links" >
              <button  (click)="clickLink(item.url ? item.url : response.prev_page_url)" [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)" class="btn btn-outline-primary" [innerHTML]="item.label"
              [style.background-color]="item.active? '#007bff' : ''" style="color:rgb(0, 0, 0); border: solid #ccc 1px;" ></button>
            </span>
          </div>
  
  
        </div>
    </div>
  </section>
  