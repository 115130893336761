<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href=""
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
        Action Against Hunger (AAH)</a
    >
    <span>.</span>
</strong>
