<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h4 class="m-0 text-dark">Community Dialogue Services </h4>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href="/myspace">My Space</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries">Beneficiaries</a></li>
                  <li class="breadcrumb-item"><a href="/beneficiaries/details/{{beneficiary?.id}}">{{beneficiary?.beneficiary_code}} - {{beneficiary?.client_name}}</a></li>
                  <li class="breadcrumb-item active">Dialogues </li>
              </ol>
          </div>
          <!-- /.col -->
      </div>
      <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="padding: 1%;">
  <div class="container-fluid">
      <div class="card card-body">

        <button type="button" *ngIf="addValue" class="col-lg-1 mb-1 pull-right btn btn-primary btn-sm" (click)="addObjectClicked()">Add New</button>


        <table class="table table-sm  table-responsive-sm table-responsive-md table-bordered" *ngIf="!addObj">

            <tr>
              <td  [appSort]="objs" data-order="desc" data-name="role_in_community" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Job Title (Role in community)</td>
              <td  [appSort]="objs" data-order="desc" data-name="cd_date" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Dialogue Date</td>
              <td  [appSort]="objs" data-order="desc" data-name="cd_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Session Type</td>
              <td  [appSort]="objs" data-order="desc" data-name="session_type" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Dialogue Type</td>
              <td  [appSort]="objs" data-order="desc" data-name="received_incentive" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Received Incentive?</td>
              <td  [appSort]="objs" data-order="desc" data-name="incentive_amount" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Incentive Amount</td>
              <td  [appSort]="objs" data-order="desc" data-name="remarks" scope="col">                <i class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
<i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true" [hidden]="!showAsc"></i>Remarks</td>

<td scope="col">Action</td>
            </tr>
          <tbody class="table table-sm -group-divider">
            <tr *ngFor="let item of objs">
              <!-- <th scope="row">{{item.id}}</th> -->
              <td>{{item.role_in_community}}</td>

              <td>{{item.cd_date | date}}</td>
              <td>{{item.cd_type}}</td>
              <td>{{item.session_type}}</td>
              <td>{{item.received_incentive}}</td>
              <td>{{item.incentive_amount}}</td>
              <td>{{item.remarks}}</td>

              <td>

                <div class="btn-group" role="group" >
                  <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
                  <button type="button" class="btn btn-primary" (click)="followups(item)">Followups</button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <form [formGroup]="formGroup" *ngIf="addObj" (ngSubmit)="submit()">

          <div class="row">


            <div class="mb-3 col-lg-3">
              <label  class="form-label">Job Title (Role in community)</label>
              <input type="text" formControlName="role_in_community" class="form-control" placeholder="">
            </div>

          <div class="mb-3 col-lg-3">
            <label  class="form-label">Dialogue Type</label>
            <select class="form-control" formControlName="cd_type" >
              <option value="Diversity and Inclusive Excellence">Diversity and Inclusive Excellence</option>
              <option value="Gender-Based Stereotyping">Gender-Based Stereotyping</option>
              <option value="Economic/Social Inequality">Economic/Social Inequality</option>
              <option value="Other">Other </option>
            </select>
          </div>


          <div class="mb-3 col-lg-3">
            <label  class="form-label">Received Incentive</label>
            <select class="form-control" formControlName="received_incentive" >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <div class="mb-3 col-lg-3">
            <label  class="form-label">Incentive Amount</label>
            <input type="number" formControlName="incentive_amount" class="form-control" placeholder="">
          </div>


          </div>
          <div class="row">



          <div class="mb-3 col-lg-4">
            <label  class="form-label">Dialogue Date</label>
            <input type="date" formControlName="cd_date" class="form-control" placeholder="">
          </div>

          <div class="mb-3 col-lg-4">
            <label  class="form-label">Session Type</label>
            <input type="text" formControlName="session_type" readonly disabled class="form-control" placeholder="">
          </div>

          <!-- <div class="mb-3 col-lg-4">
            <label  class="form-label">Session Type</label>
            <select class="form-control" formControlName="session_type" >
              <option value="Initial">Initial</option>
              <option value="Follow-up">Follow-up</option>
            </select>
          </div> -->



          <div class="mb-3 col-lg-8">
            <label  class="form-label">Remarks</label>
            <textarea type="text" formControlName="remarks" class="form-control" placeholder=""></textarea>
          </div>


        </div>
          <div class="row">
            <div class="col-lg-2">
             <button type="submit" [disabled]="formGroup.invalid" style="width: 100%;" class="btn btn-primary">
                Submit
              </button>
          </div>

          </div>

        </form>


      </div>
  </div>
</section>
