<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Settings</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Settings</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  
  <section class="content" style="padding: 1%;">
    <div class="container-fluid">
      <div class="card card-body">
        <form [formGroup]="settingForm" (ngSubmit)="submit()">
          <div class="mb-3">
            <label class="form-label">Current Password</label>
            <input type="password" formControlName="current_password" class="form-control" placeholder="">
            <print-error [control]="settingForm.get('current_password')"></print-error>
          </div>
  
          <div class="mb-3">
            <label class="form-label">New Password</label>
            <input type="password" formControlName="password" class="form-control" placeholder="">
            <print-error [control]="settingForm.get('password')"></print-error>
          </div>
  
          <div class="mb-3">
            <label class="form-label">Confirm Password</label>
            <input type="password" formControlName="c_password" class="form-control" placeholder="">
            <print-error [control]="settingForm.get('c_password')"></print-error>
          </div>
  
          <div class="row">
            <div class="col-6">
              <button type="submit" [disabled]="settingForm.invalid" style="width: 100%;" class="btn btn-primary">
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  