<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h4 class="m-0 text-dark">Grants overview</h4>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/grant/overview">Grants</a></li>
          <li class="breadcrumb-item active">Grants Overview</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card card-body" id="content" #content>

      <div class="row">
        <div class="col-lg-8">
          <div class="btn-group" role="group">
            <a type="button" class="btn btn-primary btn-sm" (click)="savePDF()">Export PDF</a>
            <a type="button" class="btn btn-success btn-sm" (click)="saveExcel()">Export Excel</a>
          </div>
        </div>
        <div class="input-group col-lg-4">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search " aria-hidden="true"></i></span>
          </div>
          <input class="input" (input)="filter($event.target.value)" class="form-control" placeholder="">
        </div>
      </div>
      <table id="dtBasicExample" class="table table-sm  table-bordered table-responsive-sm table-responsive-md">
        <thead>
          <tr class="bg-primary">
            <td scope="col">#</td>
            <td [appSort]="response?.data" data-order="desc" data-name="code" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;Code
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="title" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;Title
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="donor" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;Donor
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="start_date" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;Start Date
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="end_date" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;End Date
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="status" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;Status
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="manager" scope="col"> <i class="fa fa-sort-desc"
                aria-hidden="true" (click)="showDesc = false; showAsc = true" [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;Manager
            </td>
            <td [appSort]="response?.data" data-order="desc" data-name="reporting_type" scope="col"> <i
                class="fa fa-sort-desc" aria-hidden="true" (click)="showDesc = false; showAsc = true"
                [hidden]="!showDesc"></i>
              <i class="fa fa-sort-asc" aria-hidden="true" (click)="showAsc = false; showDesc = true"
                [hidden]="!showAsc"></i>&nbsp;Reporting
            </td>

            <td scope="col">Action</td>

          </tr>


        </thead>
        <tbody class="table table-sm -group-divider">
          <tr *ngFor="let item of response?.data; let i = index">
            <th scope="row">{{response?.from == 1 ? i+1 :response?.from + i}}</th>
            <td>{{item.code}}</td>
            <td>{{item.title}}</td>
            <td>{{item.donor}}</td>
            <td>{{item.start_date}}</td>
            <td>{{item.end_date | date}}</td>
            <td>{{item.status}}</td>
            <td>{{item.manager.name}}</td>
            <td>{{item.reporting_type}}</td>

            <td>

              <div class="btn-group" role="group">
                <button type="button" class="btn btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
                <button type="button" class="btn btn-warning" (click)="details(item)"><i class="fa fa-eye"></i></button>
                <button type="button" class="btn btn-primary" (click)="configure(item)"><i class="fa fa-gear"></i></button>
                <button type="button" class="btn btn-success" (click)="edit(item)"><i class="fa fa-pen"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-center mt-1">
        <span *ngFor="let item of response?.links">
          <button (click)="clickLink(item.url ? item.url : response.prev_page_url)"
            [disabled]="item.label.includes('...') || (item.label.includes('Next') && response?.next_page_url == null ) || (item.label.includes('Previous') && response?.prev_page_url == null)"
            class="btn btn-outline-primary" [innerHTML]="item.label"
            [style.background-color]="item.active? '#007bff' : ''"
            style="color:rgb(0, 0, 0); border: solid #ccc 1px;"></button>
        </span>
      </div>
    </div>
  </div>
</section>
