import { Component } from '@angular/core';

@Component({
  selector: 'app-dtb',
  templateUrl: './dtb.component.html',
  styleUrls: ['./dtb.component.scss']
})
export class DtbComponent {
  showDesc = true; // show the descending icon by default
  showAsc = false; // hide the ascending icon by default

}
